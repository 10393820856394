import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {
    intercept(
        httpRequest: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        const headers: HttpHeaders = httpRequest.headers
            .append('Accept', 'application/json')
            .append("X-Frame-Options", "SAMEORIGIN")
            .append("X-Content-Type-Options", "nosniff")
            .append("Content-Security-Policy", "default-src 'self'")
            .append("Referrer-Policy", "origin");
        const httpRequestWithHeaders: HttpRequest<any> = httpRequest.clone({ headers });

        return next.handle(httpRequestWithHeaders);
    }
}