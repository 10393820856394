import { Component, Input } from '@angular/core';
import { Projection } from '../models';
import { FormatDatePipe } from 'src/app/miscellaneous/pipes/format-date.pipe';

@Component({
  selector: 'app-bobby-calves-late-entry-card',
  templateUrl: './bobby-calves-late-entry-card.component.html',
  styleUrls: ['./bobby-calves-late-entry-card.component.scss'],
})
export class BobbyCalvesLateEntryCardComponent {
  @Input() pickup: Projection.Pickup;

  constructor(private readonly dateFormatter: FormatDatePipe) {}

  public get date(): string {
    const pickupDateStr = this.dateFormatter.transform(this.pickup.date, 'dwdm');
    return pickupDateStr;
  }
}
