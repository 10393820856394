import { createAction, props } from '@ngrx/store';
import { AdviceStatus } from '../../../miscellaneous/enums/advice-status.enum';
import { StockType } from '../../../miscellaneous/enums/stock-type.enum';
import { Model } from '../interfaces';

export const loadRecentAdvices = createAction("[Advices] Load Recent Advices Started");
export const loadRecentAdvicesCompleted = createAction("[Advices] Load Recent Advices Completed", props<{ response: Model.PotentialOutageResponse<Model.PagedAdviceResponse> }>());

export const getAdviceFilterOptions = createAction("[Advices] Get Available Filter Options Started");
export const getAdviceFilterOptionsCompleted = createAction("[Advices] Get Available Filter Options Completed", props<{ response: Model.AdvicesFilterOptions }>());

export const searchAdvices = createAction("[Advices] Search Advices Started", props<Model.AdvicesQuery>());
export const searchAdvicesCompleted = createAction("[Advices] Search Advices Completed", props<{ response: Model.PotentialOutageResponse<Model.PagedAdviceResponse> }>());

export const filterUpdated = createAction("[Advices] Filter Updated", props<{ update: Partial<Model.AdvicesQuery> }>());
export const filterCleared = createAction("[Advices] Filter Cleared");
export const toggleFilterAllStockTypes = createAction("[Advices] Toggle Filter All Stock Types");
export const toggleFilterStockType = createAction("[Advices] Toggle Filter Stock Type", props<{ stockType: StockType }>());
export const toggleFilterAllStatues = createAction("[Advices] Toggle Filter All Statues");
export const toggleFilterStatues = createAction("[Advices] Toggle Filter Statues", props<{ status: AdviceStatus }>());

export const showFilter = createAction("[Advices] Show Filter", props<{ show: boolean }>());

export const selectAdvice = createAction("[Advices] Select Advice", props<{ season: number, advice: number, propertyId: number, previousRoute: string }>());
export const unselectAdvice = createAction("[Advices] Unselect Advice");

export const adviceErrored = createAction("[Advices] adviceErrored", props<{ errored: boolean }>());