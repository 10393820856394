import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AngularMaterialModule } from '../../miscellaneous/angular-material.module';
import { AllStaffManagementComponent } from './all-staff-management.component';
import { featureReducerKey, userManagementReducer } from './state/reducer';
import { UserManagementEffects } from './state/effects';
import { StaffManagementComponent } from './staff-management.component';
import { RolesDescriptionComponent } from './roles-description.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    AllStaffManagementComponent,
    StaffManagementComponent,
    RolesDescriptionComponent,
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    RouterModule,
    StoreModule.forFeature(featureReducerKey, userManagementReducer),
    EffectsModule.forFeature([UserManagementEffects]),
  ],
  exports: [AllStaffManagementComponent],
})
export class StaffManagementModule { }
