import { createAction, props } from '@ngrx/store';
import { Role } from 'src/app/miscellaneous/enums/role.enum';
import { Model } from '../interfaces';

export const noAction = createAction("No Action");
export const loadOwnerPropertiesStarted = createAction("[UserManagement] Load Owner Properties Started");
export const loadOwnerPropertiesCompleted = createAction(
    "[UserManagement] Load Owner Properties Completed",
    props<Model.PotentialOutageResponse<{
        properties: Model.Property[],
        staff: Model.Staff[],
    }>>());

export const removeStaffStarted = createAction(
    "[UserManagement] Remove Staff Started",
    props<{ userId: number }>());
export const removeStaffCompleted = createAction(
    "[UserManagement] Remove Staff Completed",
    props<{ userId: number }>());

export const addStaffStarted = createAction(
    "[UserManagement] Add Staff Started",
    props<{ staff: Model.Staff }>());
export const addStaffCompleted = createAction(
    "[UserManagement] Add Staff Completed",
    props<{ staff: Model.Staff }>());

export const changeStaffStarted = createAction(
    "[UserManagement] Change Staff Started",
    props<{ staff: Model.Staff }>());
export const changeStaffCompleted = createAction(
    "[UserManagement] Change Staff Completed",
    props<{ staff: Model.Staff }>());

export const selectStaff = createAction(
    "[UserManagement] Select Staff",
    props<{ userId: number }>());

export const updateStaffDetail = createAction(
    "[UserManagement] Update Staff Detail",
    props<{ update: Partial<Model.Staff> }>());

export const updateStaffRole = createAction(
    "[UserManagement] Update Staff Role",
    props<{
        propertyId: number,
        role: Role,
        isActive: boolean,
    }>());

export const addStaff = createAction("[UserManagement] Add Staff");

export const staffErrored = createAction("[UserManagement] staffErrored", props<{ errored: boolean }>());