import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

type ProducerPortalDateFormats = 'dmy' | 'dm' | 'dmt' | 'dwdm' | 't' | 'dmta';

const dateLookup: { [key in ProducerPortalDateFormats]: string } = {
  dmy: 'd MMM yy',
  dm: 'd MMM',
  dmt: 'd MMM HH:mm',
  dwdm: 'EEE, d MMM',
  t: 'h:mm a',
  dmta: 'd MMM h:mm a'
};

/**
 * Custom Date pipe leveraging Angular's DatePipe and extending it with custom
 * date formats that are used in the producer portal.
 */
@Pipe({ name: 'formatDate' })
export class FormatDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(
    value: string | Date,
    format?: ProducerPortalDateFormats | string
  ): string {
    const dateFormat = dateLookup[format] || format;
    return this.datePipe.transform(value, dateFormat);
  }
}
