import { Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { CommandGetCarcase } from "../commands/command-get-carcase";
import { CommandGetInvoice } from "../commands/command-get-invoice";
import { allAdvicesRoute } from "../constants";
import { Projection } from "../interfaces";
import { selectAdvice } from "../state/actions";

@Component({
    selector: "app-processing-table",
    templateUrl: "processing-table.html",
    styleUrls: ["all-advices.component.scss", "../advices.scss"]
})
export class ProcessingTableComponent {
    @Input() public advices: Projection.Advice[];

    public readonly tableColumns: string[] = [
        "processingDate",
        "propertyName",
        "stockType",
        "adviceNumber",
        "tally",
        "averageWeight",
        "dollarPerKilo",
        "invoiceDollars",
        "adviceStatus",
        "files",
    ];

    constructor(
        private readonly store: Store,
        private readonly commandGetInvoice: CommandGetInvoice,
        private readonly commandGetCarcase: CommandGetCarcase,
    ) { }

    public selectAdvice(advice: Projection.Advice): void {
        this.store.dispatch(selectAdvice({
            season: advice.season,
            advice: advice.advice,
            propertyId: advice.propertyId,
            previousRoute: allAdvicesRoute,
        }));
    }

    public adviceKillDate(
        index: number,
        advice: Projection.Advice,
        adviceLine: Projection.AdviceLine,
    ): Date | null {
        return index > 0 && adviceLine.killDate.getTime() === advice.adviceLines[0].killDate.getTime() ?
            null :
            adviceLine.killDate;
    }
    
    public async downloadInvoice(
        advice: Projection.Advice,
        adviceLine: Projection.AdviceLine,
        evt: MouseEvent,
    ): Promise<void> {
        evt.stopImmediatePropagation();

        await this.commandGetInvoice.Retrieve(
            advice.invoiceFileId,
            advice.advice,
            advice.season,
            advice.propertyId,
            adviceLine.killDate,
            advice.isBobbyAdvice,
        );
    }

    public async downloadCarcaseData(
        advice: Projection.Advice,
        adviceLine: Projection.AdviceLine,
        evt: MouseEvent,
    ): Promise<void> {
        evt.stopImmediatePropagation();

        await this.commandGetCarcase.Retrieve(
            adviceLine.carcaseFileId,
            advice.advice,
            advice.season,
            advice.propertyId,
            adviceLine.killDate,
        );
    }
}