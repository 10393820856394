import { Component } from '@angular/core';
import { Location } from '@angular/common';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent {
  
  constructor(private location: Location) { }

  goBack() {
    this.location.back(); // <-- go back to previous locat  ion on cancel
  }
}
