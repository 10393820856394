import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Model, Projection } from '../interfaces';
import { filterUpdated, getAdviceFilterOptions, searchAdvices } from '../state/actions';
import { selectAdviceSearchResults, selectTotalAdvicesForSearch, selectAdvicesQuery, selectPageNumber, selectPageSize, selectAdviceFilterOptions, selectShowFilter } from '../state/selectors';
import { CommandGetCsv } from '../commands/command-get-csv';
import { FilterToggle } from './filterToggle';

@Component({
  selector: 'app-processing',
  templateUrl: './all-advices.component.html',
  styleUrls: ['../advices.scss', './all-advices.component.scss']
})
export class AllAdvicesComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  private readonly destroy$ = new Subject();

  public showFilter: boolean = true;
  public filterOptions: Projection.AdvicesFilterOptions;
  public adviceQuery: Model.AdvicesQuery = null;
  public advices: Projection.Advice[] = [];
  public totalAdvicesForSearch$: Observable<number>;
  public pageSize: number;
  public pageNumber: number;

  public readonly pageSizeOptions: number[] = [10, 20, 50, 100];

  constructor(
    private readonly store: Store,
    private readonly commandGetCsv: CommandGetCsv,
    private readonly filterToggle: FilterToggle,
  ) { }
 
  public ngOnInit(): void {
    this.filterToggle.init();

    this.store.select(selectShowFilter)
      .pipe(takeUntil(this.destroy$))
      .subscribe((show) => this.showFilter = show);

    this.store.select(selectAdviceFilterOptions)
      .pipe(takeUntil(this.destroy$))
      .subscribe((filterOptions) => this.filterOptions = filterOptions);
    
    this.store.select(selectAdviceSearchResults)
      .pipe(takeUntil(this.destroy$))
      .subscribe((advices) => this.advices = advices);

    this.store.select(selectAdvicesQuery)
      .pipe(takeUntil(this.destroy$))
      .subscribe((query) => {
        this.adviceQuery = query;
        this.store.dispatch(searchAdvices(query));
      });

    this.store.select(selectPageSize)
      .pipe(takeUntil(this.destroy$))
      .subscribe((pageSize) => this.pageSize = pageSize);

    this.store.select(selectPageNumber)
      .pipe(takeUntil(this.destroy$))
      .subscribe((pageNumber) => this.pageNumber = pageNumber);

    this.totalAdvicesForSearch$ = this.store.select(selectTotalAdvicesForSearch);
   
    this.store.dispatch(getAdviceFilterOptions());
  }

  public showFilterPanel(): void {
    this.filterToggle.showFilter();
  }

  public ngAfterViewInit(): void {
    if (this.paginator) {
      this.paginator._intl.itemsPerPageLabel = 'Advices per page';
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onPage(pageEvent: PageEvent): void {
    this.store.dispatch(filterUpdated({ update : {
      pageNumber: pageEvent.previousPageIndex === pageEvent.pageIndex ? 1 : pageEvent.pageIndex + 1,  // go to the requested page # (adjust for 0 based index)
      pageSize: pageEvent.pageSize,
    }}));
  }

  public downloadCsv(): void {
    this.commandGetCsv.Retrieve(this.adviceQuery);
  }
}