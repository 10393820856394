<div class="compliance-outage-tile">
  <a href="https://anzcofoods.com/contact-us/livestock-reps/" target="_blank" data-cy="complianceOutageCard">
    <mat-card>
      <div>
        <img src="../../../assets/images/nzfai_big.jpg" alt="nzfap image" />
        <div>
          <span>New Zealand Farm Assurance Programmes</span>
        </div>
      </div>
      <span class="blue-text">Click here to find out more</span>
    </mat-card>
  </a>
</div>
