import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { Model } from '../../admin.interfaces';
import { Role, RoleEnum } from '../../../miscellaneous/enums/role.enum';
import { SearchPropertiesQuery } from '../api/search-properties.query';
import { Models } from '../models';

@Component({
  selector: 'app-property-role-form',
  templateUrl: './property-role-form.component.html',
  styleUrls: ['./property-role-form.component.scss'],
})
export class PropertyRoleFormComponent implements OnInit {
  @Output() propertiesChanged = new EventEmitter<Models.ApiProperty[]>();

  @Input() set producerCodeSearch(producerCode: string) {
    this.producerCodeSearch$.next(producerCode || '');
  }

  @Input() set propertySearchTerm(searchTerm: string) {
    this.propertySearchTerm$.next(searchTerm || '');
  }

  @Input() set disabled(disabled: boolean) {
    if (disabled)
      this.propertyRows = [...this.rowsWithRole.values()].map((r) => ({
        ...r,
        locked: true,
        showLockBtn: false,
      }));
  }

  allRoles = RoleEnum.all();
  producerCodeSearch$ = new BehaviorSubject('');
  propertySearchTerm$ = new BehaviorSubject('');
  propertyRows: PropertyRow[] = [];
  private rowsWithRole = new Map<number, PropertyRow>();

  constructor(public readonly searchProperties: SearchPropertiesQuery) {}

  ngOnInit(): void {
    combineLatest([
      this.propertySearchTerm$.pipe(distinctUntilChanged()),
      this.producerCodeSearch$.pipe(distinctUntilChanged()),
    ])
      .pipe(
        map(([searchTerm, producerCode]) => ({ searchTerm, producerCode })),
        switchMap((search) => this.searchProperties.execute(search))
      )
      .subscribe((apiProperties) => {
        const propertyRows = [...this.rowsWithRole.values()];
        apiProperties
          .filter(({ propertyId }) => !this.rowsWithRole.has(propertyId))
          .forEach((apiProperty) => {
            const row = toPropertyRow(apiProperty);
            if (row.role !== RoleEnum.Unknown)
              this.rowsWithRole.set(apiProperty.propertyId, row);

            propertyRows.push(row);
          });
        this.propertyRows = propertyRows;
      });
  }

  onRoleClicked(property: PropertyRow, role: RoleEnum): void {
    property.role = role;
    property.locked = true;
    property.showLockBtn = true;
    this.rowsWithRole.set(property.propertyId, property);

    const apiProperties = [...this.rowsWithRole.values()].map((row) => {
      const { propertyId, propertyName, role } = row;
      return { propertyId, propertyName, roleId: role.role };
    });

    this.propertiesChanged.emit(apiProperties);
  }

  trackByPropertyId(index: number, property: PropertyRow): number {
    return property.propertyId;
  }
}

const toPropertyRow = (apiProperty: Models.ApiProperty): PropertyRow => {
  const role = RoleEnum.get(apiProperty.roleId);
  const hasRole = role.role !== Role.Unknown;

  return {
    ...apiProperty,
    role: role,
    locked: hasRole,
    showLockBtn: hasRole,
  };
};

type PropertyRow = Omit<Model.PropertyRole, 'role'> & {
  role: RoleEnum;
  showLockBtn: boolean;
};
