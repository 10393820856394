import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  selectErrored,
  selectIsReadOnly,
  selectIsWelfareDeclared,
  selectSelectedProperty,
  selectShouldDisplayPropertySelection,
  selectValidDeclaration,
} from './state/selectors';
import { takeUntil } from 'rxjs/operators';
import { Model } from './models';
import { createOrUpdatePickupDeclaration, showOpenDeclarations } from 'src/app/user/state/actions';
import { TimeSpanFormatter } from 'src/app/miscellaneous/time-span-formatter';
import { NavigationService } from 'src/app/services/navigation.service';
import { MatStepper } from '@angular/material/stepper';
import { selectSelectableBooking } from './state/actions';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

@Component({
  selector: 'app-pickup-declarations',
  templateUrl: './pickup-declarations.component.html',
  styleUrls: ['./pickup-declarations.component.scss'],
})
export class PickupDeclarationComponent implements OnInit,  OnDestroy {
  @ViewChild('stepper') private stepper: MatStepper;
  
  private readonly destroy$ = new Subject();
  public errored: boolean = false;
  public selectedProperty: Model.SelectedBookingDeclaration = null;
  public invalidDeclaration: boolean = false;
  public confirmationOutstanding: boolean = false;
  public shouldDisplayPropertySelection: boolean = false;
  public shouldDisplayEditStage: boolean = true;

  constructor(
    private readonly store: Store,
    private readonly timeSpanFormatter: TimeSpanFormatter,
    private readonly navigationService: NavigationService,
  ) {
    this.store.dispatch(showOpenDeclarations({ show: false }));
  }

  public ngOnInit(): void {
    this.store
      .select(selectErrored)
      .pipe(takeUntil(this.destroy$))
      .subscribe(errored => this.errored = errored);
    this.store
      .select(selectShouldDisplayPropertySelection)
      .pipe(takeUntil(this.destroy$))
      .subscribe(display => this.shouldDisplayPropertySelection = display);
    this.store
      .select(selectSelectedProperty)
      .pipe(takeUntil(this.destroy$))
      .subscribe(property => {
        if (this.stepper &&
            this.selectedProperty === null &&
            this.shouldDisplayPropertySelection &&
            property !== null) {
              this.stepper.next();
        }
        this.selectedProperty = property;
      });
    this.store
      .select(selectIsWelfareDeclared)
      .pipe(takeUntil(this.destroy$))
      .subscribe(declared => this.confirmationOutstanding = !declared);
    this.store
      .select(selectValidDeclaration)
      .pipe(takeUntil(this.destroy$))
      .subscribe(validDeclaration => this.invalidDeclaration = !validDeclaration);
    this.store
      .select(selectIsReadOnly)
      .pipe(takeUntil(this.destroy$))
      .subscribe(isReadOnly => this.shouldDisplayEditStage = !isReadOnly);
  }

  public ngOnDestroy(): void {
    this.store.dispatch(selectSelectableBooking({
      propertyId: null,
      bookingId: null,
    }));
    this.store.dispatch(showOpenDeclarations({ show: true }));
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onStepChange(evt: StepperSelectionEvent) {
    if (this.shouldDisplayPropertySelection && evt.selectedIndex === 0)
    {
      this.store.dispatch(selectSelectableBooking({
        propertyId: null,
        bookingId: null,
      }));
    }
  }
  
  public confirm(): void {
    this.store.dispatch(createOrUpdatePickupDeclaration({
      id: this.selectedProperty.id,
      bookingId: this.selectedProperty.bookingId,
      tally: this.selectedProperty.tally,
      contactNumber: this.selectedProperty.contactNumber,
      recordedBy: this.selectedProperty.recordedBy,
      timeOfLastFeed: this.selectedProperty.tally === 0 ?
        this.timeSpanFormatter.createTimeSpanString(0, 0) :
        this.timeSpanFormatter.createTimeSpanString(
          this.selectedProperty.timeOfLastFeedHours,
          this.selectedProperty.timeOfLastFeedMinutes,
        ),
    }));
    this.navigationService.bobbyCalves();
  }

  public back(): void {
    this.navigationService.bobbyCalves();
  }
}
