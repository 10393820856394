import { Injectable } from "@angular/core";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { Role, RoleEnum } from "src/app/miscellaneous/enums/role.enum";
import { Model, Projection } from "../interfaces";
import { featureReducerKey } from "./reducer";

@Injectable({ providedIn: 'root' })
export class Selector {
    private getUserManagementState = createFeatureSelector<Model.UserManagementState>(featureReducerKey);

    public selectPropertiesLoaded = createSelector(
        this.getUserManagementState,
        (state: Model.UserManagementState) => (state?.properties ?? []).length > 0);

    public selectAllStaff = createSelector(
        this.getUserManagementState,
        (state: Model.UserManagementState) => {
            const result: Projection.PropertyStaff[] = state.staff
                .slice()
                .sort((userA: Model.Staff, userB: Model.Staff) => {
                    const userAOwner: boolean = userA.roles.some(role => role.role === Role.Owner);
                    const userBOwner: boolean = userB.roles.some(role => role.role === Role.Owner);
                    let result: number = 0;

                    if (userAOwner === userBOwner) {
                        result = userA.firstName ?
                            userA.firstName.localeCompare(userB.firstName) :
                            -userB.firstName?.localeCompare(userA.firstName);
                    }
                    else if (userAOwner) {
                        result = -1;
                    }
                    else {
                        result = 1;
                    }
                    return result;
                })
                .map((userModel: Model.Staff) => {
                    const canChange: boolean = userModel.roles.every(role => role.role !== Role.Owner);
                    const userProjection: Projection.PropertyStaff = {
                        userId: userModel.userId,
                        email: userModel.email,
                        firstName: userModel.firstName,
                        surname: userModel.surname,
                        isActive: userModel.isActive,
                        canChange,
                    };

                    return userProjection;
                });

            return result;
        });

    public selectStaff = createSelector(
        this.getUserManagementState,
        (state: Model.UserManagementState) => {
            const staff: Model.Staff = state.editedStaff;
            const properties: Projection.PropertyRole[] = staff?.roles.map((role: Model.StaffProperty) => {
                const staffProperty: Model.Property = state.properties
                    .find(property => role.propertyId === property.propertyId);
                const staffRole: Projection.PropertyRole = {
                    propertyId: role.propertyId,
                    propertyName: staffProperty.propertyName,
                    roleId: role.role,
                    role: RoleEnum.parse(role.role),
                    isActive: role.isActive,
                }

                return staffRole;
            });
            const isOwner: boolean = properties?.some(property => property.roleId === 1) ?? false;
            const result: Projection.Staff = {
                userId: state.selectedStaffId,
                isActive: staff?.isActive ?? false,
                email: staff?.email ?? null,
                firstName: staff?.firstName ?? null,
                surname: staff?.surname ?? null,
                properties,
                isOwner,
            };

            return result;
        });
    
    public selectEditedStaff = createSelector(
        this.getUserManagementState,
        (state: Model.UserManagementState) => {
            const result: Model.Staff = {
                ...state.editedStaff,
                roles: state.editedStaff ?
                    state.editedStaff.roles.filter(role => role.role !== Role.Unknown && role.role !== Role.Owner) :
                    [],
            };

            return result;
        });

    public selectDisbaleEditedStaffFields = createSelector(
        this.getUserManagementState,
        (state: Model.UserManagementState) => state.editedStaff?.userId !== null);

    public selectStaffManagementHeadline = createSelector(
        this.getUserManagementState,
        (state: Model.UserManagementState) => 
            state.editedStaff?.userId ? "Edit Staff" : "Add Staff");

    public selectValidFirstName = createSelector(
        this.getUserManagementState,
        (state: Model.UserManagementState) => 
            (state.editedStaff?.firstName ?? null) !== "");

    public selectValidSurname = createSelector(
        this.getUserManagementState,
        (state: Model.UserManagementState) => 
            (state.editedStaff?.surname ?? null) !== "");

    public selectEmailUniqueEmail = createSelector(
        this.getUserManagementState,
        (state: Model.UserManagementState) => 
            (state.editedStaff?.userId ?? null) !== null ||
            !state.staff.map(staff => staff.email).includes((state.editedStaff?.email || "").trim()));

    public selectValidEmail = createSelector(
        this.getUserManagementState,
        (state: Model.UserManagementState) => 
            (state.editedStaff?.email || null) === null ||
            new RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")
                .test((state.editedStaff?.email || "").trim()));

    public selectSubmitValid = createSelector(
        this.getUserManagementState,
        this.selectValidEmail,
        this.selectEmailUniqueEmail,
        (state: Model.UserManagementState, validEmail: boolean, uniqueEmail: boolean) => {
            const validRoles: boolean = (state.editedStaff?.roles || []).some(role => role.role != Role.Unknown);
            const emailExist: boolean = (state.editedStaff?.email || null) !== null;
            const firstNameExist: boolean = (state.editedStaff?.firstName || null) !== null;
            const surnameExist: boolean = (state.editedStaff?.surname || null) !== null;

            return validRoles && emailExist && validEmail && uniqueEmail && firstNameExist && surnameExist;
        });

    public selectEditedStaffChanged = createSelector(
        this.getUserManagementState,
        (state: Model.UserManagementState) => state.editedStaffChanged);
}