import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "app-discard-dialog",
    templateUrl: "./discard-dialog.component.html",
    styleUrls: ["./confimation-dialog.component.scss"],
})
export class DiscardDialogComponent {
    public submitValid: boolean;
    public title: string;
    public text: string;

    constructor(
        private readonly dialogRef: MatDialogRef<DiscardDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data,
    ) {
        this.submitValid = data.submitValid;
        this.title = data.title;
        this.text = data.text;
    }

    public save(): void {
        this.dialogRef.close(true);
    }

    public discard(): void {
        this.dialogRef.close(null);
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }
}