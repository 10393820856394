import { Action, combineReducers } from "@ngrx/store";
import { resetSpinner } from "./actions";

export const featureReducerKey = "spinner";

const resetActionType = resetSpinner().type;

function startAppReducer(state: boolean = true, action: Action): boolean {
    if (action.type.endsWith("Completed") || action.type.endsWith("Failure") || action.type === resetActionType) {
        return false;
    }

    return state;
}

function activeProcessesReducer(state: string[] = [], action: Action): string[] {
    if (action.type === resetActionType) {
        return [];
    }

    if (action.type.endsWith("Started")) {
        return [...state].concat(action.type.substring(0, action.type.length - 7));
    }

    if (action.type.endsWith("Completed")) {
        const completedName: string = action.type.substring(0, action.type.length - 9);
        return [...state].filter((actionName: string) => actionName !== completedName);
    }

    if (action.type.endsWith("Failure")) {
        const failureName: string = action.type.substring(0, action.type.length - 7);
        return [...state].filter((actionName: string) => actionName !== failureName);
    }

    return state;
};

export const reducer = combineReducers({
    startApp: startAppReducer,
    activeProcesses: activeProcessesReducer,
});
