import { createAction, props } from '@ngrx/store';
import { NewsTypeEnum } from 'src/app/miscellaneous/enums/news-type.enum';
import { Model } from '../interfaces';

export const loadNewsOverview = createAction("[News] Load News Overview Started");
export const loadNewsOverviewWithoutGlobalSpinner = createAction("[News] Load News Overview Started (No Global Spinner)");
export const loadNewsOverviewCompleted = createAction("[News] Load News Overview Completed", props<Model.NewsOverview>());
export const loadNewsOverviewFailure = createAction("[News] Load News Overview Failure");

export const loadNewsItem = createAction("[News] Load News Item Started", props<{id: string }>());
export const loadNewsItemCompleted = createAction("[News] Load News Item Completed", props<Model.NewsItem>());
export const loadNewsItemFailure = createAction("[News] Load News Item Failure");

export const loadNewsFeed = createAction("[News] Load News Feed Started", props<Model.NewsFeedQuery>());
export const loadNewsFeedCompleted = createAction("[News] Load News Feed Completed", props<Model.NewsFeed>());
export const loadNewsFeedFailure = createAction("[News] Load News Feed Failure");

export const setNewsFeedType = createAction("[News] Set News Feed Type", props<NewsTypeEnum>());
export const resetNewsFeed = createAction("[News] Reset News Feed");
