<div class="filter-container">
    <div class="labeled-data-item date-picker">
        <mat-label>Date From</mat-label>
        <div (click)="fromPicker.open()">
            <input matInput
                   readonly
                   [matDatepicker]="fromPicker"
                   [value]="adviceQuery.startDate"
                   (dateChange)="updateFilter({ startDate: $event.value?.toDate() })" />
            <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
            <mat-datepicker #fromPicker></mat-datepicker>
        </div>
    </div>

    <div class="labeled-data-item date-picker">
        <mat-label>Date To</mat-label>
        <div (click)="toPicker.open()">
            <input matInput
                   readonly
                   [matDatepicker]="toPicker"
                   [value]="adviceQuery.endDate"
                   (dateChange)="updateFilter({ endDate: $event.value?.toDate() })" />
            <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
            <mat-datepicker #toPicker></mat-datepicker>
        </div>
    </div>

    <div class="labeled-data-item drop-down">
        <mat-label>Property</mat-label>
        <mat-select [(ngModel)]="selectedProperty"
                    [disabled]="filterOptions?.properties.length === 1">
            <mat-option *ngIf="filterOptions?.properties.length > 1"
                        [value]="0"
                        (click)="updateFilter({ propertyId: null })">
                All Properties
            </mat-option>
            <mat-option *ngFor="let property of filterOptions.properties"
                        [value]="property.propertyId"
                        (click)="updateFilter({ propertyId: property.propertyId })">
                {{property.propertyName}}
            </mat-option>
        </mat-select>
    </div>

    <div class="labeled-data-item drop-down">
        <mat-label>Stock Type</mat-label>
        <mat-select multiple
                    [(ngModel)]="selectedStockTypes"
                    [disabled]="filterOptions?.stockTypes.length === 1">
            <mat-select-trigger>
                <span>{{ selectedStockTypesList }}</span>
            </mat-select-trigger>
            <mat-option (click)="toggleAllStockTypes()"
                        [value]="0">
                Select All
            </mat-option>
            <mat-option *ngFor="let stockType of filterOptions.stockTypes"
                        [value]="stockType.stockType"
                        (click)="toggleStockType(stockType.stockType)">
                {{stockType.description}}
            </mat-option>
        </mat-select>
    </div>

    <div class="labeled-data-item drop-down">
        <mat-label>Status</mat-label>
        <mat-select multiple
                    [(ngModel)]="selectedStatuses"
                    [disabled]="filterOptions?.adviceStatuses.length === 1">
            <mat-select-trigger>
                <span>{{ selectedStatusList }}</span>
            </mat-select-trigger>
            <mat-option (click)="toggleAllStatuses()"
                        [value]="10">
                Select All
            </mat-option>
            <mat-option *ngFor="let status of filterOptions.adviceStatuses"
                        [value]="status.adviceStatus"
                        (click)="toggleStatus(status.adviceStatus)">
                {{status.description}}
            </mat-option>
        </mat-select>
    </div>

    <div class="labeled-data-item">
        <button mat-raised-button
                color="primary"
                (click)="clearFilters()">
            Clear Filter
        </button>
    </div>

    <div class="labeled-data-item">
        <button mat-raised-button
                color="primary"
                class="icon-button"
                (click)="hideFilter()">
            <i class="btr bt-angles-up bt-lg"></i>
        </button>
    </div>
</div>