export enum NewsType {
  Unknown = 0,
  News = 1,
  AnzcoUpdate = 2,
  ImportantInfo = 3
};

export class NewsTypeEnum {
    public static News = new NewsTypeEnum(NewsType.News, "Latest Articles", 1, ["The Rural Roundup", "ANZCO Stories"]);
    public static AnzcoUpdate = new NewsTypeEnum(NewsType.AnzcoUpdate, "Market Update", 2, []);
    public static ImportantInfo = new NewsTypeEnum(NewsType.ImportantInfo, "Farmer Info", 3, []);

    private static allNewsTypes: { [newsType: number]: NewsTypeEnum; };

    constructor(
        public readonly newsType: NewsType,
        public readonly description: string,
        public readonly orderNumber: number,
        public readonly categories: string[]
    ) {
        if (!NewsTypeEnum.allNewsTypes) {
          NewsTypeEnum.allNewsTypes = {};
        }
        NewsTypeEnum.allNewsTypes[newsType] = this;
    }

    public static parseNumber(newsType: number): NewsTypeEnum | null {
        return newsType && NewsTypeEnum.allNewsTypes[newsType] ?
            NewsTypeEnum.allNewsTypes[newsType] :
            null;
    }

    public static fromEnum(newsType: NewsType): NewsTypeEnum {
        return NewsTypeEnum.allNewsTypes[newsType];
    }
};
