import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { allAdvicesRoute } from '../constants';
import { loadRecentAdvices} from '../state/actions';
import { Projection } from '../interfaces';
import { selectRecentAdvices } from '../state/selectors';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-recent-advices',
    templateUrl: './recent-advices.component.html',
    styleUrls: ['../advices.scss', './recent-advices.component.scss']
})
export class RecentAdvicesComponent implements OnInit, OnDestroy {
    public advices: Projection.Advice[];
    private subscription: Subscription;

    constructor(
        private readonly store: Store,
        private readonly router: Router,
    ) { }

    public ngOnInit(): void {
        this.store.dispatch(loadRecentAdvices());
        this.subscription = this.store.select(selectRecentAdvices).subscribe((advices: Projection.Advice[]) => this.advices = advices);
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }    

    public goToViewAll(): void {
        this.router.navigate([allAdvicesRoute]);
    }
}