import { Component, OnInit} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Model } from 'src/app/user';
import { loadBobbyCalvesTally } from 'src/app/user/state/actions';
import { selectBobbyTallies, selectSummedBobbyTallies } from 'src/app/user/state/selector';

@Component({
  selector: 'app-bobby-calves-progress-tile',
  templateUrl: './bobby-calves-progress-tile.component.html',
  styleUrls: ['./bobby-calves-progress-tile.component.scss'],
})
export class BobbyCalvesProgressTileComponent implements OnInit {
  private readonly destroy$ = new Subject();
  public bobbyTallies: Model.TotalTally[] = [];
  public multiTalliesSummary: Model.BobbyMultiTalliesSummary;
  currentYear: number = new Date().getFullYear();

  constructor(
    private readonly store: Store,
  ) { }

  public ngOnInit(): void {
    this.store.select(selectSummedBobbyTallies)
      .pipe(takeUntil(this.destroy$))
      .subscribe((multiTalliesSummary) => this.multiTalliesSummary = multiTalliesSummary);
    this.store.select(selectBobbyTallies)
      .pipe(takeUntil(this.destroy$))
      .subscribe((bobbyTallies) => this.bobbyTallies = bobbyTallies);
    this.store.dispatch(loadBobbyCalvesTally()) 
  }
}