<div class="multi-select-calendar">
	<mat-label>{{ startDate | date: "MMM yyyy"}}</mat-label>

	<mat-calendar
		*ngIf="selected.length > 0 else again"
		#calendar
		[startAt]="startDate"
		[minDate]="startDate"
		[maxDate]="endDate"
		[dateClass]="isSelected">
	</mat-calendar>

	<ng-template #again>
		<mat-calendar
			#calendar
			[startAt]="startDate"
			[minDate]="startDate"
			[maxDate]="endDate"
			[dateClass]="isSelected">
		</mat-calendar>
	</ng-template>
</div>
