import { Component, Input } from '@angular/core';
import { Projection } from '../models';
import { FormatDatePipe } from 'src/app/miscellaneous/pipes/format-date.pipe';
import { Store } from '@ngrx/store';
import { NavigationService } from 'src/app/services/navigation.service';
import { selectBooking } from '../../pickup-declarations/state/actions';

@Component({
  selector: 'app-bobby-calves-declaration-submitted-card',
  templateUrl: './bobby-calves-declaration-submitted-card.component.html',
  styleUrls: ['./bobby-calves-declaration-submitted-card.component.scss'],
})
export class BobbyCalvesDeclarationSubmittedCardComponent {
  @Input() pickup: Projection.Pickup;

  constructor(
    private readonly store: Store,
    private readonly dateFormatter: FormatDatePipe,
    private readonly navigationService: NavigationService,
  ) {}

  public get date(): string {
    return this.dateFormatter.transform(this.pickup.date, 'dwdm');
  }

  public fillOutDeclaration(): void {
    this.store.dispatch(selectBooking({
      propertyId: this.pickup.anzcoPropertyId,
      bookingId: this.pickup.booking.id,
    }));
    this.navigationService.pickupDeclarations();
  }
}