<div class="myospri-tile">
  <a href="https://portal.ospri.co.nz/" target="_blank" rel="noopener noreferrer">
    <mat-card id="myospri" data-cy="myOspriTile">
      <img
        src="../../assets/images/MyOspri-Logo.svg"
        alt="myospri image"
      />
      <p>
        Click here to login to MyOSPRI for eASDs and more
      </p>
    </mat-card>
  </a>
</div>
