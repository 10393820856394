import { Component, Input, OnInit } from '@angular/core';
import { Bobby, Projection } from '../models';
import { FormatDatePipe } from 'src/app/miscellaneous/pipes/format-date.pipe';
import { Store } from '@ngrx/store';
import { createOrUpdateBooking } from '../state/actions';
import { hasSeasonBeenCompleted, selectBooking, selectedPropertyName } from '../state/selectors';
import { takeUntil } from 'rxjs/operators';
import { DestroyedService } from 'src/app/services/destroyed.service';
import { MatDialog } from '@angular/material/dialog';
import { SeasonCompletedDialogComponent } from '../season-completed-dialog/season-completed-dialog.component';

@Component({
  selector: 'app-bobby-calves-not-yet-submitted-card',
  templateUrl: './bobby-calves-not-yet-submitted-card.component.html',
  styleUrls: ['./bobby-calves-not-yet-submitted-card.component.scss'],
  providers: [DestroyedService],
})
export class BobbyCalvesNotYetSubmittedCardComponent implements OnInit {
  @Input() pickup: Projection.Pickup;

  public tally: number;
  public note: string;
  public contactNumber: string;
  public initialIsSeasonCompleted: boolean = false;
  public isSeasonCompleted: boolean = false;
  public validBooking: boolean = true;
  private propertyName: string | null = null;

  constructor(
    private readonly dateFormatter: FormatDatePipe,
    private readonly store: Store,
    private readonly dialog: MatDialog,
    private readonly destroyed$: DestroyedService
  ) { }

  public ngOnInit(): void {
    this.store
      .select(selectBooking)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(bookings => {
        const booking: Bobby.Booking = bookings.find(booking =>
          booking.anzcoPropertyId === this.pickup.anzcoPropertyId &&
          booking.calendarDateId === this.pickup.calendarDateId);

          if (booking) {
            this.tally = booking.tally;
            this.note = booking.note;
            this.contactNumber = booking.contactNumber;
          }
      });
    this.store
      .select(hasSeasonBeenCompleted)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(completed => {
        this.isSeasonCompleted = completed;
        this.initialIsSeasonCompleted = completed;
      });
    this.store
      .select(selectedPropertyName)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(propertyName => this.propertyName = propertyName);
  }

  public get date(): string {
    const pickupDateStr = this.dateFormatter
      .transform(this.pickup.date, 'dwdm');
    return pickupDateStr;
  }

  public get closingDateTime(): string {
    const today: Date = new Date();
    const closesDateStr: string =
      today.getDate() === this.pickup.bookingsCloseAt.getDate() &&
      today.getMonth() === this.pickup.bookingsCloseAt.getMonth() ?
        "Today" :
        this.dateFormatter.transform(this.pickup.bookingsCloseAt, 'dwdm');
    const timeStr: string =
      this.pickup.bookingsCloseAt.getHours() === 12 &&
      this.pickup.bookingsCloseAt.getMinutes() === 0 ?
        "Midday" :
        this.dateFormatter.transform(this.pickup.bookingsCloseAt, 't');

    return timeStr === "Midday" ?
        ("at " + timeStr + " " + closesDateStr) :
        (closesDateStr + " at " + timeStr);
  }

  public get statusDescription(): string {
    const currentPickupDateStr = this.dateFormatter.transform(this.pickup.date, 'dwdm');
    return currentPickupDateStr;
  }
  
  public updateTally(tally: string): void {
    this.tally = parseInt(tally);
    // this.store.dispatch(updateTally({
    //   propertyId: this.pickup.anzcoPropertyId,
    //   calendarDateId: this.pickup.calendarDateId,
    //   tally,
    // }));
  }

  public updateContactNumber(contactNumber: string): void {
    this.contactNumber = contactNumber;
    // this.store.dispatch(updateContactNumber({
    //   propertyId: this.pickup.anzcoPropertyId,
    //   calendarDateId: this.pickup.calendarDateId,
    //   contactNumber,
    // }));
  }

  public updateNote(note: string): void {
    this.note = note;
    // this.store.dispatch(updateNote({
    //   propertyId: this.pickup.anzcoPropertyId,
    //   calendarDateId: this.pickup.calendarDateId,
    //   note,
    // }));
  }

  public onSeasonCompleted(): void {
    this.isSeasonCompleted = !this.isSeasonCompleted;

    if (this.isSeasonCompleted) {
      this.dialog.open(
        SeasonCompletedDialogComponent,
        {
          data: {
            propertyName: this.propertyName,
          },
        });
    }
  }

  public noCalves(): void {
    const id = this.pickup.booking?.id;
    this.store.dispatch(createOrUpdateBooking({
      id,
      anzcoPropertyId: this.pickup.anzcoPropertyId,
      calendarDateId: this.pickup.calendarDateId,
      requestedNumOfCalves: 0,
      contactNumber: this.contactNumber,
      note: this.note,
      isSeasonCompleted: this.isSeasonCompleted,
    }));
  }

  public submit(): void {
    this.validBooking = this.tally === 0 ||
      (this.contactNumber !== null && this.contactNumber.trim().length > 0);

    if (this.validBooking) {
      const id = this.pickup.booking?.id;
      this.store.dispatch(createOrUpdateBooking({
        id,
        anzcoPropertyId: this.pickup.anzcoPropertyId,
        calendarDateId: this.pickup.calendarDateId,
        requestedNumOfCalves: this.tally,
        contactNumber: this.contactNumber,
        note: this.note,
        isSeasonCompleted: this.isSeasonCompleted,
      }));
    }
  }

  public enforceMinMax(element: HTMLInputElement): void {
    if (element.value !== "" && parseInt(element.value) < parseInt(element.min)) {
      element.value = element.min;
    }
    if (element.value !== "" && parseInt(element.value) > parseInt(element.max)) {
      element.value = element.max;
    }
  }
}
