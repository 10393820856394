import { createReducer, on } from "@ngrx/store";
import { Model } from "../interfaces";
import {
  loadNewsFeed,
  loadNewsFeedCompleted,
  loadNewsFeedFailure,
  loadNewsItem,
  loadNewsItemCompleted,
  loadNewsItemFailure,
  loadNewsOverview,
  loadNewsOverviewWithoutGlobalSpinner,
  loadNewsOverviewCompleted,
  loadNewsOverviewFailure,
  setNewsFeedType,
  resetNewsFeed
} from "./actions";

export const newsFeatureKey = "news";

const initialState: Model.NewsState = {
  overview: null,
  currentNewsItem: null,
  currentNewsFeed: null,
  initialNewsFeedType: null,
  errorLoading: false
}

export const newsReducer = createReducer<Model.NewsState>(
  initialState,

  on(loadNewsOverview, loadNewsOverviewWithoutGlobalSpinner, (state) => {
    return {
      ...state,
      overview: null,
      errorLoading: false,
    };
  }),

  on(loadNewsOverviewCompleted, (state, overview) => {
    return {
      ...state,
      overview,
    };
  }),

  on(loadNewsItem, (state) => {
    return {
      ...state,
      currentNewsItem: null,
      errorLoading: false,
    };
  }),

  on(loadNewsItemCompleted, (state, newsItem) => {
    return {
      ...state,
      currentNewsItem: newsItem,
    };
  }),

  on(loadNewsFeed, (state) => {
    return {
      ...state,
      overview: null,
      currentNewsFeed: null,
      errorLoading: false
    };
  }),

  on(loadNewsFeedCompleted, (state, feed) => {
    return {
      ...state,
      currentNewsFeed: feed,
    };
  }),

  on(setNewsFeedType, (state, feedType) => {
    return {
      ...state,
      initialNewsFeedType: feedType.newsType,
      currentNewsFeed: state.currentNewsFeed?.feedType === feedType.newsType ? state.currentNewsFeed : null,
    };
  }),

  on(resetNewsFeed, (state) => {
    return {
      ...state,
      currentNewsFeed: null,
    };
  }),

  on(loadNewsFeedFailure, loadNewsItemFailure, loadNewsOverviewFailure, (state) => {
    return {
      ...initialState,
      errorLoading: true,
    };
  }),
);
