<mat-card>
  <mat-card-title>
    <span role="heading">Pick-up for {{ date }}</span>
    <span class="pickup-date" role="heading"></span>
  </mat-card-title>
  <mat-card-content>
    <div>
      <label>
        <strong>Calf Tally</strong>
      </label>
      <div>
        {{ pickup.booking.requestedNumOfCalves }}
      </div>
    </div>
    <div>
      <label>
        <strong>Requested By</strong>
      </label>
      <div>
        {{ pickup.booking.requestedBy }}
      </div>
    </div>
    <div>
      <label>
        <strong>Contact Number</strong>
      </label>
      <div>
        {{ pickup.contactNumber }}
      </div>
    </div>
    <div class="note-div" *ngIf="pickup.booking.note">
      <label class="note-label">
        <strong>Note to Rep</strong>
      </label>
      <div class="note-content">
        {{ pickup.booking.note }}
      </div>
    </div>

    <div class="mobile-alignment">
      <p class="privacy-wording">
        Contact numbers will be passed on to your Bobby Calf transporter - please refer
        to our <a href="https://anzcofoods.com/privacy-policy/" target="_blank">Privacy Policy</a>
      </p>
      <button
        mat-raised-button
        class="nav-button"
        data-cy="editPickupRequestButton"
        (click)="editPickupRequest()">
        Edit
      </button>
    </div>

  </mat-card-content>
</mat-card>
