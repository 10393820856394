<form>
  <fieldset class="user-info" [formGroup]="userInfoForm">
    <div class="form-field email">
      <label for="email">Email *</label>
      <input matInput type="text" id="email" formControlName="email" />
    </div>
    <div class="form-field advantage-email">
      <label for="advantageEmail">ANZCO Advantage Username</label>
      <input
        matInput
        type="text"
        id="advantageEmail"
        formControlName="advantageEmail"
      />
    </div>
    <div class="form-field">
      <label for="firstName">First Name *</label>
      <input matInput type="text" id="firstName" formControlName="firstName" />
    </div>
    <div class="form-field">
      <label for="surname">Surname *</label>
      <input matInput type="text" id="surname" formControlName="surname" />
    </div>
    <div class="form-field">
      <label for="contactNumber">Contact Number</label>
      <input
        matInput
        type="text"
        id="contactNumber"
        formControlName="contactNumber"
      />
    </div>
  </fieldset>
  <fieldset class="search" [formGroup]="searchForm">
    <div class="form-field property-search">
      <label for="propertySearch">Property (Search)</label>
      <input
        matInput
        type="text"
        id="propertySearch"
        formControlName="searchTerm"
      />
    </div>
    <div class="form-field producer-search">
      <label for="producerSearch">Producer Code (Search)</label>
      <input
        matInput
        type="text"
        id="producerSearch"
        formControlName="producerCode"
      />
    </div>
  </fieldset>
</form>
