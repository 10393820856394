<table mat-table
       [dataSource]="declarations">
    <ng-container matColumnDef="propertyName">
        <th mat-header-cell *matHeaderCellDef>Property</th>
        <td mat-cell *matCellDef="let declaration">
            <div class="sub-row">
                <p>{{ declaration.propertyName }}</p>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="pickupDate">
        <th mat-header-cell *matHeaderCellDef>Pick-up Date</th>
        <td mat-cell *matCellDef="let declaration">
            <div class="sub-row">
                <p>{{ declaration.pickupDate | date: "dd/MM/yyyy" }}</p>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="declaredTally">
        <th mat-header-cell *matHeaderCellDef class="center-column">Declared Tally</th>
        <td mat-cell *matCellDef="let declaration">
            <div class="sub-row center-column">
                <p>{{ declaration.declaredTally === null ? 'Paper Declaration' : declaration.declaredTally }}</p>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="pickedUpTally">
        <th mat-header-cell *matHeaderCellDef class="center-column">Picked Up Tally</th>
        <td mat-cell *matCellDef="let declaration">
            <div class="sub-row center-column">
                <p>{{ declaration.pickedUpTally !== null ? declaration.pickedUpTally : '-' }}</p>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="timeOfLastFeed">
        <th mat-header-cell *matHeaderCellDef>Last Fed At</th>
        <td mat-cell *matCellDef="let declaration">
            <div class="sub-row">
                <p>{{ declaration.timeOfLastFeed !== null && declaration.declaredTally ? declaration.timeOfLastFeed : '-' }}</p>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="recordedBy">
        <th mat-header-cell *matHeaderCellDef>Recorded By</th>
        <td mat-cell *matCellDef="let declaration">
            <div class="sub-row">
                <p>{{ declaration.recordedBy === null ? '-' : declaration.recordedBy }}</p>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="rejected">
        <th mat-header-cell *matHeaderCellDef class="center-column">Rejected</th>
        <td mat-cell *matCellDef="let declaration">
            <div class="sub-row center-column">
                <p>{{ declaration.rejected !== null ? declaration.rejected : '-' }}</p>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="rejectReasons">
        <th mat-header-cell *matHeaderCellDef>Reject Reason</th>
        <td mat-cell *matCellDef="let declaration">
            <div class="sub-row">
                <p *ngIf="declaration.rejectReasons !== null">{{ declaration.rejectReasons }}</p>
                <p *ngIf="declaration.rejectReasons === null">-</p>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="transportDriver">
        <th mat-header-cell *matHeaderCellDef>Driver</th>
        <td mat-cell *matCellDef="let declaration">
            <div *ngIf="declaration.transportDriver" class="sub-row">
                <p>{{ declaration.transportDriver }} ({{ declaration.transportName }})</p>
            </div>
            <div *ngIf="!declaration.transportDriver" class="sub-row">
                <p>-</p>
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let compliance; columns: tableColumns"></tr>
</table>