import { Injectable } from "@angular/core";
import { Model } from "../admin.interfaces";
import { environment } from '../../../environments/environment';
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { loadAdminTasksStarted, loadAdminTasksCompleted } from "../state/actions";

@Injectable({ providedIn: 'root' })
export class CommandGetAdminUserTasks {
    constructor(
        private readonly store: Store,
        private readonly http: HttpClient,
    ) {}

    public async execute(): Promise<void> {
        this.store.dispatch(loadAdminTasksStarted());
        const adminTasks: Model.AdminTask[] = await this.requestAdminTasks();
        this.store.dispatch(loadAdminTasksCompleted({ adminTasks }));
    }

    private async requestAdminTasks(): Promise<Model.AdminTask[]> {
        return await this.http
            .get<Model.AdminTask[]>(environment.apiUrl + "/api/admin/usertasks")
            .toPromise();
    }
}