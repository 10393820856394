import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { Selector } from "../state/selector";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CommandPostDeclineAdminUserTask } from "../commands/command-post-decline-admin-user-task";
import { DeclineReason, DeclineReasonEnum } from "src/app/miscellaneous/enums/decline-reason.enum";
import { chooseDeclineReason, changeDeclineReasonText } from "../state/actions";

@Component({
    selector: "app-decline-reason-dialog",
    templateUrl: "./decline-reason-dialog.component.html",
    styleUrls: ["./decline-reason-dialog.component.scss"],
})
export class DeclineReasonDialogComponent implements OnInit, OnDestroy {
    public allReasons = DeclineReasonEnum.all();
    public declineReason: DeclineReason;
    public firstName: string;
    public email: string;

    private subscriptions: Subscription[];
    private declineReasonText: string;
    private adminTaskId: number;
    private userId: number;

    constructor(
        private readonly dialogRef: MatDialogRef<DeclineReasonDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data,
        private readonly store: Store,
        private readonly selector: Selector,
        private readonly commandPostDeclineAdminUserTask: CommandPostDeclineAdminUserTask,
    ) {
        this.firstName = data.firstName;
        this.email = data.email;
        this.adminTaskId = data.adminTaskId;
        this.userId = data.userId;
        this.subscriptions = [
            this.store.select(this.selector.selectEditDeclineReasonText).subscribe(
                (declineReasonText: string) => this.declineReasonText = declineReasonText),
            this.store.select(this.selector.selectEditDeclineReason).subscribe(
                (declineReason: DeclineReason) => this.declineReason = declineReason),
        ];
    }

    public ngOnInit(): void {
        this.store.dispatch(changeDeclineReasonText({ declineText: "" }))
        this.store.dispatch(chooseDeclineReason({ declineReason: DeclineReason.NotRecognisedProducer }))
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
    }

    public change(declineText: string): void {
        this.store.dispatch(changeDeclineReasonText({ declineText }))
    }

    public chooseReason(declineReason: DeclineReason) {
        this.store.dispatch(chooseDeclineReason({ declineReason }))
    }

    public chooseOtherReason() {
        this.store.dispatch(chooseDeclineReason({ declineReason: DeclineReason.Other }))
    }

    public async decline(): Promise<void> {
        await this.commandPostDeclineAdminUserTask.execute(
            this.adminTaskId,
            this.userId,
            this.declineReason,
            this.declineReasonText,
        );
        this.dialogRef.close();
    }

    public cancel(): void {
        this.dialogRef.close();
    }
}