import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectNewRegistration } from 'src/app/user/state/selector';
import { environment } from 'src/environments/environment';
import { registrationHelpRoute } from '../';

@Component({
  selector: 'app-registration-pending',
  templateUrl: './registration-pending.component.html',
  styleUrls: ['../registration.scss']
})
export class RegistrationPendingComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject();
  public helpRoute: string;
  public pendingMessage: string;
  public title: string;

  constructor(
    private readonly msalInstance: MsalService,
    private readonly store: Store,
  ) {
    this.helpRoute = '/' + registrationHelpRoute;
  }

  public ngOnInit(): void {
    this.store.select(selectNewRegistration)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (isNewRegistration) => {
          if (isNewRegistration) {
            this.title = "Complete Registration";
            this.pendingMessage = "We will verify your account and get back to you within 2 working days.";
          } else {
            this.title = "Registration Pending";
            this.pendingMessage = "We are currently processing your application and will be back to you soon.";
          }
        });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public logout(): void {
    this.msalInstance.logoutRedirect({ postLogoutRedirectUri: environment.webUrl });
  }
}