import { createReducer, on } from '@ngrx/store';
import { Model } from '../interfaces';
import * as reportingActions from './actions';

export const reportsFeatureKey = 'reports';

function getDefaultStartDate(): Date {
  const defaultStartDate = new Date();
  defaultStartDate.setFullYear(defaultStartDate.getFullYear() - 1);
  return defaultStartDate;
}

const initialState: Model.ReportsState = {
  availableProperties: [],
  error: '',
  query: {
    startDate: getDefaultStartDate(),
    endDate: new Date(),
    propertyIds: [],
  },
};

export const reportsReducer = createReducer<Model.ReportsState>(
  initialState,

  on(reportingActions.getFinancialReportOptionsCompleted, (state, { availableProperties }) => {
    const availablePropertiesIds: number[] = availableProperties.map(property => property.propertyId);

    return {
      ...state,
      availableProperties,
      query: {
        ...state.query,
        propertyIds: state.query.propertyIds.length === 0 ?
          availablePropertiesIds :
          (state.query.propertyIds.every(propertyId => availablePropertiesIds.includes(propertyId)) ?
            state.query.propertyIds :
            availablePropertiesIds),
      },
    };
  }),

  on(reportingActions.downloadFinancialReport, (state) => {
    return {
      ...state,
      error: '',
    };
  }),

  on(reportingActions.downloadFinancialReportFailure, (state, { error }) => {
    return {
      ...state,
      error: error,
    };
  }),

  on(reportingActions.filterUpdated, (state, { update }) => {
    return {
      ...state,
      error: '',
      query: {
        ...state.query,
        ...update,
      },
    };
  }),

  on(reportingActions.toggleFilterProperty, (state, { propertyId }) => {
    return {
      ...state,
      error: '',
      query: {
        ...state.query,
        propertyIds: state.query.propertyIds.includes(propertyId)
          ? state.query.propertyIds.filter(
              (existing) => existing !== propertyId
            )
          : state.query.propertyIds.concat(propertyId),
      },
    };
  }),

  on(reportingActions.toggleFilterAllProperties, (state) => {
    return {
      ...state,
      error: '',
      query: {
        ...state.query,
        propertyIds:
          state.query.propertyIds.length === state.availableProperties.length ?
            [] :
            state.availableProperties.map((property) => property.propertyId),
      },
    };
  }),

  on(reportingActions.clearFilter, (state) => {
    return {
      ...state,
      error: '',
      query: {
        startDate: getDefaultStartDate(),
        endDate: new Date(),
        propertyIds: state.availableProperties.map(property => property.propertyId),
      },
    };
  }),
);
