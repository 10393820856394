<mat-toolbar>
  <div class="menu-div">
    <ng-container *ngIf="shouldDisplayAdminMenu$ | async; else hamburgerIcon">
      <button
        mat-button
        class="main-menu-button"
        data-cy="newRequestMenuItem"
        (click)="navigateToNewRequests()"
      >
        New Requests
      </button>
      <div
        *ngIf="showNewRequestsNotification$ | async"
        class="notification-dot"
      >
        <p>{{ amountNewRequests$ | async }}</p>
      </div>
      <div
        *ngIf="(showNewRequestsNotification$ | async) === false"
        class="hidden-notification-dot"
      ></div>
      <button
        mat-button
        class="main-menu-button"
        (click)="navigateToChangeRequests()"
      >
        Change Requests
      </button>
      <div
        *ngIf="showChangeRequestsNotification$ | async"
        class="notification-dot"
      >
        <p>{{ amountChangeRequests$ | async }}</p>
      </div>
      <div
        *ngIf="(showChangeRequestsNotification$ | async) === false"
        class="hidden-notification-dot"
      ></div>
      <button
        mat-button
        class="main-menu-button"
        (click)="navigateToPortalUsers()"
        data-cy="portalUsersMenuItem"
      >
        Portal Users
      </button>
    </ng-container>
    <ng-template #hamburgerIcon>
      <button
        *ngIf="(shoudlHideMenu | async) === false"
        (click)="menuClicked.emit($event)"
        class="menu-icon hamburger">
        <mat-icon>menu</mat-icon>
        Menu
      </button>
    </ng-template>
  </div>
</mat-toolbar>
