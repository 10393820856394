import * as rg4js from 'raygun4js';
import Package from '../../package.json';
import { environment } from 'src/environments/environment';

export class RaygunWrapper {
  public static _initialise(): void {
    this.raygun('apiKey', environment.raygunKey);
    this.raygun('setVersion', Package.version);
    this.raygun('enableCrashReporting', true);
  }

  public static handleError(error: any) {
    this.raygun('send', {
      error,
    });
  }

  public static handleEvent(event: any) {
    this.raygun('trackEvent', event);
  }

  public static setUser(email: string, name: string): void {
    this.raygun('setUser', {
      identifier: email,
      isAnonymous: false,
      email: email,
      firstName: name,
      fullName: name,
    });
  }

  /**
   * This calls wipes out local logs so you can't see where they're coming from.
   * So comment out this call when developing locally.
   */
  private static raygun(...args: any[]): void {
    rg4js(...args);
  }
}

RaygunWrapper._initialise();
