import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Selector } from './state/selector';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { loadSSOLinkStarted } from './state/actions';

@Component({
  template: '',
})
export class AdvantageRedirectComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject();

  constructor(
    private readonly store: Store,
    private readonly selector: Selector
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(loadSSOLinkStarted());

    this.store
      .select(this.selector.selectSSOLink)
      .pipe(takeUntil(this.destroy$))
      .subscribe((link) => {
        if (link) {
          window.location.href = link;
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
