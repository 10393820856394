import { createAction, props } from '@ngrx/store';
import { Model } from '../interfaces';

export const loadSSOLinkStarted = createAction('[Advantage] Load SSO Started');
export const loadSSOLinkCompleted = createAction(
  '[Advantage] Load SSO Completed-',
  props<{ link: string }>()
);
export const loadSSOLinkFailure = createAction(
  '[Advantage] Load SSO Failure-',
  props<{ fallbackLink: string }>()
);

export const loadPointsStarted = createAction('[Advantage] Load Points');
export const loadPointsCompleted = createAction(
  '[Advantage] Load Points Completed',
  props<{ response: Model.AdvantagePointsResponse }>()
);
export const loadPointsFailure = createAction('[Advantage] Load Points Failure');
