import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { userEditedCompleted, userEditedStarted } from "../state/actions";
import { Model } from "../admin.interfaces";

@Injectable({ providedIn: 'root' })
export class CommandPostEditUser {
    constructor(
        private readonly store: Store,
        private readonly http: HttpClient,
    ) {}

    public async execute(
        user: Model.User,
        propertyRoles: Model.PropertyRole[],
    ): Promise<void> {
        this.store.dispatch(userEditedStarted());
        await this.editUser(
            user,
            propertyRoles,
        );
        this.store.dispatch(userEditedCompleted());
    }

    private async editUser(
        user: Model.User,
        propertyRoles: Model.PropertyRole[],
    ): Promise<void> {
        const params = {
            userId: user.userId,

            firstname: user.firstName,
            surName: user.surName,
            gstNumber: user.gstNumber,
            contactNumber: user.contactNumber,
            advantageUsername: user.advantageUsername,
            propertyRoles: propertyRoles
                .filter(propertyRole => propertyRole.locked)
                .map(propertyRole => {
                    const role = {
                        propertyId: propertyRole.propertyId,
                        roleId: propertyRole.role,
                    };

                    return role;
                }),
        };

        await this.http
            .post(environment.apiUrl + "/api/admin/edit-user", params)
            .toPromise();
    }
}