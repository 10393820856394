import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { RoleEnum } from 'src/app/miscellaneous/enums/role.enum';
import { staffManagementRoute } from './constants';
import { Model } from './interfaces';
import { Selector } from './state/selector';

@Component({
  selector: 'app-roles-description',
  templateUrl: './roles-description.component.html',
  styleUrls: ['./staff-management.component.scss'],
})
export class RolesDescriptionComponent {
  public readonly roleTableColumns: string[] = [
    'role',
    'requestBobby',
    'receiveNotifications',
    'viewProcessing',
    'viewFinancials',
    'manageStaff',
    'comment',
  ];

  public readonly descriptions: Model.RoleDescription[] = [
    {
      role: RoleEnum.Owner,
      requestBobby: true,
      receiveNotifications: true,
      viewProcessing: true,
      viewFinancials: true,
      manageStaff: true,
      comment:
        'Has full access to their site, except an owner cannot add or remove other owners - please contact the Livestock Admin team. Bobby Calf users can request Bobby Calf pick-ups in season.',
    },
    {
      role: RoleEnum.Manager,
      requestBobby: true,
      receiveNotifications: true,
      viewProcessing: true,
      viewFinancials: true,
      manageStaff: false,
      comment:
        'Allows access to view weights and financial data, but not managing staff. Bobby Calf users can request Bobby Calf pick-ups in season.',
    },
    {
      role: RoleEnum.Limited,
      requestBobby: true,
      receiveNotifications: true,
      viewProcessing: true,
      viewFinancials: false,
      manageStaff: false,
      comment:
        'Allows access to view weights only, no financial data is visible to this user. Bobby Calf users can request Bobby Calf pick-ups in season.',
    },
    {
      role: RoleEnum.ServiceProvider,
      requestBobby: false,
      receiveNotifications: true,
      viewProcessing: true,
      viewFinancials: true,
      manageStaff: false,
      comment:
        'Intended for accountants and financial advisors, allows view access to all data, but not able to manage staff. Not able to request Bobby Calf pick-ups.',
    },
  ];

  constructor(
    private readonly router: Router,
    private readonly store: Store,
    private readonly selector: Selector
  ) {}

  public backToStaff(): void {
    this.store
      .select(this.selector.selectEditedStaff)
      .subscribe((staff: Model.Staff) => {
        if (staff && staff.userId) {
          const userId: number = staff.userId;

          this.router.navigate([staffManagementRoute], {
            queryParams: { userId },
          });
        } else {
          this.router.navigate([staffManagementRoute], { queryParams: {} });
        }
      })
      .unsubscribe();
  }
}
