import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { documentsReducer, documentsFeatureKey } from './state/reducer';
import { DocumentsEffects } from './state/effects';
import { AngularMaterialModule } from '../../miscellaneous';
import { FormatDatePipe } from '../../miscellaneous/pipes/format-date.pipe';
import { DocumentsComponent } from './documents.component';
import { DeclarationsTableComponent } from './declarations-table/declarations-table.component';
import { DeclarationCardsComponent } from './declaration-cards/declaration-cards.component';

@NgModule({
  imports: [
    StoreModule.forFeature(documentsFeatureKey, documentsReducer),
    EffectsModule.forFeature([DocumentsEffects]),
    FormsModule,
    CommonModule,
    AngularMaterialModule,
  ],
  exports: [],
  declarations: [
    DocumentsComponent,
    DeclarationsTableComponent,
    DeclarationCardsComponent,
  ],
  providers: [FormatDatePipe],
})
export class DocumentsModule {}
