<div *ngIf="loaded$ | async" class="default-container">
  <app-bobby-calves-outage *ngIf="errored$ | async"></app-bobby-calves-outage>

  <div *ngIf="(errored$ | async) === false">
    <section *ngIf="properties.length > 0" class="filter-container">
      <div class="labeled-data-item drop-down">
        <mat-label>Property</mat-label>
        <div class="property-refresh">
          <mat-select
            [(ngModel)]="selectedPropertyId"
            (valueChange)="loadPickupsForProperty($event)"
            [disabled]="!(properties?.length > 1)">
            <mat-option
              *ngFor="let property of properties"
              [value]="property.id">
              {{ property.name }}
            </mat-option>
          </mat-select>
          <button mat-button class="refresh" (click)="loadPickupsForLastProperty()">
            Refresh
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
      </div>
    </section>

    <app-bobby-calves-season-completed-card *ngIf="isSeasonCompleted"></app-bobby-calves-season-completed-card>

    <section *ngIf="isInSeason" class="pickups">
      <div *ngFor="let pickup of pickups$ | async">
        <app-bobby-calves-upcoming-card
          *ngIf="pickup.status === 0"
          [pickup]="pickup">
        </app-bobby-calves-upcoming-card>
        <app-bobby-calves-not-yet-submitted-card
          *ngIf="pickup.status === 1"
          [pickup]="pickup">
        </app-bobby-calves-not-yet-submitted-card>
        <app-bobby-calves-booking-submitted-card
          *ngIf="pickup.status === 2"
          [pickup]="pickup">
        </app-bobby-calves-booking-submitted-card>
        <app-bobby-calves-awaiting-approval-card
          *ngIf="pickup.status === 3"
          [pickup]="pickup">
        </app-bobby-calves-awaiting-approval-card>
        <app-bobby-calves-approved-card
          *ngIf="pickup.status === 4"
          [pickup]="pickup">
        </app-bobby-calves-approved-card>
        <app-bobby-calves-amended-card
          *ngIf="pickup.status === 5"
          [pickup]="pickup">
        </app-bobby-calves-amended-card>
        <app-bobby-calves-no-calves-submitted-card
          *ngIf="pickup.status === 6"
          [pickup]="pickup">
        </app-bobby-calves-no-calves-submitted-card>
        <app-bobby-calves-no-booking-submitted-card
          *ngIf="pickup.status === 7"
          [pickup]="pickup">
        </app-bobby-calves-no-booking-submitted-card>
        <app-bobby-calves-approved-declaration-card
          *ngIf="pickup.status === 8"
          [pickup]="pickup">
        </app-bobby-calves-approved-declaration-card>
        <app-bobby-calves-amended-declaration-card
          *ngIf="pickup.status === 9"
          [pickup]="pickup">
        </app-bobby-calves-amended-declaration-card>
        <app-bobby-calves-declaration-submitted-card
          *ngIf="pickup.status === 10"
          [pickup]="pickup">
        </app-bobby-calves-declaration-submitted-card>
        <app-bobby-calves-calves-picked-up-digital-declaration-card
          *ngIf="pickup.status === 11"
          [pickup]="pickup">
        </app-bobby-calves-calves-picked-up-digital-declaration-card>
        <app-bobby-calves-calves-picked-up-paper-declaration-card
          *ngIf="pickup.status === 12"
          [pickup]="pickup">
        </app-bobby-calves-calves-picked-up-paper-declaration-card>
        <app-bobby-calves-late-entry-card
          *ngIf="pickup.status === 101"
          [pickup]="pickup">
        </app-bobby-calves-late-entry-card>
      </div>
    </section>

    <app-bobby-calves-preseason-landing *ngIf="isInPreSeason"></app-bobby-calves-preseason-landing>
    <app-bobby-calves-end-of-season *ngIf="isInPostSeason"></app-bobby-calves-end-of-season>

    <div
      *ngIf="shouldShowCalendar"
      (click)="calendar()"
      class="link-bar">
      <div>
        <mat-icon class="icon-display">date_range</mat-icon>
        <mat-label>Calendar and contacts</mat-label>
      </div>
      <i class="bts bt-angle-right bt-2x"></i>
    </div>
  </div>
</div>
