import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Selector } from '../state/selector';
import { Subscription } from 'rxjs';
import { Model, Projection } from '../admin.interfaces';
import { editUserFixRoles } from '../state/actions';
import { DeclineReasonDialogComponent } from '../decline-reason-dialog/decline-reason-dialog.component';
import { CommandAssignAdminUserTask } from '../commands/command-assign-admin-user-task';
import { CommandPostApproveAdminUserTask } from '../commands/command-post-approve-admin-user-task';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { adminRoute, newRequestsRoute, portalUserRoute } from '../constants';

@Component({
  selector: 'app-new-user-request',
  templateUrl: './new-user-request.component.html',
  styleUrls: ['../admin.scss'],
})
export class NewUserRequestComponent implements OnInit, OnDestroy {
  public adminTaskDisabled: boolean;
  public editUserConfirmed: boolean;
  public user: Projection.EditUser;
  public validToConfirm: boolean;

  private propertyRoles: Model.PropertyRole[];
  private subscriptions: Subscription[];
  private adminTaskId: number;

  constructor(
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly store: Store,
    private readonly selector: Selector,
    private readonly commandAssignAdminUserTask: CommandAssignAdminUserTask,
    private readonly commandPostApproveAdminUserTask: CommandPostApproveAdminUserTask
  ) {}

  public ngOnInit(): void {
    this.subscriptions = [
      this.store
        .select(this.selector.selectEditUser)
        .subscribe((user: Projection.EditUser) => (this.user = user)),
      this.store
        .select(this.selector.selectEditUserDisabled)
        .subscribe((disabled: boolean) => (this.adminTaskDisabled = disabled)),
      this.store
        .select(this.selector.selectEditUserConfirmed)
        .subscribe(
          (confirmed: boolean) => (this.editUserConfirmed = confirmed)
        ),
      this.store
        .select(this.selector.selectEditUserPropertyRolesModels)
        .subscribe(
          (propertyRoles: Model.PropertyRole[]) =>
            (this.propertyRoles = propertyRoles)
        ),
      this.store
        .select(this.selector.selectEditAdminTaskId)
        .subscribe((adminTaskId: number) => (this.adminTaskId = adminTaskId)),
      this.store
        .select(this.selector.selectValidToConfirm)
        .subscribe(
          (validToConfirm: boolean) => (this.validToConfirm = validToConfirm)
        ),
    ];
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }

  public async assign(): Promise<void> {
    await this.commandAssignAdminUserTask.execute(this.adminTaskId);
  }

  public confirm(): void {
    this.store.dispatch(editUserFixRoles());
  }

  public approve(): void {
    this.store
      .select(this.selector.selectEditUserModel)
      .subscribe(async (user: Model.User) => {
        await this.commandPostApproveAdminUserTask.execute(
          this.adminTaskId,
          user,
          this.propertyRoles
        );
        this.router.navigate([adminRoute, newRequestsRoute]);
      })
      .unsubscribe();
  }

  public decline(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      firstName: this.user.firstName,
      email: this.user.email,
      adminTaskId: this.adminTaskId,
      userId: this.user.userId,
    };

    const declineDialogRef: MatDialogRef<any, any> = this.dialog.open(
      DeclineReasonDialogComponent,
      dialogConfig
    );
    const subscription = declineDialogRef.afterClosed().subscribe(() => {
      this.router.navigate([adminRoute, newRequestsRoute]);
      subscription.unsubscribe();
    });
  }

  public cancel(): void {
    this.router.navigate([adminRoute, newRequestsRoute]);
  }
}
