import { createFeatureSelector, createSelector } from "@ngrx/store";
import { Documents } from "../interfaces";
import { documentsFeatureKey } from "./reducer";

const getDocumentsState = createFeatureSelector<Documents.State>(documentsFeatureKey);

export const selectProperties = createSelector(
    getDocumentsState,
    (state: Documents.State) => state.properties.slice(),
);

export const selectDeclarations = createSelector(
    getDocumentsState,
    (state: Documents.State) => state.declarations.slice(),
);

export const selectTotalDeclarations = createSelector(
    getDocumentsState,
    (state: Documents.State) => state.totalDeclarations,
);

export const selectDeclarationsFilter = createSelector(
    getDocumentsState,
    (state: Documents.State) => state.filter,
);