import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { selectOverviewLoading } from '../state/selectors';

@Component({
  selector: 'app-news-homepage-widget',
  templateUrl: './news-homepage-widget.html',
  styleUrls: ['./news-homepage-widget.scss']
})
export class NewsHomepageWidgetComponent implements OnInit {
  public overviewLoading$ : Observable<boolean>;

  constructor(
    private readonly store: Store,
  ) { }

  public ngOnInit(): void {
    this.overviewLoading$ = this.store
      .select(selectOverviewLoading)

      // Put the execution at the end of the browser queue.
      // Neeeds to happen because it may be updated async and cause exceptions.
      .pipe(delay(0));
  }
}