import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { closeHelp } from './state/actions';
import { Selector } from './state/selector';
import { selectShouldDisplayBobbyMenu } from 'src/app/user/state/selector';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent {
  public active: Observable<boolean>;

  public readonly shouldDisplayBobbyMenu$: Observable<boolean> =
    this.store.select(selectShouldDisplayBobbyMenu);

  constructor(
    private readonly store: Store,
    private readonly selector: Selector
  ) {
    this.active = this.store.select(this.selector.selectHelpActive);
  }

  public closeHelp(): void {
    this.store.dispatch(closeHelp());
  }
}
