import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AdvantageEffects } from './state/effects';
import { featureReducerKey, advantageReducer } from './state/reducer';

export { AdvantageTileComponent } from './advantage-tile.component';
export { AdvantageRedirectRoute } from './constants';
export { AdvantageRedirectComponent } from './advantage-redirect.component';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(featureReducerKey, advantageReducer),
    EffectsModule.forFeature([AdvantageEffects]),
  ],
})
export class AdvantageTileModule {}
