<div class="default-container" data-cy="staff-page-shell">
    <div class="headline">
        <h2>{{ propertyName }} Staff</h2>
        <button mat-raised-button
                color="primary"
                class="icon-button"
                data-cy="addStaffButton"
                (click)="addStaff()">
            <i class="btr bt-plus bt-lg"></i>
        </button>
    </div>
  
    <p *ngIf="users.length">Please click on a staff member to edit details</p>

    <div class="mat-elevation-z8">
        <table mat-table
               [dataSource]="users"
               class="clickable-rows"
               data-cy="allStaffTable">
            <ng-container matColumnDef="firstName">
                <th mat-header-cell
                    *matHeaderCellDef>
                    First Name
                </th>
                <td mat-cell
                    *matCellDef="let user">
                    <div class="sub-row">
                        <p>{{ user.firstName }}</p>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="surname">
                <th mat-header-cell
                    *matHeaderCellDef>
                    Surname
                </th>
                <td mat-cell
                    *matCellDef="let user">
                    <div class="sub-row">
                        <p>{{ user.surname }}</p>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell
                    *matHeaderCellDef
                    class="non-mobile">
                    Email
                </th>
                <td mat-cell
                    *matCellDef="let user"
                    class="non-mobile">
                    <div class="sub-row">
                        <p>{{ user.email }}</p>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="isActive" >
                <th mat-header-cell
                    *matHeaderCellDef>
                    Active
                </th>
                <td mat-cell
                    *matCellDef="let user">
                    <div class="sub-row">
                        <i *ngIf="user.isActive" class="btr bt-check"></i>
                        <i *ngIf="!user.isActive" class="btr bt-ban"></i>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions" >
                <th mat-header-cell
                    *matHeaderCellDef
                    class="icon-button-column">
                    Role
                </th>
                <td mat-cell
                    *matCellDef="let user">
                    <div *ngIf="user.canChange"
                            class="sub-row">
                        <button mat-raised-button
                                color="primary"
                                class="icon-button"
                                (click)="removeStaff(user.userId, user.firstName, user.surname, $event)"
                                data-cy="removeStaffButton">
                            <i class="btr bt-trash bt-lg"></i>
                        </button>
                    </div>
                    <div *ngIf="!user.canChange"
                            class="sub-row">
                        <p>Owner</p>
                    </div>
                </td>
            </ng-container>

            <tr *matHeaderRowDef="userTableColumns; sticky: true"
                mat-header-row></tr>
            <tr mat-row
                *matRowDef="let user; columns: userTableColumns"
                (click)="selectStaff(user.userId)"></tr>
        </table>

        <div *ngIf="!users.length"
             class="no-results">
             No staff connected to this property.
        </div>
    </div>
</div>