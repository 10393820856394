import { Component, OnInit } from '@angular/core';
import { Projection } from '../models';
import { Store } from '@ngrx/store';
import { selectProperties} from '../state/selectors';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { selectSelectableBooking } from '../state/actions';
import { FormatDatePipe } from 'src/app/miscellaneous/pipes/format-date.pipe';

@Component({
  selector: 'app-pickup-declaration-property-selection',
  templateUrl: './pickup-declaration-property-selection.component.html',
  styleUrls: ['./pickup-declaration-property-selection.component.scss'],
})
export class PiclupDeclarationPropertySelectionComponent implements OnInit {
  private readonly destroy$ = new Subject();
  public properties: Projection.OpenDeclarationProperties[] = [];

  constructor(
    private readonly store: Store,
    private readonly dateFormatter: FormatDatePipe,
  ) { }

  public ngOnInit(): void {
    this.store
      .select(selectProperties)
      .pipe(takeUntil(this.destroy$))
      .subscribe(properties => this.properties = properties);
  }

  public date(bookingId: string): string {
    const pickupDate = this.properties
      .find(property => property.bookingId === bookingId)
      .pickupDate
    const pickupDateStr = this.dateFormatter
      .transform(pickupDate, 'dwdm');
    return pickupDateStr;
  }

  public selectBooking(
    propertyId: number,
    bookingId: string,
  ) {
    this.store.dispatch(selectSelectableBooking({ propertyId, bookingId }));
  }
}