import { Injectable } from "@angular/core";
import { Model } from "../admin.interfaces";
import { environment } from '../../../environments/environment';
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { searchPropertiesCompleted, searchPropertiesStarted } from "../state/actions";
import { Role } from "src/app/miscellaneous/enums/role.enum";

interface Response {
    producerCodeKnown: boolean;
    properties: Array<{
        propertyId: number;
        propertyName: string;
        roleId: number;
    }>;
};

@Injectable({ providedIn: 'root' })
export class CommandSearchProperties {
    constructor(
        private readonly store: Store,
        private readonly http: HttpClient,
    ) {}

    public async execute(
        userId: number,
        producerCode: string,
        searchTerm: string,
    ): Promise<void> {
        this.store.dispatch(searchPropertiesStarted());
        const result: Response = await this.requestAdminTasks(
            userId,
            producerCode,
            searchTerm,
        );
        const properties: Model.PropertyRole[] = result.properties.map(property => {
            const modelProperty: Model.PropertyRole = {
                propertyId: property.propertyId,
                propertyName: property.propertyName,
                role: property.roleId ?? Role.Unknown,
                locked: property.roleId !== null,
            };

            return modelProperty;
        });

        this.store.dispatch(searchPropertiesCompleted({
            producerCodeKnown: result.producerCodeKnown,
            properties,
        }));
    }

    private async requestAdminTasks(
        userId: number,
        producerCode: string,
        searchTerm: string,
    ): Promise<Response> {
        const params = {
            userId,
            producerCode,
            searchTerm,
        };

        return await this.http
            .post<Response>(environment.apiUrl + "/api/admin/properties-search", params)
            .toPromise();
    }
}