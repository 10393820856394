import { createReducer, on } from '@ngrx/store';
import { Model } from '../interfaces';
import {
  loadSSOLinkCompleted,
  loadSSOLinkFailure,
  loadPointsCompleted,
  loadPointsFailure,
} from './actions';

export const featureReducerKey = 'advantage';

const initialState: Model.AdvantageState = {
  ssoLink: null,
  points: null,
};

export const advantageReducer = createReducer<Model.AdvantageState>(
  initialState,

  on(loadSSOLinkCompleted, (state: Model.AdvantageState, { link }) => ({
    ...state,
    ssoLink: link,
  })),

  on(loadSSOLinkFailure, (state: Model.AdvantageState, { fallbackLink }) => ({
    ...state,
    ssoLink: fallbackLink,
  })),

  on(loadPointsCompleted, (state: Model.AdvantageState, { response }) => ({
    ...state,
    points: {
      value: response?.value || 0,
      showPoints: !!response,
    }
  })),

  on(loadPointsFailure, (state: Model.AdvantageState) => ({
    ...state,
    points: {
      value: 0,
      showPoints: false,
    }
  })),
);