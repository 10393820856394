import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Model as AdvicesModel } from '../../advices/index';
import { Model } from '../interfaces';
import * as actions from '../state/actions';
import * as selectors from '../state/selectors';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit, OnDestroy {
  constructor(private readonly store: Store) {}
  private readonly destroy$ = new Subject();
  public properties: AdvicesModel.AvailableProperty[];
  public reportDownloadError$: Observable<string>;
  public reportQuery: Model.FinancialReportQuery;
  public selectedProperties: number[];
  public selectedPropertiesList: string;
  public submitReady: boolean;

  ngOnInit(): void {
    this.store.dispatch(actions.getFinancialReportOptions());

    this.store
      .select(selectors.selectAvailableProperties)
      .pipe(takeUntil(this.destroy$))
      .subscribe((properties) => this.properties = properties);

    this.store
      .select(selectors.selectReportQuery)
      .pipe(takeUntil(this.destroy$))
      .subscribe((query) => (this.reportQuery = query));

    this.store
      .select(selectors.selectSubmitValid)
      .pipe(takeUntil(this.destroy$))
      .subscribe((ready) => (this.submitReady = ready));

    this.store
      .select(selectors.selectSelectedPropertiesList)
      .pipe(takeUntil(this.destroy$))
      .subscribe((list) => (this.selectedPropertiesList = list));

    this.store
      .select(selectors.selectSelectedProperties)
      .pipe(takeUntil(this.destroy$))
      .subscribe((properties) => (this.selectedProperties = properties));

    this.reportDownloadError$ = this.store.select(
      selectors.selectReportDownloadError
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public download(): void {
    if (this.submitReady) {
      this.store.dispatch(actions.downloadFinancialReport(this.reportQuery));
    }
  }

  public clearFilter(): void {
    this.store.dispatch(actions.clearFilter());
  }

  public updateFilter(update: Partial<Model.FinancialReportQuery>): void {
    this.store.dispatch(actions.filterUpdated({ update }));
  }

  public toggleProperty(propertyId: number) {
    this.store.dispatch(actions.toggleFilterProperty({ propertyId }));
  }

  public toggleAllProperties(): void {
    this.store.dispatch(actions.toggleFilterAllProperties());
  }
}
