import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectShouldDisplayUserMenu } from './user';
import { toggleMenu } from './user/state/actions';
import { selectOutstandingBobbyDeclarations, selectShouldHideMenu } from './user/state/selector';

@Component({
  selector: 'app-content',
  templateUrl: './app-content.component.html',
  styleUrls: ['./app-content.component.scss'],
})
export class AppContentComponent implements OnInit {
  public shouldDisplayUserMenu: Observable<boolean>;
  public shouldHideMenu: Observable<boolean>;
  public outstandingBobbyDeclarations: Observable<number>;

  constructor(
    private readonly store: Store,
  ) {}

  public ngOnInit(): void {
    this.shouldDisplayUserMenu = this.store.select(selectShouldDisplayUserMenu);
    this.shouldDisplayUserMenu = this.store.select(selectShouldDisplayUserMenu);
    this.shouldHideMenu = this.store.select(selectShouldHideMenu);
    this.outstandingBobbyDeclarations = this.store.select(selectOutstandingBobbyDeclarations);
  }

  public onMenuClicked(): void {
    this.store.dispatch(toggleMenu());
  }

  onDeactivate() {
    document.body.scrollTop = 0;
  }
}
