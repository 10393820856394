import moment from 'moment';

export class BlobSaver {
    public static saveFile(blob: Blob, filename: string ): void {
        const objectUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = objectUrl;
        link.download = filename;
        link.click();
        link.remove();
        URL.revokeObjectURL(objectUrl);
    }
}