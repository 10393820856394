<mat-card>
  <mat-card-title>
    <span role="heading">Pick-up for {{ date }}</span>
    <span class="pickup-date" role="heading"></span>
  </mat-card-title>

  <mat-card-content>
    <div>
      <label>
        <strong>Calf Tally</strong>
      </label>
      <div>
        <input
          matInput
          type="number"
          min="0"
          max="99"
          [value]="tally"
          data-cy="numOfCalves"
          (keyup)="enforceMinMax($event.srcElement)"
          (input)="updateTally($event.srcElement.value)" />
      </div>
    </div>

    <div *ngIf="pickup.booking && pickup.booking.requestedBy">
      <label>
        <strong>Requested By</strong>
      </label>
      <div>
        {{ pickup.booking.requestedBy }}
      </div>
    </div>

    <div>
      <label>
        <strong>Contact Number</strong>
      </label>
      <div>
        <input
          matInput
          [value]="contactNumber"
          data-cy="pickupContactNumber"
          (input)="updateContactNumber($event.srcElement.value)"
        />
      </div>
    </div>

    <span *ngIf="!validBooking" class="validation-failure">
      Please supply a contact number for this pick-up request
    </span>

    <div>
      <label class="note-label">
        <strong>Note to Rep</strong>
      </label>
      <div class="textarea-content">
        <textarea
          matInput
          [value]="note"
          data-cy="note"
          maxlength="200"
          (input)="updateNote($event.srcElement.value)">
        </textarea>
        <div class="max-char">Max characters: 200</div>
      </div>
    </div>

    <div class="right-aligned" *ngIf="!initialIsSeasonCompleted">
      <mat-label (click)="onSeasonCompleted()">
        Final pick-up for this property
      </mat-label>
      <mat-slide-toggle
        [checked]="isSeasonCompleted"
        (toggleChange)="onSeasonCompleted()">
      </mat-slide-toggle>
    </div>

    <div class="mobile-alignment">
      <p class="privacy-wording">
        Contact numbers will be passed on to your Bobby Calf transporter - please refer
        to our <a href="https://anzcofoods.com/privacy-policy/" target="_blank">Privacy Policy</a>
      </p>
      <div class="button-panel">
        <button
          mat-raised-button
          class="nav-button"
          data-cy="noCalvesButton"
          (click)="noCalves()">
          No Calves
        </button>
        <button
          mat-raised-button
          class="nav-button green"
          data-cy="submitRequestButton"
          (click)="submit()">
          Submit
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
