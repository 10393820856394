export enum StockType {
    Lamb = 1,
    Sheep = 4,
    BobbyCalf = 5,
    Steer = 10,
    Heifer = 11,
    Cow = 12,
    Bull = 13,
};

export class StockTypeEnum {
    public static Lamb = new StockTypeEnum(StockType.Lamb, "Lamb", 'lamb.svg', 1);
    public static Sheep = new StockTypeEnum(StockType.Sheep, "Sheep", 'sheep.svg', 2);
    public static BobbyCalf = new StockTypeEnum(StockType.BobbyCalf, "Bobby Calf", 'bobby-calf.svg', 7);
    public static Steer = new StockTypeEnum(StockType.Steer, "Steer", 'steer.svg', 3);
    public static Heifer = new StockTypeEnum(StockType.Heifer, "Heifer", 'heifer.svg', 4);
    public static Cow = new StockTypeEnum(StockType.Cow, "Cow", 'cow.svg', 5);
    public static Bull = new StockTypeEnum(StockType.Bull, "Bull", 'bull.svg', 6);

    private static allStockTypes: { [stockType: number]: StockTypeEnum; };
    private static assetFolder: string = "../../assets/images/Icons/";

    constructor(
        public readonly stockType: StockType,
        public readonly description: string,
        public readonly defaultImage: string,
        public readonly orderNumber: number,
    ) {
        if (!StockTypeEnum.allStockTypes) {
            StockTypeEnum.allStockTypes = {};
        }
        StockTypeEnum.allStockTypes[stockType] = this;
    }

    public static sourceImage(stockType: StockType): string {
        return StockTypeEnum.assetFolder + this.allStockTypes[stockType].defaultImage;
    }
 
    public static parse(stockType: StockType): string {
        return stockType && StockTypeEnum.allStockTypes[stockType] ?
            StockTypeEnum.allStockTypes[stockType].description :
            "";
    }

    public static fromEnum(stockType: StockType): StockTypeEnum {
        return StockTypeEnum.allStockTypes[stockType]
    }
};