import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "app-confimation-dialog",
    templateUrl: "./confimation-dialog.component.html",
    styleUrls: ["./confimation-dialog.component.scss"],
})
export class ConfirmationDialogComponent {
    public title: string;
    public text: string;

    constructor(
        private readonly dialogRef: MatDialogRef<ConfirmationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data,
    ) {
        this.title = data.title;
        this.text = data.text;
    }

    public ok(): void {
        this.dialogRef.close(true);
    }

    public cancel(): void {
        this.dialogRef.close(false);
    }
}