import { createReducer, on } from '@ngrx/store';
import {
  decreaseTally,
  increaseTally,
  pickupDeclarationErrored,
  selectBooking,
  selectReadOnlyBooking,
  selectSelectableBooking,
  toggleDeclarationWelfare,
  updateDeclarationfDetail,
} from './actions';
import { Model } from '../models';
import { Model as UserModel } from '../../../user/interfaces';
import {
  createOrUpdatePickupDeclaration,
  loadOpenDeclarationsCompleted,
  showOpenDeclarations
} from 'src/app/user/state/actions';
import { fromTimeSpanString } from 'src/app/miscellaneous/time-span-formatter';

export const pickupDeclarationsReducerKey = 'pickupDeclarations';

export const initialDeclarationState: Model.DeclarationState = {
  expected: [],
  completed: [],
  selectedProperty: null,
  showPropertySelection: false,
  isReadOnly: false,
  declaredWelfare: false,
  errored: false,
};

export const pickupDeclarationReducer = createReducer<Model.DeclarationState>(
  initialDeclarationState,

  on(selectBooking, (state: Model.DeclarationState, res): Model.DeclarationState => {
    if (!res.propertyId) {
      return {
        ...state,
        showPropertySelection: false,
        selectedProperty: null,
        declaredWelfare: false,
      }
    }

    const expected = state.expected.find(expect =>
      expect.anzcoPropertyId === res.propertyId &&
      expect.bookingId === res.bookingId);
    const completed = state.completed.find(complete =>
      complete.anzcoPropertyId === res.propertyId &&
      complete.bookingId === res.bookingId);

    return {
      ...state,
      showPropertySelection: false,
      declaredWelfare: false,
      isReadOnly: false,
      selectedProperty: {
        id: expected ? null : completed.id,
        bookingId: expected?.bookingId ?? completed.bookingId,
        pickupDate: expected?.pickupDate ?? completed.pickupDate,
        anzcoPropertyId: res.propertyId,
        propertyName: expected?.propertyName ?? completed.propertyName,
        tally: expected ? expected.confirmedTally : completed.tally,
        recordedBy: expected ? null : completed.recordedBy,
        timeOfLastFeedHours: expected ?
          -1 :
          completed.tally === 0 ? -1 : completed.timeOfLastFeedHours,
        timeOfLastFeedMinutes: expected ?
          -1 :
          completed.tally === 0 ? -1 : completed.timeOfLastFeedMinutes,
        contactNumber: expected ? null : completed.contactNumber,
      },
    };
  }),

  on(selectSelectableBooking, (state: Model.DeclarationState, res): Model.DeclarationState => {
    if (!res.propertyId) {
      return {
        ...state,
        showPropertySelection: true,
        selectedProperty: null,
        declaredWelfare: false,
      }
    }

    const expected = state.expected.find(expect =>
      expect.anzcoPropertyId === res.propertyId &&
      expect.bookingId === res.bookingId);
    const completed = state.completed.find(complete =>
      complete.anzcoPropertyId === res.propertyId &&
      complete.bookingId === res.bookingId);

    return {
      ...state,
      showPropertySelection: true,
      declaredWelfare: false,
      isReadOnly: false,
      selectedProperty: {
        id: expected ? null : completed.id,
        bookingId: expected?.bookingId ?? completed.bookingId,
        pickupDate: expected?.pickupDate ?? completed.pickupDate,
        anzcoPropertyId: res.propertyId,
        propertyName: expected?.propertyName ?? completed.propertyName,
        tally: expected ? expected.confirmedTally : completed.tally,
        recordedBy: expected ? null : completed.recordedBy,
        timeOfLastFeedHours: expected ? -1 : completed.timeOfLastFeedHours,
        timeOfLastFeedMinutes: expected ? -1 : completed.timeOfLastFeedMinutes,
        contactNumber: expected ? null : completed.contactNumber,
      },
    };
  }),

  on(selectReadOnlyBooking, (state: Model.DeclarationState, res): Model.DeclarationState => {
    const completed = state.completed.find(complete =>
      complete.anzcoPropertyId === res.propertyId &&
      complete.bookingId === res.bookingId);

    return {
      ...state,
      showPropertySelection: false,
      declaredWelfare: false,
      isReadOnly: true,
      selectedProperty: {
        id: completed.id,
        bookingId: completed.bookingId,
        pickupDate: completed.pickupDate,
        anzcoPropertyId: res.propertyId,
        propertyName: completed.propertyName,
        tally: completed.tally,
        recordedBy: completed.recordedBy,
        timeOfLastFeedHours: completed.tally === 0 ? -1 : completed.timeOfLastFeedHours,
        timeOfLastFeedMinutes: completed.tally === 0 ? -1 : completed.timeOfLastFeedMinutes,
        contactNumber: completed.contactNumber,
      },
    };
  }),

  on(showOpenDeclarations, (state: Model.DeclarationState, res): Model.DeclarationState => {
    if (!res.show && state.selectedProperty === null)
    {
      const isReadOnly: boolean = state.expected.length === 0 &&
        state.completed.length === 1 &&
        state.completed[0].hasBeenPickedUp;

      return {
        ...state,
        isReadOnly,
        selectedProperty: defaultSelectedBooking(state.expected, state.completed),
      };
    }
    if (res.show)
    {
      return {
        ...state,
        selectedProperty: null,
      };
    }

    return state;
  }),

  on(toggleDeclarationWelfare, (state: Model.DeclarationState, res): Model.DeclarationState => {
    return {
      ...state,
      declaredWelfare: !state.declaredWelfare,
    };
  }),

  on(updateDeclarationfDetail, (state: Model.DeclarationState, res): Model.DeclarationState => {
    return {
      ...state,
      selectedProperty: {
        ...state.selectedProperty,
        ...res.update,
      }
    };
  }),

  on(decreaseTally, (state: Model.DeclarationState, res): Model.DeclarationState => {
    return {
      ...state,
      selectedProperty: {
        ...state.selectedProperty,
        tally: state.selectedProperty?.tally ?
          state.selectedProperty?.tally - 1 :
          0,
      }
    };
  }),

  on(increaseTally, (state: Model.DeclarationState, res): Model.DeclarationState => {
    return {
      ...state,
      selectedProperty: {
        ...state.selectedProperty,
        tally: state.selectedProperty?.tally && state.selectedProperty?.tally < 99 ?
          state.selectedProperty?.tally + 1 :
          1,
      }
    };
  }),

  on(loadOpenDeclarationsCompleted,
    (
      state: Model.DeclarationState,
      res: { declarations: UserModel.ProducerServiceResponse<UserModel.OpenDeclarationsResponse> },
    ): Model.DeclarationState => {
      const completed = res.declarations.errored ?
        [] :
        res.declarations.response.completed.map(completed => {
          const timeOfLastFeed = fromTimeSpanString(completed.timeOfLastFeed);
          const completedResponse: Model.OpenDeclarationsCompletedResponse = {
            id: completed.id,
            bookingId: completed.bookingId,
            pickupDate: completed.pickupDate,
            anzcoPropertyId: completed.anzcoPropertyId,
            propertyName: completed.propertyName,
            tally: completed.tally,
            recordedBy: completed.recordedBy,
            timeOfLastFeedHours: completed.tally === 0 ? -1 : timeOfLastFeed.hours,
            timeOfLastFeedMinutes: completed.tally === 0 ? -1 : timeOfLastFeed.minutes,
            contactNumber: completed.contactNumber,
            hasBeenPickedUp: completed.hasBeenPickedUp,
          };

          return completedResponse;
        });
      const newState: Model.DeclarationState = {
        expected: res.declarations.errored ?
          [] :
          res.declarations.response.expected.slice(),
        completed,
        selectedProperty: res.declarations.errored ?
          null :
          defaultSelectedBooking(
            res.declarations.response.expected,
            completed),
        errored: res.declarations.errored,
        declaredWelfare: false,
        showPropertySelection: !res.declarations.errored &&
          res.declarations.response.completed.length + res.declarations.response.expected.length > 1,
        isReadOnly: res.declarations.errored ?
          false :
          res.declarations.response.expected.length === 0 &&
            completed.filter(completedDec => !completedDec.hasBeenPickedUp).length === 0,
      };
      return newState;
    }
  ),

  on(pickupDeclarationErrored, (state: Model.DeclarationState, res): Model.DeclarationState => {
    return {
      completed: [],
      expected: [],
      showPropertySelection: false,
      isReadOnly: false,
      selectedProperty: null,
      declaredWelfare: false,
      errored: res.errored,
    };
  }),

  on(createOrUpdatePickupDeclaration, (state: Model.DeclarationState, res): Model.DeclarationState => {
    return {
        ...state,
        declaredWelfare: false,
        selectedProperty: null,
        expected: state.expected.filter(expected => expected.bookingId !== res.bookingId),
        completed: [
          ...state.completed,
          {
            id: state.selectedProperty.id,
            anzcoPropertyId: state.selectedProperty.anzcoPropertyId,
            bookingId: state.selectedProperty.bookingId,
            contactNumber: state.selectedProperty.contactNumber,
            pickupDate: state.selectedProperty.pickupDate,
            propertyName: state.selectedProperty.propertyName,
            recordedBy: state.selectedProperty.recordedBy,
            tally: state.selectedProperty.tally,
            timeOfLastFeedHours: state.selectedProperty.tally === 0 ?
              -1 :
              state.selectedProperty.timeOfLastFeedHours,
            timeOfLastFeedMinutes: state.selectedProperty.tally === 0 ?
              -1 :
              state.selectedProperty.timeOfLastFeedMinutes,
            hasBeenPickedUp: false,
          },
        ],
      };
  }),
);

function defaultSelectedBooking(
  expectedRes: Model.OpenDeclarationsExpectedResponse[],
  completedRes: Model.OpenDeclarationsCompletedResponse[]
) : Model.SelectedBookingDeclaration | null {
  if (expectedRes.filter(expected => !expected.hasBeenPickedUp).length +
    completedRes.filter(expected => !expected.hasBeenPickedUp).length === 1)
  {
    const expected = expectedRes.length ? expectedRes[0] : null;
    const completed = completedRes.length ? completedRes[0] : null;

    return {
      id: expected ? null : completed.id,
      bookingId: expected?.bookingId ?? completed.bookingId,
      pickupDate: expected?.pickupDate ?? completed.pickupDate,
      anzcoPropertyId: expected?.anzcoPropertyId ?? completed.anzcoPropertyId,
      propertyName: expected?.propertyName ?? completed.propertyName,
      tally: expected ? expected.confirmedTally : completed.tally,
      recordedBy: expected ? null : completed.recordedBy,
      timeOfLastFeedHours: expected ? -1 : completed.timeOfLastFeedHours,
      timeOfLastFeedMinutes: expected ? -1 : completed.timeOfLastFeedMinutes,
      contactNumber: expected ? null : completed.contactNumber,
    };
  }
  return null;
}