import { Router } from '@angular/router';
import { Component, EventEmitter, Output } from '@angular/core';
import {
  adminRoute,
  changeRequestsRoute,
  newRequestsRoute,
  portalUsersRoute,
  Selector,
} from '../../../admin-features';
import { selectShouldDisplayAdminMenu } from '../../../user';
import { Store } from '@ngrx/store';
import { selectRegistrationRequired } from 'src/app/user/state/selector';

@Component({
  selector: 'app-header-menu-items',
  templateUrl: './header-menu-items.component.html',
  styleUrls: ['../header.component.scss', 'header-menu-items.component.scss'],
})
export class HeaderMenuItemsComponent {
  @Output() public menuClicked = new EventEmitter<MouseEvent>();

  public shoudlHideMenu = this.store.select(
    selectRegistrationRequired
  );

  public readonly shouldDisplayAdminMenu$ = this.store.select(
    selectShouldDisplayAdminMenu
  );

  public readonly showNewRequestsNotification$ = this.store.select(
    this.adminSelector.selectShowAmountNewUserAdminTasks
  );

  public readonly showChangeRequestsNotification$ = this.store.select(
    this.adminSelector.selectShowAmountChangeUserAdminTasks
  );

  public readonly amountNewRequests$ = this.store.select(
    this.adminSelector.selectAmountNewUserAdminTasks
  );

  public readonly amountChangeRequests$ = this.store.select(
    this.adminSelector.selectAmountChangeUserAdminTasks
  );

  constructor(
    public readonly store: Store,
    private readonly adminSelector: Selector,
    private readonly router: Router
  ) {}

  public navigateToNewRequests(): void {
    this.router.navigate([adminRoute, newRequestsRoute]);
  }

  public navigateToChangeRequests(): void {
    this.router.navigate([adminRoute, changeRequestsRoute]);
  }

  public navigateToPortalUsers(): void {
    this.router.navigate([adminRoute, portalUsersRoute]);
  }
}
