import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Model } from './interfaces';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private getUserUrl: string = environment.apiUrl + '/api/v2/user';
  private registerUrl: string = environment.apiUrl + '/api/user/register';
  private bobbyCalvesTallyUrl: string = environment.apiUrl + '/api/bobby/total-tallies-outage';
  private bobbyCalvesOpenDeclarationsUrl: string = environment.apiUrl + '/api/bobby/open-declarations';
  private bobbyCalvesCreateDeclarationsUrl: string = environment.apiUrl + '/api/bobby/pickup-declaration-outage';
  
  constructor(
    private readonly http: HttpClient,
  ) { }

  public getUser(): Observable<Model.User> {
    const queryParams = {
      version: 'latest',
      environment: 3, // WEB
    };
    const params: HttpParams = new HttpParams({ fromObject: { ...queryParams } as any });
    
    return this.http.get<Model.User>(this.getUserUrl, { params });
  }

  public register(userDetails : Model.UserDetails): Observable<void> {
      return this.http.post<void>(this.registerUrl, userDetails);
  }

  public bobbyCalvesTally(): Observable<Model.ProducerServiceResponse<Model.TotalTallyResponse>> {
    return this.http.get<Model.ProducerServiceResponse<Model.TotalTallyResponse>>(this.bobbyCalvesTallyUrl);
  }
  
  public openDeclarations(): Observable<Model.ProducerServiceResponse<Model.OpenDeclarationsResponse>> {
    return this.http.get<Model.ProducerServiceResponse<Model.OpenDeclarationsResponse>>(this.bobbyCalvesOpenDeclarationsUrl);
  }

  public createOrUpdatePickupDeclaration(command: Model.CreateOrUpdatePickupDeclarationCommand): Observable<Model.ProducerServiceResponse<Model.CreateOrUpdatePickupDeclarationResponse>> {
    return this.http.post<Model.ProducerServiceResponse<Model.CreateOrUpdatePickupDeclarationResponse>>(this.bobbyCalvesCreateDeclarationsUrl, command);
  }
}