import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { switchMap, tap } from 'rxjs/operators';
import { Models } from '../models';

@Injectable({ providedIn: 'root' })
export class CreateUserCommand {
  private readonly _loadingSubject$ = new BehaviorSubject(false);

  constructor(private http: HttpClient) {}

  public get loading$(): Observable<boolean> {
    return this._loadingSubject$;
  }

  execute(req: Models.CreateUserRequest): Observable<unknown> {
    req.userDetails = {
      ...req.userDetails,
      advantageEmail: req.userDetails.advantageEmail || null,
    };

    return of(environment.apiUrl + '/api/admin/create-user').pipe(
      tap(() => this._loadingSubject$.next(true)),
      switchMap((url) => this.http.post<Response>(url, req)),
      tap(() => this._loadingSubject$.next(false))
    );
  }
}
