import moment from 'moment';

export class HttpDateFormatter {
    public static format(
        date: Date | null,
        includeTime: boolean,
    ): string {
        return date ?
            moment(date).format(includeTime ? "YYYY-MM-DDTHH:mm:ss" : "YYYY-MM-DDT00:00:00") :
            null;
    }
}