<div class="main-element">
    <app-edit-user [title]="'Change Request'"></app-edit-user>

    <div class="button-panel">
        <button class="mat-raised-button" (click)="cancel()">Cancel</button>
        <div>
            <button *ngIf="!editUserConfirmed"
                    class="mat-raised-button decline-button"
                    (click)="decline()">Decline</button>
            <button *ngIf="adminTaskDisabled && !editUserConfirmed"
                    class="mat-raised-button mat-primary"
                    (click)="assign()">Update</button>
            <button *ngIf="!adminTaskDisabled"
                    [disabled]="!validToConfirm"
                    class="mat-raised-button mat-primary"
                    (click)="confirm()">Confirm</button>
            <button *ngIf="editUserConfirmed"
                    class="mat-raised-button mat-primary"
                    (click)="approve()">Approve</button>
        </div>
    </div>
</div>