import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "app-season-completed-dialog",
    templateUrl: "./season-completed-dialog.component.html",
    styleUrls: ["./season-completed-dialog.component.scss"],
})
export class SeasonCompletedDialogComponent {
    public propertyName: string;
    public text: string;

    constructor(
        private readonly dialogRef: MatDialogRef<SeasonCompletedDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data,
    ) {
        dialogRef.disableClose = true;
        this.propertyName = data.propertyName;
    }

    public ok(): void {
        this.dialogRef.close(true);
    }
}