<mat-card>
  <mat-card-title>
    <span role="heading"
      >Requests open {{ bookingOpenDate }}</span
    >
  </mat-card-title>
  <mat-card-content>
      <div>
        <label>
          <strong>Next Pick-up</strong>
        </label>
        <div>
          {{ date }}
        </div>
    </div>
  </mat-card-content>
</mat-card>
