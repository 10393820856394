import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as adviceActions from './actions';
import { AdviceService } from '../advice.service';
import { selectAdvice } from './actions';
import { Router } from '@angular/router';
import { singleAdviceRoute } from '../constants';
import { createAction } from '@ngrx/store';
import { Model } from '../interfaces';

@Injectable()
export class AdvicesEffects {
  private noAction = createAction("No Action");

  constructor(
    private readonly actions$: Actions,
    private readonly adviceService: AdviceService,
    private readonly router: Router,
  ) { }

  public loadRecentAdvices$ = createEffect(() => this.actions$
    .pipe(
      ofType(adviceActions.loadRecentAdvices),
      switchMap(() => this.adviceService.getRecentAdvices()
        .pipe(
          map((response) => {
            const mappedResponse: Model.PotentialOutageResponse<Model.PagedAdviceResponse> = {
              errored: response.errored,
              response: response.response ?
                {
                  totalResults: response.response.totalResults,
                  advices: response.response.advices.map(advice => {
                    const mappedAdvice: Model.Advice = {
                      season: advice.season,
                      advice: advice.advice,
                      propertyId: advice.propertyId,
                      propertyName: advice.propertyName,
                      adviceStatus: advice.adviceStatus,
                      carcaseDataAvailable: advice.carcaseDataAvailable,
                      invoiceAvailable: advice.invoiceAvailable,
                      invoiceFile: advice.invoiceFile,
                      isGrazing: advice.isGrazing,
                      bookingType: advice.bookingType,
                      adviceLines: advice.adviceLines.map(adviceLine => {
                        const mappedAdivceLine: Model.AdviceLine = {
                          averageWeight: adviceLine.averageWeight,
                          carcaseFile: adviceLine.carcaseFile,
                          killDate: new Date(adviceLine.killDate),
                          killDollarPerKg: adviceLine.killDollarPerKg,
                          killedPaidWeight: adviceLine.killedPaidWeight,
                          paidPerHead: adviceLine.paidPerHead,
                          paidPricePerKg: adviceLine.paidPricePerKg,
                          stockType: adviceLine.stockType,
                          tally: adviceLine.tally,
                          condemnedTally: adviceLine.condemnedTally,
                          totalNettAmount: adviceLine.totalNettAmount,
                          feedbackComments: adviceLine.feedbackComments,
                        };

                        return mappedAdivceLine;
                      }),
                      booking: advice.booking,
                      declaration: advice.declaration,
                      pickup: advice.pickup,

                      bookingDisplayMessage: advice.bookingDisplayMessage,
                      declarationDisplayMessage:
                        advice.declarationDisplayMessage,
                      pickupDisplayMessage: advice.pickupDisplayMessage,
                    };

                    return mappedAdvice;
                  })
                } :
                null,
            };

            return adviceActions.loadRecentAdvicesCompleted({response: mappedResponse});
          }),
        )
      )
    )
  );

  public loadRecentAdvicesError$ = createEffect(() => this.actions$
    .pipe(
      ofType(adviceActions.loadRecentAdvicesCompleted),
      map((res) => adviceActions.adviceErrored({ errored: res.response.errored })),
    ),
  );

  public searchAdvices$ = createEffect(() => this.actions$
    .pipe(
      ofType(adviceActions.searchAdvices),
      switchMap((query) => this.adviceService.searchAdvices(query)
        .pipe(
          map((response) => {
            const mappedResponse: Model.PotentialOutageResponse<Model.PagedAdviceResponse> = {
              errored: response.errored,
              response: response.response ?
                {
                  totalResults: response.response.totalResults,
                  advices: response.response.advices.map(advice => {
                    const mappedAdvice: Model.Advice = {
                      season: advice.season,
                      advice: advice.advice,
                      propertyId: advice.propertyId,
                      propertyName: advice.propertyName,
                      adviceStatus: advice.adviceStatus,
                      carcaseDataAvailable: advice.carcaseDataAvailable,
                      invoiceAvailable: advice.invoiceAvailable,
                      invoiceFile: advice.invoiceFile,
                      isGrazing: advice.isGrazing,
                      bookingType: advice.bookingType,
                      adviceLines: advice.adviceLines.map(adviceLine => {
                        const mappedAdivceLine: Model.AdviceLine = {
                          averageWeight: adviceLine.averageWeight,
                          carcaseFile: adviceLine.carcaseFile,
                          killDate: new Date(adviceLine.killDate),
                          killDollarPerKg: adviceLine.killDollarPerKg,
                          killedPaidWeight: adviceLine.killedPaidWeight,
                          paidPerHead: adviceLine.paidPerHead,
                          paidPricePerKg: adviceLine.paidPricePerKg,
                          stockType: adviceLine.stockType,
                          tally: adviceLine.tally,
                          condemnedTally: adviceLine.condemnedTally,
                          totalNettAmount: adviceLine.totalNettAmount,
                          feedbackComments: adviceLine.feedbackComments,
                        };

                        return mappedAdivceLine;
                      }),
                      booking: advice.booking,
                      declaration: advice.declaration,
                      pickup: advice.pickup,
                      
                      bookingDisplayMessage: advice.bookingDisplayMessage,
                      declarationDisplayMessage:
                        advice.declarationDisplayMessage,
                      pickupDisplayMessage: advice.pickupDisplayMessage,
                    };

                    return mappedAdvice;
                  })
                } :
                null,
            };

            return adviceActions.searchAdvicesCompleted({ response: mappedResponse });
          }),
        )
      )
    )
  );

  public searchAdvicesError$ = createEffect(() => this.actions$
    .pipe(
      ofType(adviceActions.searchAdvicesCompleted),
      map((res) => adviceActions.adviceErrored({ errored: res.response.errored })),
    ),
  );

  public getFilterOptions$ = createEffect(() => this.actions$
    .pipe(
      ofType(adviceActions.getAdviceFilterOptions),
      switchMap((query) => this.adviceService.getAvailableFilterOptions()
        .pipe(
          map((response) => adviceActions.getAdviceFilterOptionsCompleted({ response }))
        )
      )
    )
  );

  public selectAdviceRoutingEffect = createEffect(() => this.actions$
    .pipe(
      ofType(selectAdvice),
      map((action) => {
        this.router.navigate(
          [singleAdviceRoute],
          {
            queryParams: {
              advice: action.advice,
              season: action.season,
              propertyId: action.propertyId,
              route: action.previousRoute,
            },
          });
        return this.noAction();
      }),
    )
  );
}