import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ActivationEnd,
  NavigationEnd,
  NavigationError,
  Router,
} from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  filter,
  take,
  takeUntil,
  switchMap,
  map,
} from 'rxjs/operators';
import { RaygunWrapper } from './app.raygun.setup';
import { Selector } from './features/spinner/state/selector';
import { loadUser } from './user';
import { IconRegistryService } from './services/icon-registry.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public activeSpinner: Observable<boolean>;

  private readonly _destroying$ = new Subject<void>();
  private userLoaded: boolean;
  constructor(
    private readonly broadcastService: MsalBroadcastService,
    private readonly iconRegistry: IconRegistryService,
    private readonly router: Router,
    private readonly selector: Selector,
    private readonly store: Store
  ) {}

  public async ngOnInit(): Promise<void> {
    this.iconRegistry.init();

    this.router.events.pipe(takeUntil(this._destroying$)).subscribe((event) => {
      // Track page views when the NavigationEnd event occurs
      if (event instanceof NavigationEnd) {
        RaygunWrapper.handleEvent({
          type: 'pageView',
          path: event.url,
        });
      }

      // Track navigation errors when the NavigationError event occurs
      if (event instanceof NavigationError) {
        // Track navigation error
        RaygunWrapper.handleError(event.error);
      }
    });

    // attempt to load user after login on authenticated routes
    // Note need to use router events to access route data outside of the <router-outlet>
    this.router.events
      .pipe(
        filter((e) => e instanceof ActivationEnd),
        map((e: ActivationEnd) => e.snapshot.data),
        filter((data) => !data.anonymousRoute),
        switchMap(() => this.broadcastService.inProgress$),
        filter((status) => status === InteractionStatus.None),
        take(1),
        takeUntil(this._destroying$)
      )
      .subscribe(() => this.store.dispatch(loadUser()));

    this.activeSpinner = this.store
      .select(this.selector.selectActiveSpinner)
      .pipe(distinctUntilChanged(), delay(0));

    this.router.events
      .pipe(
        filter((evt) => evt instanceof NavigationEnd),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        const contentContainer = document.querySelector('.content') || window;
        contentContainer.scrollTo(0, 0);
      });
  }

  public ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
