<div mat-dialog-title>
    <h1>Decline Registration</h1>
    <h1>Email: {{email}}</h1>
</div>

<mat-dialog-content>
    <span>Hi {{firstName}},</span>
    <br>
    <span>Thank you for your application to use the ANZCO Producer Portal. Unfortunately, we are unable to grant you permission to access the portal as:</span>
    <br>
    <div *ngFor="let reason of allReasons; index as i"
         class="reason-block">
         <input type="radio"
                name="reason"
                [checked]="reason.declineReason === declineReason"
                (change)="chooseReason(reason.declineReason)" />
        <span>{{reason.description}}</span>
    </div>
    <div class="reason-block">
        <input type="radio"
               name="reason"
               (change)="chooseOtherReason()"/>
        <span>Other:</span>
    </div>
    <textarea [disabled]="declineReason !== 100"
              (input)="change($event.srcElement.value)"></textarea>
    <br>
    <span>Should you wish to discuss this further, please talk to the property owner.</span>
    <br>
    <span>Thanks for your interest in our site.</span>
    <br>
    <span>Regards,</span>
    <br>
    <span>The Livestock Admin Team</span>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="cancel()">Cancel</button>
    <button class="mat-raised-button decline-button" (click)="decline()">Decline</button>
</mat-dialog-actions>