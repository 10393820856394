import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { RoleEnum } from "src/app/miscellaneous/enums/role.enum";
import { Model, registerAccount } from 'src/app/user';

@Component({
  selector: 'app-complete-registration',
  templateUrl: './complete-registration.component.html',
  styleUrls: ['../registration.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompleteRegistrationComponent implements OnInit {
  public readonly allRoles = RoleEnum.all();
  public completeRegistrationForm: FormGroup;
  public submitClicked: boolean = false;
  public serverError$: Observable<string>;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly store: Store,
  ) { }

  public ngOnInit(): void {
    this.completeRegistrationForm = this.formBuilder.group({
      firstName: ['', { validators: [Validators.required]}],
      surname: ['', {validators: [Validators.required]}],
      producer: ['', {validators: [Validators.required]}],
      producerCode: ['', {validators: [Validators.required]}],
      role: [null, {validators: [Validators.required]}],
      gstNumber: ['', {validators: [
        Validators.required,
        Validators.pattern("[0-9]{9}"),
      ]}],
      acceptTermsConditions: [false, { validators: [Validators.requiredTrue]}],
      receivePromotionalMaterial: [false, { validators: [] }]
    });
    
  }

  public submit(): void {
    this.submitClicked = true;
    
    if (this.completeRegistrationForm.valid) {
      const userDetails: Model.UserDetails = {
        ...this.completeRegistrationForm.value,
        gstNumber : this.completeRegistrationForm.controls.gstNumber.value.toString()
      };
  
      // success will lead to component being hidden (via shell) 
      this.store.dispatch(registerAccount({ userDetails }));
    }
  }
}