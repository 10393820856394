import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectErrorLoading } from '../state/selectors';

@Component({
  selector: 'app-news-error',
  templateUrl: './news-error.component.html',
  styleUrls: ['./news-error.component.scss']
})
export class NewsErrorComponent implements OnInit {
  public errorLoading$ : Observable<boolean>;

  constructor(
    private readonly store: Store,
  ) { }

  public ngOnInit(): void {
    this.errorLoading$ = this.store.select(selectErrorLoading);
  }
}