<div class="default-container">
    <mat-card class="default-card">
        <div>
            <mat-card-title><h2>Contact us</h2></mat-card-title>
            <mat-card-content>
                <p>Email: <a href="mailto:support@anzcoproducers.co.nz">support@anzcoproducers.co.nz</a></p>
                <p>Our support team will be in contact within 2 working days.</p>
                <br/>
                <p><strong>Need to talk to one of our Livestock Reps?</strong></p>
                <p>View the full list here: <a href="https://anzcofoods.com/contact-us/livestock-reps/">Livestock Reps</a>.</p>
                <p>Need to talk to our Livestock Admin team? Please call on us 03 357 1783.</p>
            </mat-card-content>
            <mat-card-actions>
                <button mat-raised-button color="accent"  (click)="logout()">back to login page</button>
            </mat-card-actions>
        </div>
    </mat-card>
</div>