<div *ngIf="staff"
     class="default-container">
    <div class="headline">
        <h2>{{ headline }}</h2>
        <a [routerLink]="['/role-description']">Click here for role descriptions</a>
    </div>
  
    <div class="data-item-container">
        <div class="labeled-data-item">
            <mat-label>
                Email
                <span *ngIf="!validEmail"
                      class="validation-failure">
                      * Invalid Email format
                </span>
                <span *ngIf="!uniqueEmail"
                      class="validation-failure">
                      * Staff member already registered
                </span>
            </mat-label>
            <input matInput
                   [disabled]="disbaleEditedStaffFields"
                   [value]="staff.email"
                   [class.validation-failure]="!validEmail"
                   data-cy="staffEditEmailInputField"
                   (input)="updateStaffDetail({ email: $event.srcElement.value })"/>
        </div>

        <div class="labeled-data-item">
            <mat-label>
                First Name
                <span *ngIf="!validFirstName"
                      class="validation-failure">
                      * First Name required
                </span>
            </mat-label>
            <input matInput
                   [disabled]="disbaleEditedStaffFields"
                   [value]="staff.firstName"
                   [class.validation-failure]="!validFirstName"
                   data-cy="staffEditFirstNameInputField"
                   (input)="updateStaffDetail({ firstName: $event.srcElement.value })"/>
        </div>

        <div class="labeled-data-item">
            <mat-label>
                Surname
                <span *ngIf="!validSurname"
                      class="validation-failure">
                      * Surname required
                </span>
            </mat-label>
            <input matInput
                   [disabled]="disbaleEditedStaffFields"
                   [value]="staff.surname"
                   [class.validation-failure]="!validSurname"
                   data-cy="staffEditSurnameInputField"
                   (input)="updateStaffDetail({ surname: $event.srcElement.value })"/>
        </div>
    </div>

    <table mat-table
           [dataSource]="staff.properties"
           class="mat-elevation-z8"
           data-cy="staffEditPropertyTable">
        <ng-container matColumnDef="propertyName">
            <th mat-header-cell
                *matHeaderCellDef>
                Property
            </th>
            <td mat-cell
                *matCellDef="let property">
                <div class="sub-row">
                    <p>{{ property.propertyName }}</p>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="role">
            <th mat-header-cell
                *matHeaderCellDef>
                Role
            </th>
            <td mat-cell
                *matCellDef="let property">
                <div class="sub-row">
                    <mat-select *ngIf="property.roleId === 1"
                                [(value)]="property.roleId"
                                disabled>
                        <mat-option [value]="property.roleId">
                            Owner
                        </mat-option>
                    </mat-select>
                    <mat-select *ngIf="property.roleId !== 1"
                                [(value)]="property.roleId"
                                data-cy="staffEditPropertyTableRoleSelector">
                        <mat-option *ngFor="let role of allRoles"
                                    [value]="role.role"
                                    (click)="updateStaffRole(property.propertyId, role.role)">
                            {{ role.description }}
                        </mat-option>
                        <mat-option [value]="10"
                                    (click)="updateStaffRole(property.propertyId, 10)">
                            No Role
                        </mat-option>
                    </mat-select>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="isActive" >
            <th mat-header-cell
                *matHeaderCellDef>
                Active
            </th>
            <td mat-cell
                *matCellDef="let property">
                <div class="sub-row">
                    <mat-checkbox *ngIf="property.roleId !== 10"
                                  [checked]="property.isActive"
                                  [disabled]="property.roleId === 1"
                                  (change)="updateStaffRoleActive(property.propertyId, property.roleId, $event.checked)">
                    </mat-checkbox>
                    <i *ngIf="property.roleId === 10" class="btr bt-ban"></i>
                </div>
            </td>
        </ng-container>

        <tr *matHeaderRowDef="staffTableColumns; sticky: true"
            mat-header-row></tr>
        <tr mat-row
            *matRowDef="let staff; columns: staffTableColumns"></tr>
    </table>

    <div class="button-panel">
        <button mat-raised-button
                class="nav-button"
                data-cy="staffEditCancelButton"
                (click)="cancel()">
            Cancel
        </button>

        <div>
            <button *ngIf="staff.userId && !staff.isOwner"
                    mat-raised-button
                    color="primary"
                    data-cy="staffEditRemoveButton"
                    (click)="removeStaff()">
                Remove
            </button>

            <button mat-raised-button
                    color="primary"
                    [disabled]="!submitValid"
                    data-cy="staffEditSubmitButton"
                    (click)="submit()">
                Submit
            </button>
        </div>
    </div>
</div>