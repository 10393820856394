import { Injectable } from '@angular/core';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { environment } from 'src/environments/environment';
import * as newsActions from './actions';
import { Model } from '../interfaces';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { selectNewsOverview } from './selectors';

@Injectable()
export class NewsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly store: Store
  ) { }

  public loadNewsOverview$ = createEffect(() => this.actions$
    .pipe(
      ofType(newsActions.loadNewsOverview, newsActions.loadNewsOverviewWithoutGlobalSpinner),
      withLatestFrom(this.store.select(selectNewsOverview)),
      mergeMap(([_, existingOverview]) => {
        if (existingOverview == null) {
          return this.http
            .get<Model.NewsOverview>(`${environment.apiUrl}/api/news/overview`)
            .pipe(
              map((overview) => newsActions.loadNewsOverviewCompleted(overview)),
              catchError((error) => {
                console.log(error);
                return of(newsActions.loadNewsOverviewFailure());
              })
            );
        } else {
          return of(newsActions.loadNewsOverviewCompleted(existingOverview));
        }
      }),
    ),
  );

  public loadNewsItem$ = createEffect(() => this.actions$
    .pipe(
      ofType(newsActions.loadNewsItem),
      switchMap((action) =>
        this.http.get<Model.NewsItem>(`${environment.apiUrl}/api/news/item?id=${action.id}`)
          .pipe(
            map((newsItem) => newsActions.loadNewsItemCompleted(newsItem)),
            catchError((error) => {
              console.log(error);
              return of(newsActions.loadNewsItemFailure());
            })
          )
      )
    )
  );

  public loadNewsFeed$ = createEffect(() => this.actions$
    .pipe(
      ofType(newsActions.loadNewsFeed),
      switchMap((query: Model.NewsFeedQuery) => {
         // format for HTTP transport
         const queryParams = {
          ...query,
        };
        // create a query params object without null values. e.g. {a: null, b:"1234"} => ?b=1234 instead of ?a=null&b=1234
        Object.keys(queryParams).forEach(key => !queryParams[key] ? delete queryParams[key] : {});
        const params: HttpParams = new HttpParams({ fromObject: { ...queryParams } as any });

        return this.http.get<Model.NewsFeed>(`${environment.apiUrl}/api/news/feed`, { params })
          .pipe(
            map((feed) => newsActions.loadNewsFeedCompleted(feed)),
            catchError((error) => {
              console.log(error);
              return of(newsActions.loadNewsFeedFailure());
            })
          )
      })
    )
  );
}