import { Component, Input } from "@angular/core";
import { Projection } from "../interfaces";

@Component({
    selector: "app-compliance-cards",
    templateUrl: "compliance-cards.component.html",
    styleUrls: ["compliance-cards.component.scss"]
})
export class ComplianceCardsComponent {
    @Input() public compliances: Projection.Compliances[] = [];
}