import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsOverviewShellComponent } from './news-overview-shell/news-overview-shell.component';
import { AngularMaterialModule } from 'src/app/miscellaneous';
import { LoaderModule } from '../../miscellaneous/'
import { StoreModule } from '@ngrx/store';
import { newsFeatureKey, newsReducer } from './state/reducer';
import { NewsEffects } from './state/effects';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { NewsSummaryTileComponent } from './news-thumbnail/news-thumbnail.component';
import { NewsItemComponent } from './news-item-shell/news-item-shell.component';
import { RouterModule } from '@angular/router';
import { NewsFeedPreviewComponent } from './news-feed-preview/news-feed-preview.component';
import { TrustedHtmlPipe } from './trusted-html.pipe';
import { NewsFeedShellComponent } from './news-feed-shell/news-feed-shell.component';
import { NewsErrorComponent } from './news-error/news-error.component';
import { NewsHomepageWidgetComponent } from './news-homepage-widget/news-homepage-widget';

@NgModule({
  declarations: [
    NewsOverviewShellComponent,
    NewsSummaryTileComponent,
    NewsItemComponent,
    NewsFeedPreviewComponent,
    TrustedHtmlPipe,
    NewsFeedShellComponent,
    NewsErrorComponent,
    NewsHomepageWidgetComponent,
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    LoaderModule,
    StoreModule.forFeature(newsFeatureKey, newsReducer),
    EffectsModule.forFeature([NewsEffects]),
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [
    NewsOverviewShellComponent,
    NewsItemComponent,
    NewsFeedShellComponent,
    NewsErrorComponent,
    NewsHomepageWidgetComponent,
  ]
})
export class NewsModule { }
