<div *ngIf="active | async" class="help" data-cy="help-modal">
  <div>
    <h2>Contact Us</h2>
    <p>
      Email:
      <a href="mailto:support@anzcoproducers.co.nz"
        >support@anzcoproducers.co.nz</a
      >
    </p>
    Our support team will be in contact within 2 working days.
    <br />
    <br />
    <p>
      Need to talk to one of our Livestock Reps? View the full list here:
      <br />
      <a
        href="https://anzcofoods.com/contact-us/livestock-reps/"
        target="_blank"
      >
        Livestock Reps
      </a>
    </p>
    <br />
    <p>
      Need to talk to our Livestock Admin team?
      <br />
      Please call us on <a href="tel:+6433571783">033571783</a>.
    </p>
    <br />
    <p *ngIf="shouldDisplayBobbyMenu$ | async">
      Need to speak to someone about Bobby Calves?
      <br />
      Please call 0800 4 ANZCO <a href="tel:0800426926">(0800 4 26926)</a> for
      further assistance.
    </p>
    <button mat-raised-button (click)="closeHelp()" data-cy="closeHelpButton">
      Close
    </button>
  </div>
</div>
