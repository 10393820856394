import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class EventInputDelay {
    private timer: number = 0;

    delay(callback: (evt) => void): void {
        clearTimeout(this.timer);
        this.timer = window.setTimeout(
            callback,
            500,
        );
    }
}