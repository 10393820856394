import { createFeatureSelector, createSelector } from "@ngrx/store";
import { Compliance, Projection } from "../interfaces";
import { complianceFeatureKey } from "./reducer";
import { ComplianceStatusEnum } from "../compliance-status.enum";

const getComplianceState = createFeatureSelector<Compliance.State>(complianceFeatureKey);

export const selectCompliances = createSelector(
    getComplianceState,
    (state: Compliance.State) => {
        const compliances: Projection.Compliances[] = state.compliances.map(compliance => {
            const result: Projection.Compliances = {
                complianceProgramName: compliance.complianceProgramName,
                complianceProgramDescription: compliance.complianceProgramDescription,
                propertyName: compliance.propertyName,
                expiryDate: compliance.expiryDate,
                status: compliance.status,
                statusName: ComplianceStatusEnum.parse(compliance.status),
            }
            return result;
        });
        return compliances;
    },
);