import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject } from 'rxjs';

/**
 * NOT A SINGLETON.
 * This is used to be injected into components via their `providers` property.
 * And allows the component to use a `takeUntil` more easily.
 */
@Injectable()
export class DestroyedService extends ReplaySubject<void> implements OnDestroy {
  constructor() {
    super(1);
  }

  ngOnDestroy(): void {
    this.next();
    this.complete();
  }
}
