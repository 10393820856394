import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  hasSeasonBeenCompleted,
  selectErrored,
  selectIsInPostSeason,
  selectIsInPreSeason,
  selectIsInSeason,
  selectLoaded,
  selectOrderedPickups,
  selectProperties,
  selectShouldShowCalendar,
} from './state/selectors';
import { map, takeUntil } from 'rxjs/operators';
import { Bobby, Projection } from './models';
import {
  createOrUpdateBooking,
  loadPickups,
  loadProperties,
} from './state/actions';
import { Router } from '@angular/router';
import { bobbyCalvesCalendarRoute } from './constants';
import { loadOpenDeclarations } from 'src/app/user/state/actions';

const lastSelectedPropertyKey: string =
  'anzco-bobby-calves-last-selected-property';

@Component({
  selector: 'app-bobby-calves',
  templateUrl: './bobby-calves.component.html',
  styleUrls: ['./bobby-calves.component.scss'],
})
export class BobbyCalvesComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject();
  public pickups$: Observable<Projection.Pickup[]>;
  public isInSeason: boolean;
  public isInPreSeason: boolean;
  public isInPostSeason: boolean;
  public isSeasonCompleted: boolean;
  public shouldShowCalendar: boolean;
  public loaded$: Observable<boolean>;
  public errored$: Observable<boolean>;
  public properties: Bobby.Property[] = [];
  public selectedPropertyId: number;

  constructor(
    private readonly store: Store,
    private readonly router: Router,
  ) {}

  public ngOnInit(): void {
    this.errored$ = this.store
      .select(selectErrored)
      .pipe(takeUntil(this.destroy$));
    this.loaded$ = this.store
      .select(selectLoaded)
      .pipe(takeUntil(this.destroy$));
    this.store
      .select(selectIsInSeason)
      .pipe(takeUntil(this.destroy$))
      .subscribe((inSeason) => this.isInSeason = inSeason);
    this.store
      .select(selectIsInPreSeason)
      .pipe(takeUntil(this.destroy$))
      .subscribe((isInPreSeason) => this.isInPreSeason = isInPreSeason);
    this.store
      .select(selectIsInPostSeason)
      .pipe(takeUntil(this.destroy$))
      .subscribe((isInPostSeason) => this.isInPostSeason = isInPostSeason);
    this.pickups$ = this.store
      .select(selectOrderedPickups)
      .pipe(takeUntil(this.destroy$));
    this.store
      .select(selectShouldShowCalendar)
      .pipe(takeUntil(this.destroy$))
      .subscribe((shouldShow) => this.shouldShowCalendar = shouldShow);
    this.store
      .select(selectProperties)
      .pipe(takeUntil(this.destroy$))
      .subscribe((properties) => {
        if (properties.length !== 0) {
          this.properties = properties;
          this.loadPickupsForLastProperty();
        }
      });
    this.store
      .select(hasSeasonBeenCompleted)
      .pipe(takeUntil(this.destroy$))
      .subscribe(completed => this.isSeasonCompleted = completed);

    this.store.dispatch(loadProperties());
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public loadPickupsForProperty(propertyId: number): void {
    localStorage.setItem(lastSelectedPropertyKey, propertyId.toString());
    this.selectedPropertyId = propertyId;
    this.store.dispatch(loadPickups({ propertyId }));
  }

  public onRequestSubmitted(req: Bobby.CreateOrUpdateBookingCommand): void {
    this.store.dispatch(createOrUpdateBooking(req));
  }

  public calendar(): void {
    this.router.navigate([bobbyCalvesCalendarRoute]);
  }

  public loadPickupsForLastProperty() {
    if (this.properties.length) {
      const lastPropertyIdStr: string | null = localStorage.getItem(
        lastSelectedPropertyKey
      );
      const lastPropertyId: number | null = lastPropertyIdStr ?
        parseInt(lastPropertyIdStr) :
        null;
      const selectedPropertyId: number =
        lastPropertyId &&
        this.properties.some((property) => property.id === lastPropertyId) ?
          lastPropertyId :
          this.properties[0].id;
  
      this.loadPickupsForProperty(selectedPropertyId);
    }
    this.store.dispatch(loadOpenDeclarations());
  }
}
