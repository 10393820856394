<div mat-dialog-title>
    <h1>Season Completed</h1>
</div>

<mat-dialog-content>
    <span>You have indicated that your property '{{propertyName}}' won't require any future pick-ups. ANZCO will stop sending you reminder notifications and you won't be able to book any further pick-ups.</span>
    <br>
    <br>
    <span>To revert this, either deactivate the just activated slider or return to the bobby calves booking screen after submitting your last pick-up request.</span>
    <br>
    <br>
    <span>Thank you for doing business with ANZCO.</span>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button
            (click)="ok()"
            data-cy="confirmationOkButton">
        OK
    </button>
</mat-dialog-actions>