import { Component, Input } from '@angular/core';
import { Projection } from '../models';
import { FormatDatePipe } from 'src/app/miscellaneous/pipes/format-date.pipe';
import { Store } from '@ngrx/store';
import { editBooking } from '../state/actions';

@Component({
  selector: 'app-bobby-calves-booking-submitted-card',
  templateUrl: './bobby-calves-booking-submitted-card.component.html',
  styleUrls: ['./bobby-calves-booking-submitted-card.component.scss'],
})
export class BobbyCalvesBookingSubmittedCardComponent {
  @Input() pickup: Projection.Pickup;

  constructor(
    private readonly dateFormatter: FormatDatePipe,
    private readonly store: Store,
  ) { }

  public get date(): string {
    const pickupDateStr = this.dateFormatter
      .transform(this.pickup.date, 'dwdm');
    return pickupDateStr;
  }

  public get closingDateTime(): string {
    const today: Date = new Date();
    const closesDateStr: string =
      today.getDate() === this.pickup.bookingsCloseAt.getDate() &&
      today.getMonth() === this.pickup.bookingsCloseAt.getMonth() ?
        "Today" :
        this.dateFormatter.transform(this.pickup.bookingsCloseAt, 'dwdm');
    const timeStr: string =
      this.pickup.bookingsCloseAt.getHours() === 12 &&
      this.pickup.bookingsCloseAt.getMinutes() === 0 ?
        "Midday" :
        this.dateFormatter.transform(this.pickup.bookingsCloseAt, 't');

    return timeStr === "Midday" ?
        ("at " + timeStr + " " + closesDateStr) :
        (closesDateStr + " at " + timeStr);
  }

  public get statusDescription(): string {
    const currentPickupDateStr = this.dateFormatter.transform(this.pickup.date, 'dwdm');
    return currentPickupDateStr;
  }
  
  public editPickupRequest(): void {
    this.store.dispatch(editBooking({
      propertyId: this.pickup.anzcoPropertyId,
      calendarDateId: this.pickup.calendarDateId,
    }));
  }
}
