import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AngularMaterialModule } from '../../miscellaneous/angular-material.module';
import { RecentAdvicesComponent } from './recent-advices/recent-advices.component';
import { AllAdvicesComponent } from './all-advices/all-advices.component';
import { advicesFeatureKey, advicesReducer } from './state/reducer';
import { AdvicesEffects } from './state/effects';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdviceCardsComponent } from './advice-cards/advice-cards.component';
import { ProcessingTableComponent } from './all-advices/processing-table';
import { AdviceFilterComponent } from './all-advices/advice-filter';
import { SingleAdviceComponent } from './single-advice/single-advice.component';

@NgModule({
  declarations: [
    RecentAdvicesComponent,
    AllAdvicesComponent,
    SingleAdviceComponent,
    AdviceFilterComponent,
    ProcessingTableComponent,
    AdviceCardsComponent,
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    StoreModule.forFeature(advicesFeatureKey, advicesReducer),
    EffectsModule.forFeature([AdvicesEffects]),
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    RecentAdvicesComponent,
  ]
})
export class AdvicesModule { }
