import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { adminRoute } from './index';
import { CommandGetAdminUserTasks } from './commands/command-get-admin-user-tasks';
import { Selector } from './state/selector';

@Component({
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.scss'],
})
export class AdminComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  private timerId: any;

  constructor(
    private readonly router: Router,
    private readonly store: Store,
    private readonly selector: Selector,
    private readonly commandGetAdminUserTasks: CommandGetAdminUserTasks,
  ) {}

  public async ngOnInit(): Promise<void> {
    await this.recursivelyFetchAdminTasks();
    this.subscription = this.store
      .select(this.selector.selectSubPage)
      .subscribe((subPage: string) => {
        if (subPage && this.router.url.endsWith(adminRoute)) {
          this.router.navigate([adminRoute, subPage]);
        }
      });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    clearInterval(this.timerId);
  }

  private async recursivelyFetchAdminTasks(failed = 0): Promise<void> {
    if (failed >= 3) {
      console.error('Failed to fetch admin tasks 3 times in a row. Stopping.');
      return Promise.reject('Failed to fetch admin tasks 3 times in a row.');
    }

    try {
      await this.commandGetAdminUserTasks.execute();
      this.timerId = setTimeout(() => this.recursivelyFetchAdminTasks(0), 6000);
    } catch (error) {
      console.error(error);
      this.timerId = setTimeout(() => this.recursivelyFetchAdminTasks(failed + 1), 6000);
    }
  }
}
