import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, map } from 'rxjs/operators';
import { Models } from '../models';
import { FORM_DEBOUNCE_TIME_MS } from '../constants';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit {
  @Output() searchChanged = new EventEmitter<Models.PropertySearchRequest>();

  @Output() userDetails = new EventEmitter<Models.User>();

  @Input() set disabled(disabled: boolean) {
    const emitEvent = { emitEvent: false };
    if (disabled) {
      this.userInfoForm.disable(emitEvent);
      this.searchForm.disable(emitEvent);
    } else {
      this.userInfoForm.enable(emitEvent);
      this.searchForm.enable(emitEvent);
    }
  }

  readonly searchForm = new FormGroup({
    searchTerm: new FormControl(''),
    producerCode: new FormControl(''),
  });

  readonly userInfoForm = new FormGroup({
    email: new FormControl('', Validators.required),
    firstName: new FormControl(''),
    surname: new FormControl(''),
    contactNumber: new FormControl(''),
    advantageEmail: new FormControl(null),
  });

  constructor(
    @Inject(FORM_DEBOUNCE_TIME_MS) private readonly debounceTime: number
  ) {}

  ngOnInit(): void {
    this.userInfoForm.valueChanges
      .pipe(
        debounceTime(this.debounceTime),
        map(this.trimWhiteSpaceFromUserDetails)
      )

      .subscribe((value) => this.userDetails.emit(value));

    this.searchForm.valueChanges
      .pipe(debounceTime(this.debounceTime))
      .subscribe((value) => this.searchChanged.emit(value));
  }

  private trimWhiteSpaceFromUserDetails(user: Models.User): Models.User {
    return {
      ...user,
      advantageEmail: user.advantageEmail?.trim(),
      contactNumber: user.contactNumber?.trim(),
      email: user.email?.trim(),
      firstName: user.firstName?.trim(),
      surname: user.surname?.trim(),
    };
  }
}
