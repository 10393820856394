import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { complianceReducer, complianceFeatureKey } from './state/reducer';
import { ComplianceEffects } from './state/effects';
import { AngularMaterialModule } from '../../miscellaneous';
import { FormatDatePipe } from '../../miscellaneous/pipes/format-date.pipe';
import { ComplianceComponent } from './compliance.component';
import { ComplianceTableComponent } from './compliance-table/compliance-table.component';
import { ComplianceCardsComponent } from './compliance-cards/compliance-cards.component';

@NgModule({
  imports: [
    StoreModule.forFeature(complianceFeatureKey, complianceReducer),
    EffectsModule.forFeature([ComplianceEffects]),
    FormsModule,
    CommonModule,
    AngularMaterialModule,
  ],
  exports: [],
  declarations: [
    ComplianceComponent,
    ComplianceTableComponent,
    ComplianceCardsComponent,
  ],
  providers: [FormatDatePipe],
})
export class ComplianceModule {}
