import { createReducer, on } from "@ngrx/store";
import { Model } from "../interfaces";
import {
    filterCleared,
    filterUpdated,
    getAdviceFilterOptions,
    getAdviceFilterOptionsCompleted,
    loadRecentAdvicesCompleted,
    searchAdvices,
    searchAdvicesCompleted,
    toggleFilterAllStockTypes,
    toggleFilterStockType,
    showFilter,
    selectAdvice,
    unselectAdvice,
    toggleFilterAllStatues,
    toggleFilterStatues
} from "./actions";

export const advicesFeatureKey = "property-advices";

const initialFilter: Model.AdvicesQuery = {
    pageSize: 10,
    pageNumber: 1,
    startDate: null,
    endDate: null,
    propertyId: null,
    stockTypes: [],
    adviceStatuses: [],
};

const initialState: Model.AdvicesState = {
    recentAdvices: [],
    adviceSearchResults: [],
    selectedAdvice: null,
    filterOptions: {
        properties: [],
        stockTypes: [],
        adviceStatuses: [],
    },
    filter : initialFilter,
    totalAdvicesForSearch: 0,
    showFilter: true,
}

export const advicesReducer = createReducer<Model.AdvicesState>(
    initialState,
    on(loadRecentAdvicesCompleted, (state, { response }) => {
        return {
            ...state,
            recentAdvices: response.errored ? [] : response.response.advices,
        };
    }),

    on(searchAdvices, (state) => {
        return {
            ...state,
            adviceSearchResults: [],
        };
    }),

    on(searchAdvicesCompleted, (state, { response }) => {
        return {
            ...state,
            adviceSearchResults: response.errored ? [] : response.response.advices,
            totalAdvicesForSearch: response.errored ? 0 : response.response.totalResults,
        };
    }),

    on(getAdviceFilterOptions, (state) => {
        return {
            ...state,
        };
    }),

    on(getAdviceFilterOptionsCompleted, (state, { response }) => {
        return {
            ...state,
            filter : state.filterOptions.properties.length === response.properties.length ?
                state.filter :
                {
                    ...state.filter,
                    pageNumber: 1,
                    propertyId: response.properties.length === 1 ?
                        response.properties[0].propertyId :
                        state.filter.propertyId,
                    stockTypes: response.stockTypes.slice(),
                    adviceStatuses: response.adviceStatuses.slice(),
                },
            filterOptions: response,
        };
    }),

    on(filterUpdated, (state, { update }) => {
        return {
            ...state,
            filter: {
                ...state.filter,
                ...update,
            }
        };
    }),

    on(filterCleared, (state) => {
        return {
            ...state,
            filter: {
                pageSize: state.filter.pageSize,
                pageNumber: 1,
                startDate: null,
                endDate: null,
                propertyId: null,
                stockTypes: state.filterOptions.stockTypes,
                adviceStatuses: state.filterOptions.adviceStatuses,
            },
        };
    }),

    on(toggleFilterAllStockTypes, (state) => {
        return {
            ...state,
            filter: {
                ...state.filter,
                pageNumber: 1,
                stockTypes: state.filter.stockTypes.length === state.filterOptions.stockTypes.length ?
                    [] :
                    state.filterOptions.stockTypes,
            },
        };
    }),

    on(toggleFilterStockType, (state, { stockType }) => {
        return {
            ...state,
            filter: {
                ...state.filter,
                pageNumber: 1,
                stockTypes: state.filter.stockTypes.includes(stockType) ?
                    state.filter.stockTypes.filter(existing => existing !== stockType) :
                    state.filter.stockTypes.concat(stockType),
            },
        };
    }),

    on(toggleFilterAllStatues, (state) => {
        return {
            ...state,
            filter: {
                ...state.filter,
                pageNumber: 1,
                adviceStatuses: state.filter.adviceStatuses.length === state.filterOptions.adviceStatuses.length ?
                    [] :
                    state.filterOptions.adviceStatuses,
            },
        };
    }),

    on(toggleFilterStatues, (state, { status }) => {
        return {
            ...state,
            filter: {
                ...state.filter,
                pageNumber: 1,
                adviceStatuses: state.filter.adviceStatuses.includes(status) ?
                    state.filter.adviceStatuses.filter(existing => existing !== status) :
                    state.filter.adviceStatuses.concat(status),
            },
        };
    }),

    on(showFilter, (state, { show }) => {
        return {
            ...state,
            showFilter: show,
        };
    }),

    on(selectAdvice, (state, { season, advice, propertyId }) => {
        const selectedAdvice =
            state.recentAdvices.find(adv =>
                adv.season === season &&
                adv.advice === advice &&
                adv.propertyId === propertyId) ??
            state.adviceSearchResults.find(adv =>
                adv.season === season &&
                adv.advice === advice &&
                adv.propertyId === propertyId) ?? null;

        return {
            ...state,
            selectedAdvice,
        };
    }),

    on(unselectAdvice, (state) => {
        return {
            ...state,
            selectedAdvice: null,
        };
    }),
);