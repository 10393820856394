import { createAction, props } from '@ngrx/store';
import { Model } from '../interfaces';

export const loadUser = createAction("[User] Load User Started");
export const loadUserCompleted = createAction("[User] Load User Completed", props<{ user: Model.User }>());

export const registerAccount = createAction('[User] Register Account Started', props<{ userDetails: Model.UserDetails}>());
export const registerAccountSuccess = createAction('[User] Register Account Completed', props<{ userDetails: Model.UserDetails}>());

export const toggleMenu = createAction('[User] Toggle Menu', props);
export const closeMenu = createAction('[User] Close Menu', props);

export const deactivateBobbyMenu = createAction('[User] Deactivate Bobby Menu', props);

export const loadBobbyCalvesTally = createAction("[User] Load Bobby Calves Tally Started");
export const loadBobbyCalvesTallyCompleted = createAction(
    "[User] Load Bobby Calves Tally Completed",
    props<{ tallies: Model.ProducerServiceResponse<Model.TotalTallyResponse> }>());

export const loadOpenDeclarations = createAction("[User] Load Bobby Calves Open Declarations Started");
export const loadOpenDeclarationsCompleted = createAction(
    "[User] Load Bobby Calves Open Declarations Completed",
    props<{ declarations: Model.ProducerServiceResponse<Model.OpenDeclarationsResponse> }>());

export const showOpenDeclarations = createAction("[User] Show Bobby Calves Open Declarations", props<{ show: boolean }>());

export const createOrUpdatePickupDeclaration = createAction(
    "[User] Create Pickup Declaration Started",
    props<Model.CreateOrUpdatePickupDeclarationCommand>());
  
export const createOrUpdatePickupDeclarationCompleted = createAction(
    "[User] Create Pickup Declaration Completed",
    props<Model.ProducerServiceResponse<Model.CreateOrUpdatePickupDeclarationResponse>>());
  