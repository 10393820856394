<mat-card *ngFor="let advice of advices"
          (click)="selectAdvice(advice)">
    <mat-card-content>
        <div class="header"
             [class]="advice.cardBackgroundClass">
            <div>
                <div class="emphasis front-label">{{ advice.propertyName }}</div>
                <div class="back-label">
                    <div>STATUS:</div>
                    <strong>{{ advice.adviceStatus }}</strong>
                </div>
            </div>
            <div>
                <div>{{ adviceKillDate(advice) | date: "dd/MM/yyyy" }}</div>
                <div>#{{ advice.advice }}</div>
            </div>
            <label *ngIf="advice.bookingType === 3">Contracted</label>
            <label *ngIf="advice.bookingType === 6">Paddock Sale</label>
        </div>

        <div *ngIf="advice.feedbackComments" class="comment">
            <label><strong>Comments: </strong> {{ advice.feedbackComments }}</label>
        </div>
    
        <div *ngFor="let adviceLine of advice.adviceLines"
             class="info-container">
            <div>
               <div class="image-container">
                   <img [src]="adviceLine.imageSrc" />
                   <p class="emphasis">{{ adviceLine.stockType }}</p>
               </div>
    
               <div class="stats-container">
                   <div *ngIf="advice.bookingType !== 6">
                       <p>Avg. Weight</p>
                       <p>{{ adviceLine.averageWeight ? (adviceLine.averageWeight | number: "1.1-1") : "-" }}</p>
                   </div>
                    <div *ngIf="advice.bookingType !== 6">
                        <p>$/Head</p>
                        <p>{{ adviceLine.paidPerHead ? (adviceLine.paidPerHead | number: "1.2-2") : "-" }}</p>
                    </div>
                    <div>
                        <p *ngIf="adviceLine.condemnedTally">Tally*</p>
                        <p *ngIf="!adviceLine.condemnedTally">Tally</p>
                        <p>{{ adviceLine.tally ? (adviceLine.tally | number) : "-" }}</p>
                    </div>
                    <div *ngIf="advice.bookingType === 6">
                        <p>$/Head</p>
                        <p>{{ adviceLine.paidPerHead ? (adviceLine.paidPerHead | number: "1.2-2") : "-" }}</p>
                    </div>
                    <div>
                        <p>INV (excl GST)</p>
                        <p>{{ adviceLine.totalNettAmount ? (adviceLine.totalNettAmount | number: "1.2-2") : "-" }}</p>
                    </div>
                </div>
            </div>

            <div *ngIf="adviceLine.condemnedTally">
                <label>* Includes <strong>{{ adviceLine.condemnedTally }}</strong> condemned</label>
            </div>
        </div>

        <div *ngIf="advice.invoiceFileId || advice.hasCarcaseFiles"
            class="downloads">
            <div *ngIf="advice.invoiceFileId"
                class="download-icon"
                matRipple
                [ngClass]="{ divider: advice.hasCarcaseFiles }"
                (click)="downloadInvoice(advice, $event)">
                <mat-icon aria-hidden="false" aria-label="Invoice icon">
                    <span class="material-icons"> monetization_on </span>
                </mat-icon>
                <p>Invoice</p>
            </div>

            <div *ngIf="advice.hasCarcaseFiles"
                class="download-icon"
                matRipple
                (click)="downloadCarcaseData(advice, $event)">
                <mat-icon aria-hidden="false" aria-label="Chart icon">
                    <span class="material-icons-outlined"> insert_chart_outlined </span>
                </mat-icon>
                <p>Carcase Data</p>
            </div>
        </div>
    </mat-card-content>
</mat-card>