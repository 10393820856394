import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Selector } from '../state/selector';
import { Observable, Subscription } from 'rxjs';
import { Model, Projection } from '../admin.interfaces';
import { Router } from '@angular/router';
import { adminRoute, newRequestRoute } from '../constants';
import { userEdited } from '../state/actions';

@Component({
  selector: 'app-new-user-requests',
  templateUrl: './new-user-requests.component.html',
  styleUrls: ['../admin.scss'],
})
export class NewUserRequestsComponent {
  public adminTasks: Observable<Projection.AdminTask[]>;

  constructor(
    private readonly router: Router,
    private readonly store: Store,
    private readonly selector: Selector
  ) {
    this.adminTasks = this.store.select(this.selector.selectNewUserAdminTasks);
  }

  public async editUserRequest(adminTaskId: number): Promise<void> {
    const subscription: Subscription = this.store
      .select(this.selector.selectAdminTasks)
      .subscribe((adminTasks: Model.AdminTask[]) => {
        const adminTask: Model.AdminTask = adminTasks.find(
          (adminTask) => adminTask.id === adminTaskId
        );

        this.store.dispatch(
          userEdited({
            user: adminTask,
            disabled: adminTask.assignedTo === null,
            adminTaskId,
          })
        );
        this.router.navigate([adminRoute, newRequestRoute]);
      });
    subscription.unsubscribe();
  }
}
