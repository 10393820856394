<div mat-dialog-title>
    <h1>{{title}}</h1>
</div>

<mat-dialog-content>
    <span>{{text}}</span>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button
            class="cancel-button"
            (click)="cancel()"
            data-cy="confirmationCancelButton">
        Cancel
    </button>
    <button mat-raised-button
            (click)="discard()"
            data-cy="confirmationDiscardButton">
        Discard
    </button>
    <button mat-raised-button
            [disabled]="!submitValid"
            (click)="save()"
            data-cy="confirmationSaveButton">
        Save
    </button>
</mat-dialog-actions>