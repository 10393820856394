export enum ComplianceStatus {
  Applied = -1,
  NoCompliance = 0,
  Withdrawn = 1, // Removed
  Certified = 2,
  ExpiresSoon = 3,
  NotCertified = 4, // Expired
  Suspended = 5, // CAR
  NoAccess = 100,
}

export class ComplianceStatusEnum {
  public static Applied = new ComplianceStatusEnum(ComplianceStatus.Applied, 'Applied');
  public static NoCompliance = new ComplianceStatusEnum(ComplianceStatus.NoCompliance, 'N/A');
  public static Withdrawn = new ComplianceStatusEnum(ComplianceStatus.Withdrawn, 'Withdrawn');
  public static Certified = new ComplianceStatusEnum(ComplianceStatus.Certified, 'Certified');
  public static ExpiresSoon = new ComplianceStatusEnum(ComplianceStatus.ExpiresSoon, 'Expires Soon');
  public static NotCertified = new ComplianceStatusEnum(ComplianceStatus.NotCertified, 'Not Certified');
  public static Suspended = new ComplianceStatusEnum(ComplianceStatus.Suspended, 'CARs');
  public static NoAccess = new ComplianceStatusEnum(ComplianceStatus.NoAccess, 'No Access');

  private static allComplianceStatus: { [complianceStatus: number]: ComplianceStatusEnum };

  constructor(
    public readonly complianceStatus: ComplianceStatus,
    public readonly description: string,
  ) {
    if (!ComplianceStatusEnum.allComplianceStatus) {
      ComplianceStatusEnum.allComplianceStatus = {};
    }
    ComplianceStatusEnum.allComplianceStatus[complianceStatus] = this;
  }

  public static get(complianceStatus: ComplianceStatus): ComplianceStatusEnum {
    return this.allComplianceStatus[complianceStatus] || ComplianceStatusEnum.NoCompliance;
  }

  public static parse(complianceStatus: ComplianceStatus): string {
    return complianceStatus && ComplianceStatusEnum.allComplianceStatus[complianceStatus] ?
      ComplianceStatusEnum.allComplianceStatus[complianceStatus].description :
      '';
  }

  public static all(): ComplianceStatusEnum[] {
    return Object.entries(ComplianceStatusEnum.allComplianceStatus)
      .map(([key, value]) => value);
  }
}
