import { createAction, props } from '@ngrx/store';
import { Model } from '../interfaces';

export const loadComplianceLines = createAction("[Compliance] Load Compliance Lines Started");
export const loadComplianceLinesCompleted = createAction(
    "[Compliance] Load Compliance Lines Completed",
    props<Model.PotentialOutageResponse<Model.ComplianceLineResponse[]>>(),
);
export const complianceErrored = createAction(
    "[Compliance] complianceErrored",
    props<{ errored: boolean }>(),
);