import { createAction, props } from '@ngrx/store';
import { Bobby } from '../models';

const TYPE = '[Bobby Calves]';

export const createOrUpdateBooking = createAction(
  `${TYPE} createBookingStarted`,
  props<Bobby.CreateOrUpdateBookingCommand>()
);

export const createOrUpdateBookingCompleted = createAction(
  `${TYPE} createBookingCompleted`,
  props<Bobby.PotentialOutageResponse<{
    propertyId: number;
    calendarDateId: string;
    contactNumber: string;
    note: string;
    tally: number;
    isSeasonCompleted: boolean;
    response: Bobby.CreateOrUpdateBookingResponse;
  }>>(),
);

export const editBooking = createAction(
  `${TYPE} editBooking`,
  props<{
    propertyId: number;
    calendarDateId: string;
  }>()
);

export const loadPickups = createAction(
  `${TYPE} loadPickupsStarted`,
  props<{ propertyId: number }>()
);

export const loadPickupsCompleted = createAction(
  `${TYPE} loadPickupsCompleted`,
  props<Bobby.PotentialOutageResponse<Bobby.PickupsByPropertyResponse>>()
);

export const loadProperties = createAction(`${TYPE} loadPropertiesStarted`);

export const loadPropertiesCompleted = createAction(
  `${TYPE} loadPropertiesCompleted`,
  props<Bobby.PotentialOutageResponse<Bobby.PropertiesResponse>>()
);

export const loadPropertyCalendar = createAction(
  `${TYPE} loadPropertyCalendarStarted`,
  props<{ propertyId: number }>()
);

export const loadPropertyCalendarCompleted = createAction(
  `${TYPE} loadPropertyCalendarCompleted`,
  props<Bobby.PotentialOutageResponse<Bobby.PropertyCalendarResponse>>()
);

export const updateTally = createAction(
  `${TYPE} updateTally`,
  props<{
    propertyId: number;
    calendarDateId: string;
    tally: number;
  }>()
);

export const updateContactNumber = createAction(
  `${TYPE} updateContactNumber`,
  props<{
    propertyId: number;
    calendarDateId: string;
    contactNumber: string;
  }>()
);

export const updateNote = createAction(
  `${TYPE} updateNote`,
  props<{
    propertyId: number;
    calendarDateId: string;
    note: string;
  }>()
);

export const seasonComplete = createAction(
  `${TYPE} seasonCompleteStarted`,
  props<Bobby.SeasonCompletedCommand>()
);

export const seasonCompleteCompleted = createAction(
  `${TYPE} seasonCompleteCompleted`,
  props<Bobby.PlainPotentialOutageResponse>(),
);

export const bobbyCalvesErrored = createAction(
  `${TYPE} bobbyCalvesErrored`,
  props<{
    errored: boolean;
  }>()
);
