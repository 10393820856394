import { createReducer, on } from "@ngrx/store";
import { Role } from "src/app/miscellaneous/enums/role.enum";
import { Model } from "../interfaces";
import { loadOwnerPropertiesStarted, loadOwnerPropertiesCompleted, selectStaff, addStaff, removeStaffCompleted, updateStaffDetail, updateStaffRole, addStaffCompleted, changeStaffCompleted } from "./actions";

export const featureReducerKey = "staff-management";

const initialState: Model.UserManagementState = {
    properties: [],
    staff: [],
    selectedStaffId: null,
    editedStaff: null,
    editedStaffChanged: false,
}

export const userManagementReducer = createReducer<Model.UserManagementState>(
    initialState,
    
    on(loadOwnerPropertiesStarted, (state: Model.UserManagementState) => {
        return initialState;
    }),

    on(loadOwnerPropertiesCompleted, (state: Model.UserManagementState, response) => {
        return response.errored ?
            state :
            {
                properties: response.response.properties,
                staff: response.response.staff,
                selectedStaffId: state.selectedStaffId,
                editedStaffChanged: state.editedStaffChanged,
                editedStaff: createStaffCopy(
                    response.response.properties,
                    response.response.staff,
                    state.selectedStaffId,
                ),
        };
    }),

    on(selectStaff, (state: Model.UserManagementState, { userId }) => {
        return {
            ...state,
            selectedStaffId: userId,
            editedStaffChanged: false,
            editedStaff: createStaffCopy(
                state.properties,
                state.staff,
                userId,
            ),
        };
    }),

    on(updateStaffDetail, (state: Model.UserManagementState, { update }) => {
        return {
            ...state,
            editedStaff: {
                ...state.editedStaff,
                ...update,
            },
            editedStaffChanged: true,
        };
    }),

    on(updateStaffRole, (state: Model.UserManagementState, {
        propertyId,
        role,
        isActive,
    }) => {
        const index: number = state.editedStaff.roles.findIndex(role => role.propertyId === propertyId);
        const roles: Model.StaffProperty[] = state.editedStaff.roles.slice();

        roles[index] = {
            propertyId,
            isActive: role !== Role.Unknown && isActive,
            role,
        };
        return {
            ...state,
            editedStaff: {
                ...state.editedStaff,
                roles,
            },
            editedStaffChanged: true,
        };
    }),

    on(removeStaffCompleted, (state: Model.UserManagementState, { userId }) => {
        return {
            ...state,
            staff: state.staff.filter(user => user.userId !== userId ),
            editedStaffChanged: false,
        };
    }),

    on(addStaff, (state: Model.UserManagementState) => {
        return {
            ...state,
            editedStaffChanged: false,
            editedStaff: {
                userId: null,
                email: null,
                firstName: null,
                surname: null,
                contactNumber: null,
                isActive: true,
                receivePromotionalMaterial: false,
                roles: state.properties.map(property => {
                    return {
                        propertyId: property.propertyId,
                        isActive: false,
                        role: Role.Unknown,
                    }
                }),
            },
        };
    }),

    on(addStaffCompleted, (state: Model.UserManagementState, { staff }) => {
        const existingRoles: Model.StaffProperty[] = staff.roles.filter(role => role.role !== Role.Unknown);

        return {
            ...state,
            staff: state.staff.concat({
                ...staff,
                roles: existingRoles,
            }),
            selectedStaffId: null,
            editedStaff: null,
            editedStaffChanged: false,
        };
    }),

    on(changeStaffCompleted, (state: Model.UserManagementState, { staff }) => {
        const existingRoles: Model.StaffProperty[] = staff.roles.filter(role => role.role !== Role.Unknown);
        
        return {
            ...state,
            staff: state.staff
                .filter(stateStaff => stateStaff.userId !== staff.userId)
                .concat({
                    ...staff,
                    roles: existingRoles,
                }),
            selectedStaffId: null,
            editedStaff: null,
            editedStaffChanged: false,
        };
    }),
);

function createStaffCopy(
    properties: Model.Property[],
    staff: Model.Staff[],
    selectedStaffId: number,
): Model.Staff {
    const toCopy: Model.Staff = staff.find(staff => staff.userId === selectedStaffId);
    const result: Model.Staff = toCopy ?
        {
            userId: toCopy.userId,
            email: toCopy.email,
            firstName: toCopy.firstName,
            surname: toCopy.surname,
            isActive: toCopy.isActive,
            roles: properties.map((ownerProperty: Model.Property) => {
                const existingRole: Model.StaffProperty =
                    toCopy.roles.find(role => role.propertyId === ownerProperty.propertyId);
                const staffProperty: Model.StaffProperty = existingRole ?
                    {
                        isActive: existingRole.isActive,
                        propertyId: existingRole.propertyId,
                        role: existingRole.role,
                    } :
                    {
                        isActive: false,
                        propertyId: ownerProperty.propertyId,
                        role: Role.Unknown,
                    };

                return staffProperty;
            }),
        } :
        {
            userId: null,
            email: null,
            firstName: null,
            surname: null,
            isActive: null,
            roles: properties.map((ownerProperty: Model.Property) => {
                const staffProperty: Model.StaffProperty = {
                    isActive: false,
                    propertyId: ownerProperty.propertyId,
                    role: Role.Unknown,
                };

                return staffProperty;
            }),
        };

    return result;
}