import {
  Component,
  Input,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MatCalendar } from "@angular/material/datepicker";

export interface DateColour {
  date: Date;
  colour: string;
}

@Component({
  selector: "app-multi-select-calendar",
  templateUrl: "multi-select-calendar.component.html",
  styleUrls: ["multi-select-calendar.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class MultiSelectCalendarComponent {
  selected: DateColour[] = [];
  @Input() startDate: string | undefined;
  @Input() set daysSelected(selected: DateColour[]) {
    this.selected = selected;
  };

  @ViewChild(MatCalendar) calendar!: MatCalendar<Date>;

  get endDate(): Date {
    const startDate: Date = new Date(<string>this.startDate);
    const result: Date = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
    return result;
  }

  public isSelected = (date: any) => {
    const dateColour: DateColour | null = <DateColour>this.selected.find(selectedDate =>
      selectedDate.date.getDate() == date._d.getDate() &&
      selectedDate.date.getMonth() == date._d.getMonth() &&
      selectedDate.date.getFullYear() == date._d.getFullYear()) ?? null;
    const colour: string | null = dateColour ?
      (dateColour.colour.startsWith("#") ?
       dateColour.colour.substring(1, dateColour.colour.length) :
       dateColour.colour) :
      null;

    return colour ? "selected_" + colour : '';
  };
} 