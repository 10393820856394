<mat-card>
  <mat-card-title>
    <span>Requests have now closed for </span>
    <span>{{ date }}</span>
  </mat-card-title>
  <mat-card-content>
    <span>We are sorry, requests for this pick-up date have now closed.</span>
    <span>Please contact your Bobby Calf Livestock Representative.</span>
    <span>To view your booking request for {{ date }}, please refresh the screen.</span>
  </mat-card-content>
</mat-card>
