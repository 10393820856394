import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import {
  selectedAugustDates,
  selectedBobbyCalvesBuyerName,
  selectedBobbyCalvesBuyerPhone,
  selectedBuyerName,
  selectedBuyerPhone,
  selectedJulyDates,
  selectedJuneDates,
  selectedOctoberDates,
  selectedPropertyId,
  selectedCalendarPropertyName,
  selectedNovemberDates,
  selectedSeptemberDates,
  selectedTransports
} from '../state/selectors';
import { takeUntil } from 'rxjs/operators';
import { loadPropertyCalendar } from '../state/actions';
import { DateColour } from './multi-select-calendar/multi-select-calendar.component';
import { bobbyCalvesProfileRoute } from '../constants';

@Component({
  selector: 'app-bobby-calves-calendar',
  templateUrl: './bobby-calves-calendar.component.html',
  styleUrls: ['./bobby-calves-calendar.component.scss'],
})
export class BobbyCalvesCalendarComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject();
  private readonly year: number = new Date().getFullYear();
  public propertyName: string;
  public selectedPropertyId: number;
  public juneDates: DateColour[];
  public julyDates: DateColour[];
  public augustDates: DateColour[];
  public septemberDates: DateColour[];
  public octoberDates: DateColour[];
  public novemberDates: DateColour[];
  public buyerName: string;
  public buyerPhone: string;
  public bobbyBuyerName: string | null;
  public bobbyBuyerPhone: string | null;
  public transports: string[];

  get june(): string {
    return this.year + '-06-01';
  }

  get july(): string {
    return this.year + '-07-01';
  }

  get august(): string {
    return this.year + '-08-01';
  }

  get september(): string {
    return this.year + '-09-01';
  }

  get october(): string {
    return this.year + '-10-01';
  }

  get november(): string {
    return this.year + '-11-01';
  }

  constructor(
    private readonly store: Store,
    private readonly router: Router,
  ) {}

  public ngOnInit(): void {
    this.store
      .select(selectedCalendarPropertyName)
      .pipe(takeUntil(this.destroy$))
      .subscribe(name => this.propertyName = name);
    this.store
      .select(selectedJuneDates)
      .pipe(takeUntil(this.destroy$))
      .subscribe(dates => this.juneDates = dates);
    this.store
      .select(selectedJulyDates)
      .pipe(takeUntil(this.destroy$))
      .subscribe(dates => this.julyDates = dates);
    this.store
      .select(selectedAugustDates)
      .pipe(takeUntil(this.destroy$))
      .subscribe(dates => this.augustDates = dates);
    this.store
      .select(selectedSeptemberDates)
      .pipe(takeUntil(this.destroy$))
      .subscribe(dates => this.septemberDates = dates);
    this.store
      .select(selectedOctoberDates)
      .pipe(takeUntil(this.destroy$))
      .subscribe(dates => this.octoberDates = dates);
    this.store
      .select(selectedNovemberDates)
      .pipe(takeUntil(this.destroy$))
      .subscribe(dates => this.novemberDates = dates);
    this.store
      .select(selectedBuyerName)
      .pipe(takeUntil(this.destroy$))
      .subscribe(name => this.buyerName = name);
    this.store
      .select(selectedBuyerPhone)
      .pipe(takeUntil(this.destroy$))
      .subscribe(phone => this.buyerPhone = phone);
    this.store
      .select(selectedBobbyCalvesBuyerName)
      .pipe(takeUntil(this.destroy$))
      .subscribe(name => this.bobbyBuyerName = name);
    this.store
      .select(selectedBobbyCalvesBuyerPhone)
      .pipe(takeUntil(this.destroy$))
      .subscribe(phone => this.bobbyBuyerPhone = phone);
    this.store
      .select(selectedTransports)
      .pipe(takeUntil(this.destroy$))
      .subscribe(transports => this.transports = transports);
    this.store
      .select(selectedPropertyId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(selectedPropertyId => {
        if (selectedPropertyId) {
          this.selectedPropertyId = selectedPropertyId;
          this.store.dispatch(loadPropertyCalendar({ propertyId: selectedPropertyId }));
        }
        else {
          this.cancel();
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public cancel(): void {
    this.router.navigate([bobbyCalvesProfileRoute]);
  }
}
