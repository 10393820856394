<mat-toolbar>
  <div class="menu-div">
    <button
      data-cy="admin-menu"
      mat-button
      [matMenuTriggerFor]="adminuser"
      class="main-menu-button"
    >
      {{ username | async }}
    </button>

    <mat-menu #adminuser="matMenu">
      <button
        mat-menu-item
        data-cy="becomeSelfMenuItem"
        class="menu-sub-item"
        [disabled]="shouldHideBecomeSelf | async"
        (click)="becomeSelf()"
      >
        Become Self
      </button>
      <button
        data-cy="admin-logout-btn"
        mat-menu-item
        class="menu-sub-item"
        (click)="logout()"
      >
        Log Out
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
