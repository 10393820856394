<table mat-table
       [dataSource]="compliances"
       class="mat-elevation-z8">
    <ng-container matColumnDef="complianceProgramName">
        <th mat-header-cell *matHeaderCellDef>Programme</th>
        <td mat-cell *matCellDef="let compliance">
            <div class="sub-row">
                <p>{{ compliance.complianceProgramDescription }}</p>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="propertyName">
        <th mat-header-cell *matHeaderCellDef>Property</th>
        <td mat-cell *matCellDef="let compliance">
            <div class="sub-row">
                <p>{{ compliance.propertyName }}</p>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="expiryDate">
        <th mat-header-cell *matHeaderCellDef class="center-column">Certification Expiry</th>
        <td mat-cell *matCellDef="let compliance">
            <div class="sub-row center-column">
                <p>{{ compliance.expiryDate ? (compliance.expiryDate | date: "dd/MM/yyyy") : '-' }}</p>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="statusName">
        <th mat-header-cell *matHeaderCellDef class="center-column">Status</th>
        <td mat-cell *matCellDef="let compliance">
            <div *ngIf="compliance.status !== 5" class="sub-row center-column">
                <div *ngIf="compliance.status === -1" class="status applied">
                    <p>{{ compliance.statusName }}</p>
                </div>
                <div *ngIf="compliance.status === 1" class="status withdrawn">
                    <p>{{ compliance.statusName }}</p>
                </div>
                <div *ngIf="compliance.status === 2" class="status certified">
                    <p>{{ compliance.statusName }}</p>
                </div>
                <div *ngIf="compliance.status === 3" class="status expires-soon">
                    <p>{{ compliance.statusName }}</p>
                </div>
                <div *ngIf="compliance.status === 4 " class="status not-certified">
                    <p>{{ compliance.statusName }}</p>
                </div>
            </div>
            <div *ngIf="compliance.status === 5" class="sub-row tall-row center-column">
                <div class="status not-certified">
                    <p>{{ compliance.statusName }}</p>
                </div>
                <span>Corrective Actions Required</span>
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let compliance; columns: tableColumns"></tr>
</table>