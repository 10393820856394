import { Component, Input } from "@angular/core";
import { Projection } from "../interfaces";

@Component({
    selector: "app-compliance-table",
    templateUrl: "compliance-table.component.html",
    styleUrls: ["compliance-table.component.scss"]
})
export class ComplianceTableComponent {
    @Input() public compliances: Projection.Compliances[] = [];

    public readonly tableColumns: string[] = [
        "complianceProgramName",
        "propertyName",
        "expiryDate",
        "statusName",
    ];
}