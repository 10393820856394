import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { showHelp } from '../help/index';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public releaseVersion: string = "";
  public currentYear: number = null;

  constructor(
    private readonly store: Store,
  ) { }

  public ngOnInit(): void {
    this.releaseVersion = String(environment.releaseVersion);
    this.currentYear = new Date().getFullYear()
  }

  public help(): void {
    this.store.dispatch(showHelp());
  }
}