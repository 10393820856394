import { Injectable } from "@angular/core";
import { createSelector } from "@ngrx/store";
import { Model } from "../spinner.interfaces";

@Injectable({ providedIn: 'root' })
export class Selector {
    private selectActiveProcesses = (state: Model.State) => state.spinner.activeProcesses;
    private selectStartApp = (state: Model.State) => state.spinner.startApp;

    public selectActiveSpinner = createSelector(
        this.selectActiveProcesses,
        this.selectStartApp,
        (selectActiveProcesses: string[], selectStartApp: boolean) => selectStartApp || selectActiveProcesses.length > 0,
    );
}