<div class="default-container" data-cy="documents-page-shell">
  <div class="headline">
    <h2>Bobby Calf Pick-up Declaration History</h2>
  </div>

  <div class="filter-container">
    <div class="labeled-data-item drop-down">
      <mat-label>Property</mat-label>
      <mat-select [(ngModel)]="propertyId"
                  [disabled]="properties.length < 2">
          <mat-option *ngIf="properties.length > 1"
                      [value]="0"
                      (click)="selectProperty(null)">
              All Properties
          </mat-option>
          <mat-option *ngFor="let property of properties"
                      [value]="property.id"
                      (click)="selectProperty(property.id)">
              {{property.name}}
          </mat-option>
      </mat-select>
    </div>

    <div class="labeled-data-item date-picker">
        <mat-label>Date From</mat-label>
        <div (click)="fromPicker.open()">
            <input matInput
                   readonly
                   [matDatepicker]="fromPicker"
                   [value]="startDate"
                   (dateChange)="updateFilter({ startDate: $event.value?.toDate() })" />
            <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
            <mat-datepicker #fromPicker></mat-datepicker>
        </div>
    </div>

    <div class="labeled-data-item date-picker">
        <mat-label>Date To</mat-label>
        <div (click)="toPicker.open()">
            <input matInput
                   readonly
                   [matDatepicker]="toPicker"
                   [value]="endDate"
                   (dateChange)="updateFilter({ endDate: $event.value?.toDate() })" />
            <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
            <mat-datepicker #toPicker></mat-datepicker>
        </div>
    </div>

    <div class="labeled-data-item">
        <button mat-raised-button
                color="primary"
                (click)="clearFilters()">
            Clear Filter
        </button>
    </div>
  </div>

  <div>
    <div class="mat-elevation-z8">
      <app-declarations-table [declarations]="declarations"></app-declarations-table>
      <app-declaration-cards [declarations]="declarations"></app-declaration-cards>
      <div *ngIf="declarations.length === 0" class="no-results">No bobby calf declarations found</div>
      <mat-paginator *ngIf="declarations?.length > 0"
                      [length]="totalDeclarations"
                      [pageIndex]="pageIndex"
                      [pageSize]="pageSize"
                      [pageSizeOptions]="pageSizeOptions"
                      [showFirstLastButtons]="true"
                      (page)="onPage($event)"
                      aria-label="Select page">
      </mat-paginator>
    </div>
  </div>
</div>