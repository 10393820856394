import { Injectable } from '@angular/core';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as reportingActions from './actions';
import { of } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import moment from 'moment';
import { HttpDateFormatter } from 'src/app/miscellaneous/http-date-formatter';
import { BlobSaver } from 'src/app/miscellaneous/file-downloader';
import { Model } from '../interfaces';

@Injectable()
export class ReportingEffects {
  constructor(private actions$: Actions, private readonly http: HttpClient) {}

  getFilterOptions$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reportingActions.getFinancialReportOptions),
      switchMap(() =>
        this.http
          .get<Model.AvailableProperty[]>(
            `${environment.apiUrl}/api/reporting/financial-report-options`
          )
          .pipe(
            map((availableProperties) =>
              reportingActions.getFinancialReportOptionsCompleted({
                availableProperties
              })
            )
          )
      )
    );
  });

  downloadFinancialReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reportingActions.downloadFinancialReport),
      switchMap((action) => {
        const params: any = {
          propertyIds: action.propertyIds,
          startDate: HttpDateFormatter.format(action.startDate, false),
          endDate: HttpDateFormatter.format(action.endDate, false),
        };
        return this.http
          .get(`${environment.apiUrl}/api/reporting/financial-report`, {
            responseType: 'blob',
            params,
          })
          .pipe(
            tap((res) => {
              BlobSaver.saveFile(
                res,
                `FinancialReport-${moment().format('YYYY-MM-DD-HH-mm')}.xlsx`
              );
            }),
            catchError((error: any) => {
              if (error instanceof HttpErrorResponse) {
                const httpError = error as HttpErrorResponse;
                if (httpError.status === 404) {
                  return of(
                    reportingActions.downloadFinancialReportFailure({
                      error: 'No data found for the selected period.',
                    })
                  );
                }
              }
              throw error;
            })
          );
      })
    );
  });
}
