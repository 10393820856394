import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { bobbyReducer, bobbyReducerKey } from './state/reducer';
import { BobbyEffects } from './state/effects';
import { AngularMaterialModule, LoaderModule } from '../../miscellaneous';
import { BobbyCalvesComponent } from './bobby-calves.component';
import { SharedPipesModule } from '../../miscellaneous/pipes/shared-pipes.module';
import { BobbyCalvesUpcomingCardComponent } from './bobby-calves-upcoming-card/bobby-calves-upcoming-card.component';
import { BobbyCalvesNoCalvesSubmittedCardComponent } from './bobby-calves-no-calves-submitted-card/bobby-calves-no-calves-submitted-card.component';
import { BobbyCalvesNoBookingSubmittedCardComponent } from './bobby-calves-no-booking-submitted-card/bobby-calves-no-booking-submitted-card.component';
import { BobbyCalvesApprovedCardComponent } from './bobby-calves-approved-card/bobby-calves-approved-card.component';
import { BobbyCalvesAmendedCardComponent } from './bobby-calves-amended-card/bobby-calves-amended-card.component';
import { BobbyCalvesBookingSubmittedCardComponent } from './bobby-calves-booking-submitted-card/bobby-calves-booking-submitted-card.component';
import { BobbyCalvesAwaitingApprovalCardComponent } from './bobby-calves-awaiting-approval-card/bobby-calves-awaiting-approval-card.component';
import { BobbyCalvesNotYetSubmittedCardComponent } from './bobby-calves-not-yet-submitted-card/bobby-calves-not-yet-submitted-card.component';
import { FormatDatePipe } from '../../miscellaneous/pipes/format-date.pipe';
import { BobbyCalvesPreseasonLandingComponent } from './bobby-calves-preseason-landing/bobby-calves-preseason-landing.component';
import { BobbyCalvesOutageComponent } from './bobby-calves-outage/bobby-calves-outage.component';
import { BobbyCalvesEndOFSeasonComponent } from './bobby-calves-end-of-season/bobby-calves-end-of-season.component';
import { BobbyCalvesCalendarComponent } from './bobby-calves-calendar/bobby-calves-calendar.component';
import { MultiSelectCalendarComponent } from './bobby-calves-calendar/multi-select-calendar/multi-select-calendar.component';
import { BobbyCalvesLateEntryCardComponent } from './bobby-calves-late-entry-card/bobby-calves-late-entry-card.component';
import { BobbyCalvesApprovedDeclarationCardComponent } from './bobby-calves-approved-declaration-card/bobby-calves-approved-declaration-card.component';
import { BobbyCalvesDeclarationSubmittedCardComponent } from './bobby-calves-declaration-submitted-card/bobby-calves-declaration-submitted-card.component';
import { BobbyCalvesAmendedDeclarationCardComponent } from './bobby-calves-amended-declaration-card/bobby-calves-amended-declaration-card.component';
import { BobbyCalvesSeasonCompletedCardComponent } from './bobby-calves-season-completed-card/bobby-calves-season-completed-card.component';
import { SeasonCompletedDialogComponent } from './season-completed-dialog/season-completed-dialog.component';
import { ReactivateSeasonDialogComponent } from './reactivate-season-dialog/reactivate-season-dialog.component';
import { BobbyCalvesPickedUpDigitalDeclarationCardComponent } from './bobby-calves-calves-picked-up-digital-declaration-card/bobby-calves-calves-picked-up-digital-declaration-card.component';
import { BobbyCalvesPickedUpPaperDeclarationCardComponent } from './bobby-calves-calves-picked-up-paper-declaration-card/bobby-calves-calves-picked-up-paper-declaration-card.component';

@NgModule({
  imports: [
    StoreModule.forFeature(bobbyReducerKey, bobbyReducer),
    EffectsModule.forFeature([BobbyEffects]),
    FormsModule,
    CommonModule,
    AngularMaterialModule,
    SharedPipesModule,
    LoaderModule,
  ],
  exports: [],
  declarations: [
    BobbyCalvesComponent,
    BobbyCalvesOutageComponent,
    BobbyCalvesPreseasonLandingComponent,
    BobbyCalvesEndOFSeasonComponent,
    BobbyCalvesUpcomingCardComponent,
    BobbyCalvesNoBookingSubmittedCardComponent,
    BobbyCalvesBookingSubmittedCardComponent,
    BobbyCalvesNoCalvesSubmittedCardComponent,
    BobbyCalvesAwaitingApprovalCardComponent,
    BobbyCalvesApprovedCardComponent,
    BobbyCalvesAmendedCardComponent,
    BobbyCalvesNotYetSubmittedCardComponent,
    BobbyCalvesApprovedDeclarationCardComponent,
    BobbyCalvesAmendedDeclarationCardComponent,
    BobbyCalvesDeclarationSubmittedCardComponent,
    BobbyCalvesLateEntryCardComponent,
    BobbyCalvesCalendarComponent,
    BobbyCalvesSeasonCompletedCardComponent,
    BobbyCalvesPickedUpDigitalDeclarationCardComponent,
    BobbyCalvesPickedUpPaperDeclarationCardComponent,
    MultiSelectCalendarComponent,
    SeasonCompletedDialogComponent,
    ReactivateSeasonDialogComponent,
  ],
  providers: [FormatDatePipe],
})
export class BobbyCalvesModule {}
