<button
  *ngFor="let property of properties"
  (click)="selectBooking(property.anzcoPropertyId, property.bookingId)">
  <div *ngIf="property.status === 1" class="incomplete-color"></div>
  <div *ngIf="property.status === 2" class="complete-color"></div>
  <div class="property-description">
    <div class="booking-identifier web">
      <mat-label class="left-align">{{ property.propertyName }}</mat-label>
      <mat-label class="right-align">Pick-up Date: {{ date(property.bookingId) }}</mat-label>
    </div>
    <div class="booking-identifier mobile">
      <mat-label class="left-align">{{ property.propertyName }}</mat-label>
      <mat-label class="right-align">{{ date(property.bookingId) }}</mat-label>
    </div>
    <div>
      <mat-label class="left-align">Declaration Status: </mat-label>
      <mat-label *ngIf="property.status === 1" class="right-align">Incomplete</mat-label>
      <mat-label *ngIf="property.status === 2" class="right-align">Complete</mat-label>
    </div>
  </div>
  <div class="next-icon">
    <i class="bts bt-angle-right bt-2x"></i>
  </div>
</button>