import { createReducer, on } from '@ngrx/store';
import {
  loadPickupsCompleted,
  loadPropertiesCompleted,
  updateTally,
  updateContactNumber,
  updateNote,
  createOrUpdateBookingCompleted,
  editBooking,
  bobbyCalvesErrored,
  loadProperties,
  loadPickups,
  loadPropertyCalendarCompleted,
} from './actions';
import { Bobby } from '../models';

export const bobbyReducerKey = 'bobbyCalves';

export const initialBobbyState: Bobby.State = {
  pickupResponse: {
    hasSeasonStarted: false,
    hasSeasonEnded: false,
    isSeasonCompleted: false,
    userName: '',
    pickups: [],
  },
  properties: [],
  bookings: [],
  calendar: null,
  selectedPropertyId: null,
  loaded: false,
  errored: false,
};

export const bobbyReducer = createReducer<Bobby.State>(
  initialBobbyState,

  on (
    loadPickups,
    (
      state: Bobby.State,
      res: { propertyId: number },
    ): Bobby.State => ({
      ...state,
      selectedPropertyId: res.propertyId,
    })
  ),

  on (
    loadProperties,
    (state: Bobby.State): Bobby.State => ({
      ...state,
      loaded: false,
    })
  ),

  on(
    loadPickupsCompleted,
    (
      state: Bobby.State,
      res: Bobby.PotentialOutageResponse<Bobby.PickupsByPropertyResponse>,
    ): Bobby.State => ({
      ...state,
      loaded: true,
      pickupResponse: res.errored ?
        {
          hasSeasonEnded: false,
          hasSeasonStarted: false,
          isSeasonCompleted: false,
          userName: '',
          pickups: [],
        } :
        res.response,
      bookings: res.errored ?
        [] :
        res.response.pickups
          .filter(
            (pickup) => pickup.status === Bobby.PickupStatus.NotYetSubmitted
          )
          .map((pickup) => {
            const booking: Bobby.Booking = {
              anzcoPropertyId: pickup.anzcoPropertyId,
              calendarDateId: pickup.calendarDateId,
              contactNumber: pickup.contactNumber,
              note: null,
              tally: 0,
            };

            return booking;
          }),
    })
  ),

  on(
    loadPropertiesCompleted,
    (state: Bobby.State, res: Bobby.PotentialOutageResponse<Bobby.PropertiesResponse>): Bobby.State => ({
      ...state,
      loaded: res.errored ?
        true :
        res.response.properties.length === 0,
      properties: res.errored ?
        [] :
        res.response.properties.map(property => {
          return {
            id: property.id,
            name: property.name,
          };
        })
        .sort((propertyA, propertyB) => propertyA.name.localeCompare(propertyB.name)),
    })
  ),

  on(createOrUpdateBookingCompleted, (state: Bobby.State, res): Bobby.State => {
    if (res.errored) {
      return { ...state };
    }
    const pickupIndex = state.pickupResponse.pickups.findIndex(
      (pickup) =>
        pickup.anzcoPropertyId === res.response.propertyId &&
        pickup.calendarDateId === res.response.calendarDateId
    );
    const newPickups: Bobby.PickupsByPropertyPickupResponse[] =
      state.pickupResponse.pickups.slice();

    newPickups[pickupIndex] = {
      ...newPickups[pickupIndex],
      status: res.response.response.result === 1 ?
        Bobby.PickupStatus.BookingSubmitted :
        Bobby.PickupStatus.LateEntry,
      contactNumber: res.response.contactNumber,
      booking: {
        id: res.response.response.id,
        requestedBy: state.pickupResponse.userName,
        note: res.response.note,
        requestedNumOfCalves: res.response.tally,
        approvedNumOfCalves: null,
        pickupDeclaration: null,
        pickupCompletion: null,
      },
    };

    return {
      ...state,
      pickupResponse: {
        ...state.pickupResponse,
        isSeasonCompleted: res.response.isSeasonCompleted,
        pickups: newPickups,
      },
      bookings: state.bookings.filter(
        (booking) =>
          booking.anzcoPropertyId !== res.response.propertyId ||
          booking.calendarDateId !== res.response.calendarDateId
      ),
    };
  }),

  on(editBooking, (state: Bobby.State, res): Bobby.State => {
    const pickupIndex = state.pickupResponse.pickups.findIndex(
      (pickup) =>
        pickup.anzcoPropertyId === res.propertyId &&
        pickup.calendarDateId === res.calendarDateId
    );
    const newPickups: Bobby.PickupsByPropertyPickupResponse[] =
      state.pickupResponse.pickups.slice();
    const newBookings: Bobby.Booking[] = state.bookings.slice();

    newBookings.push({
      anzcoPropertyId: newPickups[pickupIndex].anzcoPropertyId,
      calendarDateId: newPickups[pickupIndex].calendarDateId,
      contactNumber: newPickups[pickupIndex].contactNumber,
      note: newPickups[pickupIndex].booking.note,
      tally: newPickups[pickupIndex].booking.requestedNumOfCalves,
    });
    newPickups[pickupIndex] = {
      ...newPickups[pickupIndex],
      status: Bobby.PickupStatus.NotYetSubmitted,
      booking: newPickups[pickupIndex].booking,
    };

    return {
      ...state,
      pickupResponse: {
        ...state.pickupResponse,
        pickups: newPickups,
      },
      bookings: newBookings,
    };
  }),

  on(updateTally, (state: Bobby.State, res): Bobby.State => {
    const bookingIndex: number = state.bookings.findIndex(
      (booking) =>
        booking.anzcoPropertyId === res.propertyId &&
        booking.calendarDateId === res.calendarDateId
    );
    const newBookings: Bobby.Booking[] = state.bookings.slice();

    newBookings[bookingIndex] = {
      ...state.bookings[bookingIndex],
      tally: res.tally,
    };
    return {
      ...state,
      bookings: newBookings,
    };
  }),

  on(updateContactNumber, (state: Bobby.State, res): Bobby.State => {
    const bookingIndex: number = state.bookings.findIndex(
      (booking) =>
        booking.anzcoPropertyId === res.propertyId &&
        booking.calendarDateId === res.calendarDateId
    );
    const newBookings: Bobby.Booking[] = state.bookings.slice();

    newBookings[bookingIndex] = {
      ...state.bookings[bookingIndex],
      contactNumber: res.contactNumber,
    };
    return {
      ...state,
      bookings: newBookings,
    };
  }),

  on(updateNote, (state: Bobby.State, res): Bobby.State => {
    const bookingIndex: number = state.bookings.findIndex(
      (booking) =>
        booking.anzcoPropertyId === res.propertyId &&
        booking.calendarDateId === res.calendarDateId
    );
    const newBookings: Bobby.Booking[] = state.bookings.slice();

    newBookings[bookingIndex] = {
      ...state.bookings[bookingIndex],
      note: res.note,
    };
    return {
      ...state,
      bookings: newBookings,
    };
  }),

  on(loadPropertyCalendarCompleted, (state: Bobby.State, res): Bobby.State => {
    return {
      ...state,
      calendar: res.response,
    };
  }),

  on(bobbyCalvesErrored, (state: Bobby.State, res): Bobby.State => {
    return {
      ...state,
      errored: res.errored,
    };
  }),
);