import {
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  Renderer2,
  ViewContainerRef,
  HostBinding,
} from '@angular/core';
import { Model } from './interfaces';
import { LoaderComponent } from './loader.component';

@Directive({ selector: '[appLoader]' })
export class LoaderDirective {
  private spinner: ComponentRef<LoaderComponent>;

  @Input()
  public set appLoader(options: Model.LoaderOptions) {
    if (options.show) {
      this.show(options);
    } else {
      this.hide();
    }
  }

  /**
   * HostBinding applies the `loader-container` class on the html element containing this directive when true
   * This sets its positive to relative, allowing us to overlay the spinner over the entire element
   */
  @HostBinding('class.loader-container') public doesSpinnerExist = false;

  constructor(
    private directiveView: ViewContainerRef,
    private renderer: Renderer2,
    private directiveElement: ElementRef
  ) {}

  private hide() {
    if (this.doesSpinnerExist) {
      this.directiveView.remove();
      this.doesSpinnerExist = false;
    }
  }

  private show(options: Model.LoaderOptions) {
    if (!this.doesSpinnerExist) {
      this.spinner = this.directiveView.createComponent(LoaderComponent);
      this.spinner.instance.options = options;
      this.spinner.changeDetectorRef.detectChanges();
      this.renderer.appendChild(
        this.directiveElement.nativeElement,
        this.spinner.location.nativeElement
      );
      this.doesSpinnerExist = true;
    }
  }
}
