<app-loader *ngIf="searchProperties.loading$ | async"></app-loader>
<table class="table">
  <thead>
    <tr>
      <th>Property ID</th>
      <th class="property-name">Property Name</th>
      <th>Role</th>
      <th class="lock"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let property of propertyRows; trackBy: trackByPropertyId">
      <td>{{ property.propertyId }}</td>
      <td>{{ property.propertyName }}</td>
      <td>
        <ng-container *ngIf="!property.locked; else readonlyRole">
          <button mat-flat-button color="primary" [matMenuTriggerFor]="roles">
            {{ property.role.description }}
          </button>
          <mat-menu #roles="matMenu">
            <button
              mat-menu-item
              *ngFor="let role of allRoles"
              (click)="onRoleClicked(property, role)"
            >
              {{ role.description }}
            </button>
          </mat-menu>
        </ng-container>
        <ng-template #readonlyRole>{{ property.role.description }}</ng-template>
      </td>
      <td>
        <ng-container *ngIf="property.showLockBtn">
          <button
            *ngIf="property.locked; else unlocked"
            mat-flat-button
            color="accent"
            (click)="property.locked = !property.locked"
          >
            Unlock
          </button>
          <ng-template #unlocked>
            <button
              mat-flat-button
              color="primary"
              (click)="property.locked = !property.locked"
            >
              Lock
            </button>
          </ng-template>
        </ng-container>
      </td>
    </tr>
    <tr *ngIf="!propertyRows.length">
      <td colspan="4" class="no-properties">No properties found</td>
    </tr>
  </tbody>
</table>
