import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

// News content comes from the CMS system as raw HTML including scripts for embedded podcast and magazines.
// This pipe enables us to embed these stories.
// Only trusted HTML content should be used with this pipe. i.e. this is not suitable for use with user submitted HTML.
@Pipe({
  name: 'trustedHtml'
})
export class TrustedHtmlPipe implements PipeTransform {
  constructor(
    private readonly sanitized: DomSanitizer
  ) {}

  public transform(rawHtml: string): SafeHtml {
    return this.sanitized.bypassSecurityTrustHtml(rawHtml);
  }
}
