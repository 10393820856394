<div data-cy="news-container" class="default-container">
  <ng-container *ngIf="newsFeed">
    <div class="headline" #top>
      <h2 data-cy="news-heading">{{ newsTypeEnum.description }}</h2>
      <!-- <button mat-raised-button [routerLink]="newsRoute">
         All Articles &#62;
      </button> -->
    </div>

    <div class="categories" *ngIf="newsTypeEnum.categories">
      <mat-chip-list>
        <mat-chip
          (click)="onSelectCategory(null)"
          [selected]="!selectedCategory"
        >
          All
        </mat-chip>
        <mat-chip
          *ngFor="let category of newsTypeEnum.categories"
          (click)="onSelectCategory(category)"
          [selected]="selectedCategory === category"
        >
          {{ category }}
        </mat-chip>
      </mat-chip-list>
    </div>

    <div class="feed">
      <div
        class="news-feed-item"
        *ngFor="let newsItem of newsFeed.items"
        [routerLink]="[newsItemRouteLinkPrefix, newsItem.id]"
      >
        <img [src]="newsItem.featuredImageUrl" />
        <div>
          <div class="headline">
            <h2>{{ newsItem.title }}</h2>
          </div>
          <p>Posted on {{ newsItem.publishedDate | date: "fullDate" }}</p>
          <button
            mat-raised-button
            class="read-more"
            [routerLink]="[newsItemRouteLinkPrefix, newsItem.id]"
          >
            Read more &#62;
          </button>
        </div>
      </div>
    </div>

    <div class="navigation">
      <mat-paginator
        [hidePageSize]="true"
        [length]="newsFeed.totalCount"
        [pageSize]="pageSize"
        [pageIndex]="newsFeed.currentOffset / pageSize"
        [showFirstLastButtons]="true"
        (page)="onPage($event)"
        aria-label="Select page"
      >
      </mat-paginator>
    </div>
  </ng-container>
  <app-news-error></app-news-error>
</div>
