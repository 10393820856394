import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { loadPickups } from "../state/actions";
import { selectedPropertyId } from "../state/selectors";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
    selector: "app-reactivate-season-dialog",
    templateUrl: "./reactivate-season-dialog.component.html",
    styleUrls: ["./reactivate-season-dialog.component.scss"],
})
export class ReactivateSeasonDialogComponent implements OnInit {
    private readonly destroy$ = new Subject();
    public propertyName: string;
    public text: string;
    private propertyId: number;

    constructor(
        private readonly dialogRef: MatDialogRef<ReactivateSeasonDialogComponent>,
        private readonly store: Store,
        @Inject(MAT_DIALOG_DATA) data,
    ) {
        dialogRef.disableClose = true;
        this.propertyName = data.propertyName;
    }

    public ngOnInit(): void {
        this.store
            .select(selectedPropertyId)
            .pipe(takeUntil(this.destroy$))
            .subscribe((propertyId) => this.propertyId = propertyId);
    }

    public ok(): void {
        this.store.dispatch(loadPickups({ propertyId: this.propertyId }));
        this.dialogRef.close(true);
    }
}