import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ConfirmationDialogComponent } from "../confimation-dialog/index";
import { staffManagementRoute } from "./constants";
import { Projection } from "./interfaces";
import { addStaff, loadOwnerPropertiesStarted, removeStaffStarted, selectStaff } from "./state/actions";
import { Selector } from "./state/selector";

@Component({
    selector: "app-all-staff-management",
    templateUrl: "./all-staff-management.component.html",
    styleUrls: ["./staff-management.component.scss"],
})
export class AllStaffManagementComponent implements OnInit, OnDestroy {
    public readonly userTableColumns: string[] = [
        "firstName",
        "surname",
        "email",
        "isActive",
        "actions",
    ];

    private readonly destroy$ = new Subject();

    public propertyName: string;
    public users: Projection.PropertyStaff[];

    constructor(
        private readonly router: Router,
        private readonly dialog: MatDialog,
        private readonly store: Store,
        private readonly selector: Selector,
    ) { }

    public ngOnInit(): void {
        this.store.dispatch(loadOwnerPropertiesStarted());

        this.store
            .select(this.selector.selectAllStaff)
            .pipe(takeUntil(this.destroy$))
            .subscribe((users: Projection.PropertyStaff[]) => this.users = users);
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public addStaff(): void {
        this.store.dispatch(addStaff());
        this.router.navigate(
            [staffManagementRoute],
            { queryParams: { userId: null }});
    }

    public removeStaff(
        userId: number,
        firstName: string,
        surname: string,
        evt: MouseEvent,
    ): void {
        const name: string = firstName && surname ?
            firstName + " " + surname :
            (firstName ?? surname ?? "this staff member");
        evt.stopPropagation();

        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                title: "Remove Staff",
                text: "Do you want to remove " + name + " from all your properties?",
            },
        });
        dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.store.dispatch(removeStaffStarted({ userId }));
            }
        });
    }

    public selectStaff(userId: number): void {
        this.store.dispatch(selectStaff({ userId }));
        this.router.navigate(
            [staffManagementRoute],
            { queryParams: { userId }});
    }
}