<div class="preview-container" *ngIf="newsItems?.length > 0">
  <div class="headline">
    <h3 class="news-title">{{ title }}</h3>
    <button
      mat-raised-button
      class="view-all-button"
      *ngIf="displayFeedLink"
      (click)="onViewAll()">
      View More &#62;
  </button>
  </div>
  <div class="news-container" [ngClass]="{ vertical: verticalDisplay }">
    <app-news-thumbnail
      *ngFor="let newsItem of newsItems"
      [newsItem]="newsItem"
      [ngClass]="{ 'multiple-thumbnails': newsItems.length > 1 }">
    </app-news-thumbnail>
  </div>
</div>
