import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
} from '@azure/msal-angular';
import {
  LogLevel,
  Configuration,
  BrowserCacheLocation,
  InteractionType,
} from '@azure/msal-browser';
import { environment } from '../environments/environment';

// as per Microsoft this workaround is required for Edge.
// see: https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-js-known-issues-ie-edge-browsers
const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

// TODO: There are a couple more improvements we can make in this area once the old React version of the site is deprecated and we have less moving parts to consider:
// 1 - use a separate client Id per environment
// 2 - use a redirect URI of  webUrl + "/auth" to cover potential routing edge cases.
//     See: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/msal-guard.md#using-the-msal-guard-with-path-routing
export const msalClientConfig: Configuration = {
  auth: {
    clientId: 'c4dfef03-58fa-49de-bedc-dfc1f3e09f4a',
    authority:
      'https://anzcoproducers.b2clogin.com/anzcoproducers.onmicrosoft.com/b2c_1_anzcoproducers_sign-in/',
    knownAuthorities: ['AnzcoProducers.b2clogin.com'],
    redirectUri: environment.webUrl,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.SessionStorage,
    storeAuthStateInCookie: isIE,
  },
  system: {
    loggerOptions: {
      loggerCallback: (logLevel, message, containsPii) => {
        // console.log(message);
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false,
    },
  },
};

const defaultApiScope: string =
  'https://AnzcoProducers.onmicrosoft.com/api/user.read';

export const msalGuardConfiguration: MsalGuardConfiguration = {
  interactionType: InteractionType.Redirect,
  authRequest: {
    scopes: [defaultApiScope],
  },
  loginFailedRoute: '/',
};

export const msalInterceptorConfiguration: MsalInterceptorConfiguration = {
  interactionType: InteractionType.Redirect,
  protectedResourceMap: new Map([
    [environment.apiUrl, ['openid', defaultApiScope]],
  ]),
};
