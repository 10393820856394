import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { becomeSelfCompleted, becomeSelfStarted } from "../state/actions";

@Injectable({ providedIn: 'root' })
export class CommandBecomeSelf {
    constructor(
        private readonly store: Store,
        private readonly http: HttpClient,
    ) {}

    public async execute(): Promise<void> {
        this.store.dispatch(becomeSelfStarted());
        await this.becomeSelf();
        this.store.dispatch(becomeSelfCompleted());
    }

    private async becomeSelf(): Promise<void> {
        await this.http
            .post(environment.apiUrl + "/api/admin/become-self", {})
            .toPromise();
    }
}