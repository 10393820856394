import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CanDeactivate } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { DiscardDialogComponent } from "../confimation-dialog/index";
import { Model } from "./interfaces";
import { StaffManagementComponent } from "./staff-management.component";
import { addStaffStarted, changeStaffStarted, selectStaff } from "./state/actions";
import { Selector } from "./state/selector";

@Injectable({ providedIn: 'root' })
export class CanDeactivateStaffManagement implements CanDeactivate<StaffManagementComponent> {
    constructor(
        private readonly dialog: MatDialog,
        private readonly store: Store,
        private readonly selector: Selector,
    ) {}

    public canDeactivate(): Observable<boolean> {
        return this.store
            .select(this.selector.selectEditedStaffChanged)
            .pipe(
                switchMap((changed: boolean) => {
                    let result: Observable<boolean> = of(true);

                    if (changed) {
                        result = this.store
                            .select(this.selector.selectSubmitValid)
                            .pipe(
                                switchMap((submitValid: boolean) => {
                                    const dialogRef = this.dialog.open(DiscardDialogComponent, {
                                        data: {
                                            submitValid, 
                                            title: "Changes Detected",
                                            text: "There are unsaved changed. How would you like to proceed?",
                                        },
                                    });
                            
                                    return this.handDialogResult(dialogRef.afterClosed());
                                })
                            );
                    }
                    return result;
                }),
            );
    }

    private handDialogResult(afterClosedDialog: Observable<boolean>): Observable<boolean> {
        return afterClosedDialog
            .pipe(
                map((dialogResult: boolean) => {
                    if (dialogResult) {
                        this.store
                            .select(this.selector.selectEditedStaff)
                            .subscribe((staff: Model.Staff) => {
                                if (staff.userId) {
                                    this.store.dispatch(changeStaffStarted({ staff }));
                                }
                                else {
                                    this.store.dispatch(addStaffStarted({ staff }));
                                }
                            })
                            .unsubscribe();
                        return true;
                    }
                    else if (dialogResult === null) {
                        this.store.dispatch(selectStaff({ userId: null }));
                        return true;
                    }
                    else {
                        return false;
                    }
                }));
    }
}