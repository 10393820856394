import { Injectable } from '@angular/core';
import { map, exhaustMap, mergeMap, tap, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as userActions from './actions';
import { UserService } from '../user.service';
import { RaygunWrapper } from 'src/app/app.raygun.setup';
import { Store } from '@ngrx/store';

@Injectable()
export class UserEffects {
  constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
    private readonly userService: UserService,
  ) {}

  public registerUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.registerAccount),
      exhaustMap((action) =>
        this.userService.register(action.userDetails).pipe(
          map(() =>
            userActions.registerAccountSuccess({
              userDetails: action.userDetails,
            })
          )
        )
      )
    );
  });

  public loadUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.loadUser),
      mergeMap(() =>
        this.userService.getUser().pipe(
          tap((user) =>
            RaygunWrapper.setUser(
              user.email,
              [user.firstName, user.surname].filter(Boolean).join(' ')
            )
          ),
          tap((user) => {
            if (user.hasBobbyCalvesAccess && !user.bobbyServiceOutage) {
              this.store.dispatch(userActions.loadOpenDeclarations());
            }
          }),
          map((user) => userActions.loadUserCompleted({ user })),
        )
      )
    );
  });

  public loadBobbyCalvesTally$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.loadBobbyCalvesTally),
      mergeMap(() =>
        this.userService.bobbyCalvesTally().pipe(
          map((tallies) => userActions.loadBobbyCalvesTallyCompleted({ tallies }))
        )
      )
    );
  });

  public loadOpenDeclarations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.loadOpenDeclarations),
      mergeMap(() =>
        this.userService.openDeclarations().pipe(
          map((declarations) => userActions.loadOpenDeclarationsCompleted({ declarations }))
        )
      )
    );
  });

  public createOrUpdatePickupDeclaration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.createOrUpdatePickupDeclaration),
      mergeMap((req) =>
        this.userService.createOrUpdatePickupDeclaration(req)
          .pipe(
            map((res) => userActions.createOrUpdatePickupDeclarationCompleted(res)),
          ),
      ),
    ),
  );
}
