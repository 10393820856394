<mat-card (click)="fillOutDeclaration()">
  <mat-card-title>
    <span role="heading">Confirmed</span>
  </mat-card-title>
  <mat-card-content>
    <div *ngIf="pickup.booking.approvedNumOfCalves !== pickup.booking.requestedNumOfCalves">
      <label>
        <strong>Requested Tally</strong>
      </label>
      <div>
        <del>{{ pickup.booking.requestedNumOfCalves }}</del>
      </div>
    </div>
    <div>
      <label>
        <strong>Confirmed Tally*</strong>
      </label>
      <div>
        <strong>{{ pickup.booking.approvedNumOfCalves }}</strong>
      </div>
    </div>
    <div>
      <label>
        <strong>Pick-up Date</strong>
      </label>
      <div>{{ date }}</div>
    </div>
    <div>
      <label>
        <strong>Requested By</strong>
      </label>
      <div>
        {{ pickup.booking.requestedBy }}
      </div>
    </div>
    <div class="panel-info-text">
      *Pick-ups are subject to change due to unforeseen circumstances e.g. adverse weather
    </div>
    <div class="declaration" >
      <div>
        <i class="bts bt-check-circle bt-2x"></i>
      </div>
      <div>
        <mat-label>DECLARATION COMPLETED</mat-label>
        <mat-label>Click here to update</mat-label>
      </div>
    </div>
  </mat-card-content>
</mat-card>
