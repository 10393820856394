import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { AngularMaterialModule } from "src/app/miscellaneous";
import { featureReducerKey, reducer } from "./state/reducer";

export { showHelp } from "./state/actions";
export { HelpComponent } from "./help.component"

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(featureReducerKey, reducer),
        AngularMaterialModule,
    ],
})
export class HelpModule {}