import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { adminTaskApprovedCompleted, adminTaskApprovedStarted } from "../state/actions";
import { Model } from "../admin.interfaces";

@Injectable({ providedIn: 'root' })
export class CommandPostApproveAdminUserTask {
    constructor(
        private readonly store: Store,
        private readonly http: HttpClient,
    ) {}

    public async execute(
        adminTaskId: number,
        user: Model.User,
        propertyRoles: Model.PropertyRole[],
    ): Promise<void> {
        this.store.dispatch(adminTaskApprovedStarted());
        await this.approveAdminTask(
            adminTaskId,
            user,
            propertyRoles,
        );
        this.store.dispatch(adminTaskApprovedCompleted({ adminTaskId }));
    }

    private async approveAdminTask(
        adminTaskId: number,
        user: Model.User,
        propertyRoles: Model.PropertyRole[],
    ): Promise<void> {
        const params = {
            adminTaskId,
            userId: user.userId,

            firstname: user.firstName,
            surName: user.surName,
            gstNumber: user.gstNumber,
            advantageUsername: user.advantageUsername,
            propertyRoles: propertyRoles
                .filter(propertyRole => propertyRole.locked)
                .map(propertyRole => {
                    const role = {
                        propertyId: propertyRole.propertyId,
                        roleId: propertyRole.role,
                    };

                    return role;
                }),
        };

        await this.http
            .post(environment.apiUrl + "/api/admin/approve-admin-task", params)
            .toPromise();
    }
}