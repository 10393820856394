import { Injectable } from "@angular/core";
import { Model } from "../admin.interfaces";
import { environment } from '../../../environments/environment';
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { loadUserByIdStarted, loadUserByIdCompleted } from "../state/actions";

@Injectable({ providedIn: 'root' })
export class CommandGetUserById {
    constructor(
        private readonly store: Store,
        private readonly http: HttpClient,
    ) {}

    public async retrieve(userId: number): Promise<void> {
        this.store.dispatch(loadUserByIdStarted());
        const userDetails: Model.UserDetails = await this.requestUserDetails(userId);
        const user: Model.User = {
            userId: userDetails.userId,

            email: userDetails.email,
            firstName: userDetails.firstName,
            gstNumber: userDetails.gstNumber,
            isActive: userDetails.isActive,
            surName: userDetails.surName,
            contactNumber: userDetails.contactNumber,
            advantageUsername: userDetails.advantageUsername,

            producerCode: "",
            producerName: "",
            role: null,
        };

        this.store.dispatch(loadUserByIdCompleted({ user }));
    }

    private async requestUserDetails(userId: number): Promise<Model.UserDetails> {
        return await this.http
            .get<Model.UserDetails>(environment.apiUrl + "/api/admin/user-by-id/", { params: { userId } })
            .toPromise();
    }
}