import { createAction, props } from '@ngrx/store';
import { Model } from '../interfaces';

export const downloadFinancialReport = createAction("[Reports] Download Financial Report", props<Model.FinancialReportQuery>());
export const downloadFinancialReportFailure = createAction("[Reports] Download Financial Report Failure", props<{ error: string }>());

export const getFinancialReportOptions = createAction("[Reports] Get Available Filter Options Started");
export const getFinancialReportOptionsCompleted = createAction("[Reports] Get Available Filter Options Completed", props<{ availableProperties: Model.AvailableProperty[] }>());

export const clearFilter = createAction("[Reports] Clear Filter");
export const toggleFilterProperty = createAction("[Reports] Toggle Filter Property", props<{ propertyId: number }>());
export const toggleFilterAllProperties = createAction("[Reports] Toggle Filter All Properties");
export const filterUpdated = createAction("[Reports] Filter Updated", props<{ update: Partial<Model.FinancialReportQuery> }>());