<mat-card (click)="showDeclaration()">
  <mat-card-title>
    <span role="heading">Picked Up</span>
  </mat-card-title>
  <mat-card-content>
    <div>
      <label>
        <strong>Pick-up Date</strong>
      </label>
      <div>{{ date }}</div>
    </div>

    <div>
      <label>
        <strong>Picked Up Tally</strong>
      </label>
      <div>
        <strong>{{ pickup.booking.pickedUpTally }}</strong>
      </div>
    </div>

    <div *ngIf="pickup.booking.numberRejectedWet">
      <label>
        <strong>Rejected - Wet</strong>
      </label>
      <div>
        <strong>{{ pickup.booking.numberRejectedWet }}</strong>
      </div>
    </div>

    <div *ngIf="pickup.booking.numberRejectedWetNavel">
      <label>
        <strong>Rejected - Wet Navel</strong>
      </label>
      <div>
        <strong>{{ pickup.booking.numberRejectedWetNavel }}</strong>
      </div>
    </div>

    <div *ngIf="pickup.booking.numberRejectedNoTag">
      <label>
        <strong>Rejected - No Tag</strong>
      </label>
      <div>
        <strong>{{ pickup.booking.numberRejectedNoTag }}</strong>
      </div>
    </div>

    <div *ngIf="pickup.booking.numberRejectedScours">
      <label>
        <strong>Rejected - Scours</strong>
      </label>
      <div>
        <strong>{{ pickup.booking.numberRejectedScours }}</strong>
      </div>
    </div>

    <div *ngIf="pickup.booking.numberRejectedWeak">
      <label>
        <strong>Rejected - Weak</strong>
      </label>
      <div>
        <strong>{{ pickup.booking.numberRejectedWeak }}</strong>
      </div>
    </div>

    <div *ngIf="pickup.booking.numberRejectedOther">
      <label class="cut-off">
        <strong>Rejected - </strong>{{ pickup.booking.rejectedOtherNote }}
      </label>
      <div>
        <strong>{{ pickup.booking.numberRejectedOther }}</strong>
      </div>
    </div>

    <div>
      <label>
        <strong>Driver Name</strong>
      </label>
      <div>{{ pickup.booking.pickedUpDriverName }}</div>
    </div>

    <div>
      <label>
        <strong>Approved Tally</strong>
      </label>
      <div>{{ pickup.booking.approvedNumOfCalves }}</div>
    </div>

    <div>
      <label>
        <strong>Booked By</strong>
      </label>
      <div>{{ pickup.booking.requestedBy }}</div>
    </div>

    <div class="declaration" >
      <div>
        <mat-label>DIGITAL DECLARATION COMPLETED</mat-label>
        <mat-label>Click here to view</mat-label>
      </div>
    </div>
  </mat-card-content>
</mat-card>
