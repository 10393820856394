import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TimeSpanFormatter {
  public fromTimeSpanString(timeSpanString: string): {
    hours: number;
    minutes: number;
  } {
    return fromTimeSpanString(timeSpanString);
  }

  public createTimeSpanString(hours: number, minute: number): string {
    return createTimeSpanString(hours, minute);
  }
}

export function fromTimeSpanString(timeSpanString: string): {
  hours: number;
  minutes: number;
} {
  const splitTimeSpanString: string[] = timeSpanString.split(':');

  return {
    hours: parseInt(splitTimeSpanString[0]),
    minutes: parseInt(splitTimeSpanString[1]),
  };
}

export function createTimeSpanString(hours: number, minute: number): string {
  const result: string =
    (hours < 10 ? '0' + hours : hours.toString()) +
    ':' +
    (minute < 10 ? '0' + minute : minute.toString()) +
    ':00';

  return result;
}