import { Component, OnInit } from '@angular/core';
import { Model } from '../models';
import { Store } from '@ngrx/store';
import {
  selectSelectedProperty,
  selectValidCalves,
  selectValidContactNumber,
  selectValidRecordedBy
} from '../state/selectors';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  decreaseTally,
  increaseTally,
  updateDeclarationfDetail
} from '../state/actions';
import { FormatDatePipe } from 'src/app/miscellaneous/pipes/format-date.pipe';

@Component({
  selector: 'app-pickup-declaration-edit',
  templateUrl: './pickup-declaration-edit.component.html',
  styleUrls: ['./pickup-declaration-edit.component.scss'],
})
export class PiclupDeclarationEditComponent implements OnInit {
  private readonly destroy$ = new Subject();
  public declaration: Model.SelectedBookingDeclaration;
  public validCalves: boolean = true;
  public validContactNumber: boolean = true;
  public validRecordedBy: boolean = true;

  constructor(
    private readonly store: Store,
    private readonly dateFormatter: FormatDatePipe,
  ) { }

  public get date(): string {
    const pickupDateStr = this.dateFormatter.transform(this.declaration.pickupDate, 'dwdm');
    return pickupDateStr;
  }

  public ngOnInit(): void {
    this.store
      .select(selectSelectedProperty)
      .pipe(takeUntil(this.destroy$))
      .subscribe(propertyDeclaration => this.declaration = propertyDeclaration);
    this.store
      .select(selectValidCalves)
      .pipe(takeUntil(this.destroy$))
      .subscribe(validCalves => this.validCalves = validCalves);
    this.store
      .select(selectValidContactNumber)
      .pipe(takeUntil(this.destroy$))
      .subscribe(validContactNumber => this.validContactNumber = validContactNumber);
    this.store
      .select(selectValidRecordedBy)
      .pipe(takeUntil(this.destroy$))
      .subscribe(validRecordedBy => this.validRecordedBy = validRecordedBy);
  }

  public updateDeclaration(update: Partial<Model.SelectedBookingDeclaration>): void {
    if (update.tally && !isNaN(update.tally)) {
      this.store.dispatch(updateDeclarationfDetail({ update: { tally: parseInt(update.tally.toString()) }}));
    }
    else {
      this.store.dispatch(updateDeclarationfDetail({ update }));
    }
  }

  public onHourChanged(hour: number) {
    this.updateDeclaration({ timeOfLastFeedHours: hour });
  }

  public onMinutesChanged(minutes: number) {
    this.updateDeclaration({ timeOfLastFeedMinutes: minutes });
  }

  public enforceMinMax(element: HTMLInputElement): void {
    if (element.value !== "" && parseInt(element.value) < parseInt(element.min)) {
      element.value = element.min;
    }
    if (element.value !== "" && parseInt(element.value) > parseInt(element.max)) {
      element.value = element.max;
    }
  }

  public decreaseTally(): void {
    this.store.dispatch(decreaseTally());
  }

  public increaseTally(): void {
    this.store.dispatch(increaseTally());
  }
}