<div class="error-snack-bar">
  <p>Oops something went wrong.</p>
  <p>Please try again soon.</p>
  <p>
    If the problem persists, please contact us on
    <a href="mailto:support@anzcoproducers.co.nz">
      support@anzcoproducers.co.nz
    </a>
  </p>
  <p>If you have an urgent matter, please contact your Livestock Representative or call 0800 4 ANZCO (0800 4 26926).</p>
  <button mat-raised-button (click)="reload()">Reload</button>
</div>