<div
  data-cy="news-overview"
  class="default-container"
  [ngClass]="{ compact: useCompactView }"
>
  <ng-container *ngIf="overviewReady">
    <div class="headline">
      <h2>News</h2>
    </div>
    <div class="news-section">
      <app-news-feed-preview
        data-cy="market-update-overview"
        title="Market Update"
        [newsItems]="anzcoUpdateItems"
        [displayFeedLink]="true"
      ></app-news-feed-preview>
      <app-news-feed-preview
        data-cy="latest-articles-overview"
        title="Latest Articles"
        [newsItems]="newItems"
        [displayFeedLink]="true"
      ></app-news-feed-preview>
      <app-news-feed-preview
        data-cy="farmer-info-overview"
        title="Farmer Info"
        [newsItems]="importantInfoItems"
        [displayFeedLink]="true"
      ></app-news-feed-preview>
    </div>
  </ng-container>

  <app-news-error></app-news-error>
</div>
