import { Component, HostBinding, Input } from '@angular/core';
import { Model } from './interfaces';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input() public options: Model.LoaderOptions = {
    loadingText: '',
    noBackgroundOverlay: false,
    show: true,
  };
  @HostBinding('class.no-background-overlay') get shouldDisableBackground() {
    return this.options.noBackgroundOverlay;
  }
}
