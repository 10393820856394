import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-error-snack-bar',
  templateUrl: './error-snack-bar.component.html',
  styleUrls: ['./error-snack-bar.component.scss'],
  encapsulation: ViewEncapsulation.None, // break out of Angular's default css encapsulation so we can easily disable clicks 
})
export class ErrorSnackBarComponent {
  public reload(): void{
    location.reload();
  }
}
