import { Component, OnInit } from '@angular/core';
import { DestroyedService } from '../../services/destroyed.service';
import { Models } from './models';
import { Router } from '@angular/router';
import { adminRoute, portalUsersRoute } from '../constants';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { CreateUserCommand } from './api/create-user.command';
import { first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
  providers: [DestroyedService],
})
export class CreateUserComponent implements OnInit {
  search: Models.PropertySearchRequest;
  userDetails$ = new BehaviorSubject<Models.User>(null);
  propertyRoles$ = new BehaviorSubject<Models.ApiProperty[]>([]);
  isConfirmed: boolean;
  isFormValid: boolean;

  constructor(
    public readonly createUser: CreateUserCommand,
    private readonly router: Router,
    private readonly destroyed$: DestroyedService
  ) {}

  ngOnInit(): void {
    combineLatest([this.userDetails$, this.propertyRoles$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.onFormUpdated());
  }

  get showConfirmed(): boolean {
    return !this.isConfirmed;
  }

  onCancel(): void {
    this.goToPortalUsers();
  }

  onConfirmed(): void {
    this.isConfirmed = true;
  }

  onFormUpdated(): void {
    this.isFormValid = isRequestValid(this.toRequest());
    this.isConfirmed = false;
  }

  onSubmitted(): void {
    this.createUser
      .execute(this.toRequest())
      .pipe(first())
      .subscribe(() => this.goToPortalUsers());
  }

  private goToPortalUsers(): void {
    const ignore = this.router.navigate([adminRoute, portalUsersRoute]);
  }

  private toRequest(): Models.CreateUserRequest {
    return {
      userDetails: this.userDetails$.value,
      propertyRoles: this.propertyRoles$.value,
    };
  }

  onSearchChanged($event: Models.PropertySearchRequest): void {
    this.search = $event;
    this.onFormUpdated();
  }
}

const isRequestValid = (request: Models.CreateUserRequest): boolean =>
  !!request.userDetails?.email &&
  !!request.userDetails?.firstName &&
  !!request.userDetails?.surname &&
  request.propertyRoles.length > 0;
