import {
  loadSSOLinkStarted,
  loadSSOLinkCompleted,
  loadSSOLinkFailure,
  loadPointsStarted,
  loadPointsCompleted,
  loadPointsFailure,
} from './actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Model } from '../interfaces';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { of } from 'rxjs';

@Injectable()
export class AdvantageEffects {
  constructor(
    private readonly actions: Actions,
    private readonly http: HttpClient
  ) {}

  public loadSSOLinkEffect$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadSSOLinkStarted),
      switchMap(() =>
          this.http
            .post<Model.AdvantageSSOResponse>(
              `${environment.apiUrl}/api/advantage/sso`,
              {}
            )
          .pipe(
            map((response) => loadSSOLinkCompleted({ link: response.url })),
            catchError(() =>
              of(loadSSOLinkFailure({ fallbackLink: environment.advantageUrl }))
            )
          )
      )
    )
  );

  public loadPointsEffect$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadPointsStarted),
      switchMap(() =>
          this.http
            .get<Model.AdvantagePointsResponse>(
              `${environment.apiUrl}/api/advantage/points`
            )
          .pipe(
            map((response) => loadPointsCompleted({ response })),
            catchError(() => of(loadPointsFailure()))
          )
      )
    )
  );
}