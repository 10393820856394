import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Projection } from '../interfaces';
import { selectSelectedAdvice } from '../state/selectors';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { homeRoute } from '../../home/index';
import { unselectAdvice } from '../state/actions';
import { CommandGetInvoice } from '../commands/command-get-invoice';
import { CommandGetCarcase } from '../commands/command-get-carcase';

@Component({
    selector: 'app-single-advice',
    templateUrl: './single-advice.component.html',
    styleUrls: ['../advices.scss', './single-advice.component.scss']
})
export class SingleAdviceComponent implements OnInit, OnDestroy {
    public advice: Projection.Advice;
    public previousRoute: string;
    private subscriptions: Subscription[];

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly store: Store,
        private readonly commandGetInvoice: CommandGetInvoice,
        private readonly commandGetCarcase: CommandGetCarcase,
    ) { }

    public async ngOnInit(): Promise<void> {
        this.subscriptions = [
            this.route.queryParams
                .subscribe(params => this.previousRoute = params["route"]),
            this.store.select(selectSelectedAdvice)
                .subscribe((advice: Projection.Advice) => {
                    this.advice = advice;

                    if (this.advice === null) {
                        this.router.navigate([homeRoute]);
                    }
                }),
        ];
    }

    public async downloadInvoice(evt: MouseEvent): Promise<void> {
        evt.stopImmediatePropagation();

        await this.commandGetInvoice.Retrieve(
            this.advice.invoiceFileId,
            this.advice.advice,
            this.advice.season,
            this.advice.propertyId,
            this.advice.adviceLines.reduce(
                (lowestLine: Projection.AdviceLine, currentLine: Projection.AdviceLine) =>
                    lowestLine.killDate > currentLine.killDate ? currentLine : lowestLine).killDate,
            this.advice.isBobbyAdvice);
    }

    public async downloadCarcaseData(evt: MouseEvent): Promise<void> {
        evt.stopImmediatePropagation();

        for (let index = 0; index < this.advice.adviceLines.length; index++) {
            const adviceLine: Projection.AdviceLine = this.advice.adviceLines[index];
            
            if (adviceLine.carcaseFileId)
            {
                await this.commandGetCarcase.Retrieve(
                    adviceLine.carcaseFileId,
                    this.advice.advice,
                    this.advice.season,
                    this.advice.propertyId,
                    adviceLine.killDate);
            }
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
    }

    public goBack(): void {
        this.store.dispatch(unselectAdvice());
        this.router.navigate([this.previousRoute]);
    }
}