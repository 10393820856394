import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Model } from './interfaces';
import { selectDeclarations, selectDeclarationsFilter, selectProperties, selectTotalDeclarations } from './state/selectors';
import { clearFilter, filterUpdated, loadDeclarations, loadProperties } from './state/actions';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject();
  public readonly pageSizeOptions: number[] = [10, 20, 50, 100];
  public properties: Model.Property[] = [];
  public propertyId: number = 0;
  public declarations: Model.DeclarationHistorySearchResponse[] = [];
  public totalDeclarations: number = 0;
  public pageSize: number = 10;
  public pageIndex: number = 0;
  public startDate: Date | null = null;
  public endDate: Date | null = null;

  constructor(
    private readonly store: Store,
  ) { }
 
  public ngOnInit(): void {
    this.store.dispatch(clearFilter());
    this.store.select(selectDeclarations)
      .pipe(takeUntil(this.destroy$))
      .subscribe((declarations) => this.declarations = declarations);
    this.store.select(selectTotalDeclarations)
      .pipe(takeUntil(this.destroy$))
      .subscribe((totalDeclarations) => this.totalDeclarations = totalDeclarations);
    this.store.select(selectProperties)
      .pipe(takeUntil(this.destroy$))
      .subscribe((properties) => {
        if (!this.properties.length && properties.length)
        {
          this.store.dispatch(filterUpdated({
            update : {
              anzcoPropertyIds: properties.map(property => property.id),
              pageSize: this.pageSize,
              pageIndex: this.pageIndex,
              startDate: this.startDate,
              endDate: this.endDate,
            },
          }));
        }
        this.properties = properties;
      });
    this.store.select(selectDeclarationsFilter)
      .pipe(takeUntil(this.destroy$))
      .subscribe((query) => {
        this.propertyId = query.anzcoPropertyIds.length && query.anzcoPropertyIds.length === 1 ?
          query.anzcoPropertyIds[0] :
          0;
        this.startDate = query.startDate;
        this.endDate = query.endDate;
        this.pageSize = query.pageSize;
        this.pageIndex = query.pageIndex;
        this.store.dispatch(loadDeclarations({
          anzcoPropertyIds: this.propertyId !== 0 ?
            [this.propertyId] :
            this.properties.map(property => property.id),
          pageSize: this.pageSize,
          pageIndex: this.pageIndex,
          startDate: this.startDate,
          endDate: this.endDate,
        }));
    });

    this.store.dispatch(loadProperties());
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public selectProperty(propertyId: number | null): void {
    this.pageIndex = 1;
    this.store.dispatch(filterUpdated({
      update: { anzcoPropertyIds: propertyId ? [<number>propertyId] : [] }
    }));
  }

  public updateFilter(update: Partial<Model.DeclarationsHistoryQuery >): void {
    this.pageIndex = 1;
    this.store.dispatch(filterUpdated({ update }));
  }

  public onPage(pageEvent: PageEvent): void {
    this.store.dispatch(filterUpdated({ update : {
      pageIndex: pageEvent.previousPageIndex === pageEvent.pageIndex ? 0 : pageEvent.pageIndex, 
      pageSize: pageEvent.pageSize,
    }}));
  }

  public clearFilters(): void {
    this.store.dispatch(clearFilter());
  }
}