<app-help></app-help>
<div class="header-container">
  <app-header (menuClicked)="onMenuClicked()"></app-header>
  <app-menu
    *ngIf="shouldDisplayUserMenu | async"
    [class.is-hidden]="shouldHideMenu | async">
  </app-menu>
  <app-outstanding-bobby-declarations *ngIf="(outstandingBobbyDeclarations | async)" class="bcackground-section-no-padding">
  </app-outstanding-bobby-declarations>
</div>

<div class="content">
  <div>
    <router-outlet (deactivate)="onDeactivate()"></router-outlet>
  </div>
  <app-footer></app-footer>
</div>
