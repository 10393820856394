import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdviceStatus } from 'src/app/miscellaneous/enums/advice-status.enum';
import { environment } from 'src/environments/environment';
import { Model } from '.';
import { HttpDateFormatter } from '../../miscellaneous/http-date-formatter';
import { BookingType } from 'src/app/miscellaneous/enums/booking-type.enum';

@Injectable({
  providedIn: 'root'
})
export class AdviceService {
  private getAdvicesUrl: string = environment.apiUrl + "/api/v2/advices";
  private advicesFilterOptionsUrl: string = environment.apiUrl + "/api/v2/advices/available-filter";

  constructor(
    private readonly http: HttpClient,
  ) { }

  public getRecentAdvices(): Observable<Model.PotentialOutageResponse<Model.PagedAdviceResponse>> {
    return this.getAdvices(
      {
        pageNumber: 1,
        pageSize: 3,
        adviceStatuses: [
          AdviceStatus.Processing,
          AdviceStatus.ProposedPricing,
          AdviceStatus.Invoiced,
        ],
      } as Model.AdvicesQuery);
  }

  public getAvailableFilterOptions(): Observable<Model.AdvicesFilterOptions> {
    return this.http.get<Model.AdvicesFilterOptions>(this.advicesFilterOptionsUrl)
  }

  public searchAdvices(query: Model.AdvicesQuery): Observable<Model.PotentialOutageResponse<Model.PagedAdviceResponse>> {
    return this.getAdvices(query);
  }

  private getAdvices(advicesQuery: Model.AdvicesQuery): Observable<Model.PotentialOutageResponse<Model.PagedAdviceResponse>> {
    // format for HTTP transport
    const queryParams = {
      ...advicesQuery,
      startDate: HttpDateFormatter.format(advicesQuery.startDate, false),
      endDate: HttpDateFormatter.format(advicesQuery.endDate, false),
      bookingTypes: [
        BookingType.BobbyCalfKill,
        BookingType.ContractKill,
        BookingType.PaddockKill,
        BookingType.ScheduleKill,
      ],
    };

    // create a query params object without null values. e.g. {a: null, b:"1234"} => ?b=1234 instead of ?a=null&b=1234
    Object.keys(queryParams).forEach(key => !queryParams[key] ? delete queryParams[key] : {});
    const params: HttpParams = new HttpParams({ fromObject: { ...queryParams } as any });
    
    return this.http.get<Model.PotentialOutageResponse<Model.PagedAdviceResponse>>(this.getAdvicesUrl, { params })
  }
}