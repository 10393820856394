import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from "rxjs/operators";
import { NewsType, NewsTypeEnum } from 'src/app/miscellaneous/enums/news-type.enum';
import { newsItemRouteLinkPrefix, newsOverviewRoute } from '../constants';
import { Model } from '../interfaces';
import { setNewsFeedType, loadNewsFeed, loadNewsOverview } from '../state/actions';
import { selectCurrentNewsFeed, selectInitialNewsFeed } from '../state/selectors';

@Component({
  selector: 'app-news-feed-shell',
  templateUrl: './news-feed-shell.component.html',
  styleUrls: ['./news-feed-shell.component.scss']
})
export class NewsFeedShellComponent implements OnInit, OnDestroy {
  @ViewChild("top") private scrollElement: ElementRef;
  public newsFeed: Model.NewsFeed;
  public newsItemRouteLinkPrefix: string = newsItemRouteLinkPrefix;
  public newsRoute: string = "/" + newsOverviewRoute;
  public pageSize: number = 5;
  public selectedCategory: string = null;
  public newsTypeEnum: NewsTypeEnum;
  private readonly destroy$ = new Subject();

  constructor(private readonly route: ActivatedRoute, private readonly store: Store) { }

  ngOnInit(): void {
    this.route
      .paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => {
        const newsTypeParameter = new Number(params.get('newsType')).valueOf();
        this.newsTypeEnum = NewsTypeEnum.parseNumber(newsTypeParameter);
        this.store.dispatch(setNewsFeedType(this.newsTypeEnum));
        this.store.dispatch(loadNewsOverview());
      });

    this.store.select(selectCurrentNewsFeed)
      .pipe(takeUntil(this.destroy$))
      .subscribe(feed => {
        this.newsFeed = feed;
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onPage(pageEvent: PageEvent) {
    this.loadNewsFeed(pageEvent.pageIndex * this.pageSize, this.newsFeed.feedType, this.selectedCategory);
    this.scrollElement.nativeElement.scrollIntoView({
      behavior: 'smooth'
    });
  }

  public onSelectCategory(category: string) {
    this.selectedCategory = category;
    this.loadNewsFeed(0, this.newsFeed.feedType, category);
  }

  private loadNewsFeed(offset: number, newsType: NewsType, category: string) {
    const query: Model.NewsFeedQuery = {
      offset: offset,
      newsType: newsType,
      limit: this.pageSize,
      category: category
    };

    this.store.dispatch(loadNewsFeed(query));
  }
}
