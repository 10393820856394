import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavigationService } from 'src/app/services/navigation.service';
import { selectOutstandingBobbyDeclarations } from 'src/app/user/state/selector';

@Component({
  selector: 'app-outstanding-bobby-declarations',
  templateUrl: './outstanding-bobby-declarations.component.html',
  styleUrls: ['./outstanding-bobby-declarations.component.scss'],
})
export class OutstandingBobbyDeclarationsComponent implements OnInit {
  private readonly destroy$ = new Subject();
  public outstandingBobbyDeclarations: number = 0;

  constructor(
    private readonly store: Store,
    private readonly navigationService: NavigationService,
  ) { }

  public ngOnInit(): void {
    this.store
      .select(selectOutstandingBobbyDeclarations)
      .pipe(takeUntil(this.destroy$))
      .subscribe(amount => this.outstandingBobbyDeclarations = amount);
  }

  public fillOutDeclarations(): void {
    this.navigationService.pickupDeclarations();
  }
}