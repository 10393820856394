import { NgModule } from '@angular/core';
import { CreateUserComponent } from './create-user.component';
import { UserFormComponent } from './user-form/user-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule, LoaderModule } from '../../miscellaneous';
import { CommonModule } from '@angular/common';
import { PropertyRoleFormComponent } from './property-role-form/property-role-form.component';
import { FORM_DEBOUNCE_TIME_MS } from './constants';

@NgModule({
  imports: [
    AngularMaterialModule,
    CommonModule,
    ReactiveFormsModule,
    LoaderModule,
  ],
  exports: [],
  declarations: [
    CreateUserComponent,
    UserFormComponent,
    PropertyRoleFormComponent,
  ],
  providers: [{ provide: FORM_DEBOUNCE_TIME_MS, useValue: 500 }],
})
export class CreateUserModule {}
