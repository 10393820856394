import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import {
  selectShouldDisplayReportsMenu,
  selectShouldDisplayBobbyMenu,
  selectShouldDisplayComplianceMenu,
} from '../../user';
import { closeMenu } from 'src/app/user/state/actions';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  private readonly destroy$ = new Subject();
  public displayReportsMenu: boolean;
  public displayBobbyMenu: boolean;
  public displayComplianceMenu: boolean;

  constructor(
    public readonly nav: NavigationService,
    private readonly store: Store,
  ) {}

  public ngOnInit(): void {
    this.store.select(selectShouldDisplayReportsMenu)
      .pipe(takeUntil(this.destroy$))
      .subscribe((displayReportsMenu) => this.displayReportsMenu = displayReportsMenu);
    this.store.select(selectShouldDisplayBobbyMenu)
      .pipe(takeUntil(this.destroy$))
      .subscribe((displayBobbyMenu) => this.displayBobbyMenu = displayBobbyMenu);
    this.store.select(selectShouldDisplayComplianceMenu)
      .pipe(takeUntil(this.destroy$))
      .subscribe((displayComplianceMenu) => this.displayComplianceMenu = displayComplianceMenu);
  }

  public goHome(): void {
    this.store.dispatch(closeMenu());
    this.nav.home();
  }

  public goProcessing(): void {
    this.store.dispatch(closeMenu());
    this.nav.processing();
  }

  public goBobbyCalves(): void {
    this.store.dispatch(closeMenu());
    this.nav.bobbyCalves();
  }

  public goCompliance(): void {
    this.store.dispatch(closeMenu());
    this.nav.compliance();
  }

  public goReporting(): void {
    this.store.dispatch(closeMenu());
    this.nav.reporting();
  }

  public more(): void {
    this.store.dispatch(closeMenu());
    this.nav.more();
  }
}