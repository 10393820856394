import { Component } from '@angular/core';
import { CommandBecomeSelf } from '../../../admin-features';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import {
  loadUser,
  selectShouldDisplayAdminMenu,
  selectUserName,
} from '../../../user';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-user',
  templateUrl: './admin-user.component.html',
  styleUrls: ['../header.component.scss'],
})
export class AdminUserComponent {
  public username: Observable<string>;
  public shouldHideBecomeSelf: Observable<boolean>;

  constructor(
    private readonly msalInstance: MsalService,
    private readonly store: Store,
    private readonly commandBecomeSelf: CommandBecomeSelf,
    private readonly router: Router
  ) {
    this.username = this.store.select(selectUserName);
    this.shouldHideBecomeSelf = this.store.select(selectShouldDisplayAdminMenu);
  }

  public async becomeSelf(): Promise<void> {
    await this.commandBecomeSelf.execute();
    this.store.dispatch(loadUser());
    this.router.navigate(['']);
  }

  public logout(): void {
    this.msalInstance.logoutRedirect({
      postLogoutRedirectUri: environment.webUrl,
    });
  }
}
