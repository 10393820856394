<mat-card
  *ngFor="let declaration of declarations"
  class="card-border">

  <mat-card-content>
    <div class="header">
      <div class="emphasis front-label">{{ declaration.propertyName }}</div>
      <div>{{ declaration.pickupDate | date: "dd/MM/yyyy" }}</div>
    </div>
    <div class="rows">
      <div>
        <span><strong> Declared Tally</strong></span>
        <div>{{ declaration.declaredTally === null ? 'Paper Declaration' : declaration.declaredTally }}</div>
      </div>
      <div>
        <span><strong> Picked Up Tally</strong></span>
        <div>{{ declaration.pickedUpTally !== null ? declaration.pickedUpTally : '-' }}</div>
      </div>
      <div>
        <span><strong> Last Fed At</strong></span>
        <div>{{ declaration.timeOfLastFeed !== null && declaration.declaredTally ? declaration.timeOfLastFeed : '-' }}</div>
      </div>
      <div>
        <span><strong> Recorded By</strong></span>
        <div>{{ declaration.recordedBy !== null ? declaration.recordedBy : '-' }}</div>
      </div>
      <div>
        <span><strong> Rejected</strong></span>
        <div>{{ declaration.rejected !== null ? declaration.rejected : '-' }}</div>
      </div>
      <div>
        <span><strong> Reject Reason</strong></span>
        <div>{{ declaration.rejectReasons !== null ? declaration.rejectReasons : '-' }}</div>
      </div>
      <div>
        <span><strong> Driver</strong></span>
        <div>{{ declaration.transportDriver !== null ? declaration.transportDriver : '-' }} {{ declaration.transportDriver && declaration.transportName ? '(' + declaration.transportName + ')' : '' }}</div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
