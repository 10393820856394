<div class="main-element" data-cy="new-requests-page-shell">
    <h1>New Requests</h1>

    <table size="sm" data-cy=newRequestsTable>
        <thead>
            <tr>
                <th class="medium-table-column" cy-data=userNameHeading>User Name</th>
                <th>Email</th>
                <th class="medium-table-column" cy-date=producerCodeHeading>Producer Code</th>
                <th class="short-table-column" cy-date=roleHeading>Role</th>
                <th class="medium-table-column" cy-date=tradeNameHeading>Trade Name</th>
                <th class="wide-table-column"cy-date=requestedHeading>Requested</th>
                <th class="medium-table-column" cy-date=assignedToHeading>Assigned To</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let adminTask of adminTasks | async; index as i"
                (click)="editUserRequest(adminTask.id)">
                <td class="medium-table-column">{{adminTask.userName}}</td>
                <td>{{adminTask.email}}</td>
                <td class="medium-table-column">{{adminTask.producerCode}}</td>
                <td class="short-table-column">{{adminTask.role}}</td>
                <td class="medium-table-column">{{adminTask.producerName}}</td>
                <td class="wide-table-column">{{adminTask.created}}</td>
                <td class="medium-table-column">{{adminTask.assignedTo}}</td>
            </tr>
        </tbody>
    </table>
</div>