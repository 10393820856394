import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthError } from '@azure/msal-common';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { RaygunWrapper } from '../app.raygun.setup';
import { resetSpinner } from '../features';
import { ErrorSnackBarComponent } from './error-snack-bar/error-snack-bar.component';

const MsalAccessDeniedErrorCode = 'access_denied';
const MsalLoginCancelledErrorCode = 'AADB2C90091'; // User canceled the operation

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private store: Store;

  constructor(
    private notificationBar: MatSnackBar,
    private readonly zone: NgZone,
    private readonly injector: Injector
  ) {
    // Prevents Angular circular dependency issues
    setTimeout(() => (this.store = this.injector.get(Store)));
  }

  public handleError(error: Error) {
    if (environment.production) {
      RaygunWrapper.handleError(error);
    }

    console.error(error);

    this.disableLoadingSpinner();

    // BUG 37639: Don't show the error when the user cancels their login
    if (
      error instanceof AuthError &&
      error.errorCode === MsalAccessDeniedErrorCode &&
      error.message.includes(MsalLoginCancelledErrorCode)
    ) {
      return;
    }

    this.notifyUser(error);
  }

  private disableLoadingSpinner(): void {
    this.store.dispatch(resetSpinner());
  }

  private notifyUser(error: Error) {
    // Runs the snackbar inside Angular Zone in order to fix behavior issues such as empty duplicates appearing.
    // see: https://github.com/angular/components/issues/9875
    this.zone.run(() => {
      this.notificationBar.openFromComponent(ErrorSnackBarComponent, {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: 'warning-panel',
      });
    });
  }
}
