import { createFeatureSelector, createSelector } from '@ngrx/store';
import { pickupDeclarationsReducerKey } from './reducer';
import { Model, Projection } from '../models';

const getPickupDeclarationsState = createFeatureSelector<Model.DeclarationState>(pickupDeclarationsReducerKey);

export const selectShouldDisplayPropertySelection = createSelector(
  getPickupDeclarationsState,
  (state: Model.DeclarationState) =>
    state.showPropertySelection &&
    state.completed.filter(completed => !completed.hasBeenPickedUp).length +
      state.expected.filter(expected => !expected.hasBeenPickedUp).length > 1,
);

export const selectErrored = createSelector(
  getPickupDeclarationsState,
  (state: Model.DeclarationState) => state.errored,
);

export const selectSelectedProperty = createSelector(
  getPickupDeclarationsState,
  (state: Model.DeclarationState) => state.selectedProperty,
);

export const selectIsWelfareDeclared = createSelector(
  getPickupDeclarationsState,
  (state: Model.DeclarationState) => state.declaredWelfare,
);

export const selectProperties = createSelector(
  getPickupDeclarationsState,
  (state: Model.DeclarationState) => state.expected
    .filter(expected => !expected.hasBeenPickedUp)
    .map(expected => {
      const property: Projection.OpenDeclarationProperties = {
        anzcoPropertyId: expected.anzcoPropertyId,
        bookingId: expected.bookingId,
        propertyName: expected.propertyName,
        pickupDate: expected.pickupDate,
        status: 1,
      };

      return property;
    })
    .sort((expectedA, expectedB) => expectedA.pickupDate > expectedB.pickupDate ? 1 : -1)
    .concat(state.completed
      .filter(completed => !completed.hasBeenPickedUp)
      .map(completed => {
        const property: Projection.OpenDeclarationProperties = {
          anzcoPropertyId: completed.anzcoPropertyId,
          bookingId: completed.bookingId,
          propertyName: completed.propertyName,
          pickupDate: completed.pickupDate,
          status: 2,
        };

        return property;
      })),
);

export const selectValidCalves = createSelector(
  getPickupDeclarationsState,
  (state: Model.DeclarationState) =>
    state.selectedProperty === null  ||
    state.selectedProperty.tally === null ||
    state.selectedProperty.tally >= 0,
);

export const selectValidContactNumber = createSelector(
  getPickupDeclarationsState,
  (state: Model.DeclarationState) =>
    state.selectedProperty === null ||
    state.selectedProperty.contactNumber === null ||
    state.selectedProperty.contactNumber.length > 0,
);

export const selectValidRecordedBy = createSelector(
  getPickupDeclarationsState,
  (state: Model.DeclarationState) =>
    state.selectedProperty === null ||
    state.selectedProperty.recordedBy === null ||
    state.selectedProperty.recordedBy.length > 0,
);

export const selectValidDeclaration = createSelector(
  getPickupDeclarationsState,
  (state: Model.DeclarationState) =>
    state.selectedProperty &&
    state.selectedProperty.tally >= 0 &&
    state.selectedProperty.contactNumber &&
    state.selectedProperty.contactNumber.length > 0 &&
    state.selectedProperty.recordedBy &&
    state.selectedProperty.recordedBy.length > 0 &&
    (state.selectedProperty.timeOfLastFeedHours > 0 ||
      state.selectedProperty.tally === 0) &&
    (state.selectedProperty.timeOfLastFeedMinutes >= 0 ||
      state.selectedProperty.tally === 0)
);

export const selectIsReadOnly = createSelector(
  getPickupDeclarationsState,
  (state: Model.DeclarationState) => state.isReadOnly,
);