import { Component, Input } from "@angular/core";
import { Model } from "../interfaces";

@Component({
    selector: "app-declarations-table",
    templateUrl: "declarations-table.component.html",
    styleUrls: ["declarations-table.component.scss"]
})
export class DeclarationsTableComponent {
    @Input() public declarations: Model.DeclarationHistorySearchResponse[] = [];

    public readonly tableColumns: string[] = [
        "propertyName",
        "pickupDate",
        "declaredTally",
        "pickedUpTally",
        "timeOfLastFeed",
        "recordedBy",
        "rejected",
        "rejectReasons",
        "transportDriver",
    ];
}