<div class="compliance-certified-tile" (click)="goCompliance()">
  <mat-card>
    <div>
      <img src="../../../assets/images/nzfai_big.jpg" alt="nzfap image" />
      <div>
        <span>Click here to view Certification details</span>
      </div>
    </div>
    <div>
      <span class="current-status">Current Status:</span>
      <p class="certified">Certified</p>
    </div>
  </mat-card>
</div>
