import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectShouldDisplayAdminUserMenu } from '../../user';
import { Router } from '@angular/router';
import { homeRoute } from '../home';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() public menuClicked = new EventEmitter<MouseEvent>();
  public logoClass: string;
  public shouldDisplayAdminUserMenu: Observable<boolean>;
  public shouldDisplayLogout: boolean;

  constructor(
    private readonly msalInstance: MsalService,
    private readonly router: Router,
    private readonly store: Store
  ) {}

  public ngOnInit(): void {
    this.shouldDisplayAdminUserMenu = this.store.select(
      selectShouldDisplayAdminUserMenu
    );

    this.shouldDisplayLogout =
      this.isOnRegisterPage() || this.isOnNoAccessPage();

    if (!this.isOnRegisterPage()) {
      this.logoClass = 'active-logo';
    }
  }

  public logout(): void {
    this.msalInstance.logoutRedirect({
      postLogoutRedirectUri: environment.webUrl,
    });
  }

  public navigateHome(): void {
    if (!this.isOnRegisterPage()) {
      this.router.navigate([homeRoute]);
    }
  }

  private isOnRegisterPage(): boolean {
    return window.location.pathname.includes('/register');
  }

  private isOnNoAccessPage(): boolean {
    return window.location.pathname.includes('/no-properties');
  }
}
