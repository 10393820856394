<mat-card>
  <mat-card-title>
  </mat-card-title>
  <mat-card-content>
    <div>
      <label>
        <strong>Calf Tally</strong>
      </label>
      <div>
        No request submitted*
      </div>
    </div>
    <div>
      <label>
        <strong>Pick-up Date</strong>
      </label>
      <div>
        {{ date }}
      </div>
    </div>
    <div class="panel-info-text">
      *No request was submitted for this pick-up Date. If this is incorrect, please contact your Bobby Calf Representative
    </div>
  </mat-card-content>
</mat-card>