import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  complianceErrored,
  loadComplianceLines,
  loadComplianceLinesCompleted,
} from './actions';
import { HttpClient } from '@angular/common/http';
import { Model } from '../interfaces';
import { environment } from 'src/environments/environment';

const complianceUrl = (...segments: any[]) =>
  [`${environment.apiUrl}/api/compliance`, ...segments].join('/');

@Injectable()
export class ComplianceEffects {
  constructor(
    private readonly actions: Actions,
    private readonly http: HttpClient,
  ) { }

  public loadCompliances$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadComplianceLines),
      switchMap(() =>
        this.http
          .get<Model.PotentialOutageResponse<Model.ComplianceLineResponse[]>>(complianceUrl('compliance-lines'))
          .pipe(map(res => loadComplianceLinesCompleted(res)))
      ),
    ),
  );

  public loadPropertiesErrorEffect$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadComplianceLinesCompleted),
      map((res) => {
        return complianceErrored({ errored: res.errored })}
      ),
    ),
  );
}