import { NgModule } from '@angular/core';
import { FormatDatePipe } from './format-date.pipe';
import { DatePipe } from '@angular/common';

@NgModule({
  imports: [],
  exports: [FormatDatePipe],
  declarations: [FormatDatePipe],
  providers: [DatePipe],
})
export class SharedPipesModule {}
