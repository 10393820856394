import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  distinctUntilChanged,
  startWith,
  map,
} from 'rxjs/operators';
import {
  loadPointsStarted,
} from './state/actions';
import { Selector } from './state/selector';
import { Model } from './interfaces';

@Component({
  selector: 'app-advantage-tile',
  templateUrl: './advantage-tile.component.html',
  styleUrls: ['./advantage-tile.component.scss'],
})
export class AdvantageTileComponent implements OnInit {
  public points$: Observable<Model.AdvantagePoints>;
  public isLoading$: Observable<boolean>;

  constructor(
    private readonly store: Store,
    private readonly selector: Selector
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(loadPointsStarted());

    this.points$ = this.store.select(this.selector.selectPoints);

    this.isLoading$ = this.points$.pipe(
      map((points) => !points),
      startWith(true),
      distinctUntilChanged()
    );
  }
}
