<div class="default-container">
    <mat-card class="default-card">
        <div> 
            <mat-card-title cy-data=regestrationHeading><h2>{{title}}</h2></mat-card-title>
            <mat-card-content>
                <p>Thank you for registering for the ANZCO Producer Portal.</p>
                <p>{{pendingMessage}}</p>
                <br/>
                <p>Need help? Click <a routerLink="{{helpRoute}}" cy-data=helpLink>here</a>.</p>
            </mat-card-content>
            <mat-card-actions>
                <button mat-raised-button color="accent" (click)="logout()" cy-data=logoutBtn>back to login page</button>
            </mat-card-actions>
        </div>
    </mat-card> 
</div>