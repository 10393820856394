import { Component, OnInit } from '@angular/core';
import { Model } from '../models';
import { Store } from '@ngrx/store';
import { selectIsReadOnly, selectSelectedProperty } from '../state/selectors';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { toggleDeclarationWelfare } from '../state/actions';
import { FormatDatePipe } from 'src/app/miscellaneous/pipes/format-date.pipe';

@Component({
  selector: 'app-pickup-declaration-summary',
  templateUrl: './pickup-declaration-summary.component.html',
  styleUrls: ['./pickup-declaration-summary.component.scss'],
})
export class PiclupDeclarationSummaryComponent implements OnInit {
  private readonly destroy$ = new Subject();
  public declaration: Model.SelectedBookingDeclaration;
  public confirmationRequired: boolean = true;

  constructor(
    private readonly store: Store,
    private readonly dateFormatter: FormatDatePipe,
  ) { }

  public ngOnInit(): void {
    this.store
      .select(selectSelectedProperty)
      .pipe(takeUntil(this.destroy$))
      .subscribe(propertyDeclaration => this.declaration = propertyDeclaration);
    this.store
      .select(selectIsReadOnly)
      .pipe(takeUntil(this.destroy$))
      .subscribe(isReadOnly => this.confirmationRequired = !isReadOnly);
  }

  get lastFedTime(): string {
    const result: string =
      (this.declaration.timeOfLastFeedHours < 10 ? '0' + this.declaration.timeOfLastFeedHours : this.declaration.timeOfLastFeedHours.toString()) +
      ':' +
      (this.declaration.timeOfLastFeedMinutes < 10 ? '0' + this.declaration.timeOfLastFeedMinutes : this.declaration.timeOfLastFeedMinutes.toString());

    return result;
  }

  public get date(): string {
    const pickupDateStr = this.dateFormatter.transform(this.declaration.pickupDate, 'dwdm');
    return pickupDateStr;
  }

  public declareWelfare(): void {
    this.store.dispatch(toggleDeclarationWelfare());
  }
}