import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Model } from "../interfaces";
import moment from "moment";
import { HttpDateFormatter } from "src/app/miscellaneous/http-date-formatter";
import { BlobSaver } from "src/app/miscellaneous/file-downloader";

@Injectable({ providedIn: "root" })
export class CommandGetCsv {
    constructor(
        private readonly http: HttpClient,
    ) {}

    public async Retrieve(advicesQuery: Model.AdvicesQuery): Promise<void> {
        const res = await this.RequestCsvReport(advicesQuery);
        BlobSaver.saveFile(res, "ProcessingData-" + moment().format("YYYY-MM-DD-HH-mm-ss") +".csv");
    }

    private async RequestCsvReport(advicesQuery: Model.AdvicesQuery): Promise<Blob> {
        const params: any = {
            stockTypes: advicesQuery.stockTypes ?? [],
            adviceStatuses: advicesQuery.adviceStatuses ?? [],
        };

        if (advicesQuery.startDate) {
            params.startDate = HttpDateFormatter.format(advicesQuery.startDate, false);
        }
        if (advicesQuery.endDate) {
            params.endDate = HttpDateFormatter.format(advicesQuery.endDate, false);
        }
        if (advicesQuery.propertyId) {
            params.propertyId = advicesQuery.propertyId;
        }
        const pdfBlob = await this.http
            .get(
                environment.apiUrl + "/api/documents/all-csv",
                {
                    responseType: "blob",
                    params,
                })
            .toPromise();

        return pdfBlob as Blob;
    }
}