<div class="main-element">
    <app-edit-user [title]="'New Request'"></app-edit-user>

    <div class="button-panel">
        <button class="mat-raised-button" (click)="cancel()">Cancel</button>
        <div>
                <button *ngIf="!editUserConfirmed"
                        class="mat-raised-button decline-button"
                        (click)="decline()" cy-data=declineBtn>Decline</button>
                <button *ngIf="adminTaskDisabled && !editUserConfirmed"
                        class="mat-raised-button mat-primary"
                        (click)="assign()" cy-data=updateBtn>Update </button>
                <button *ngIf="!adminTaskDisabled"
                        [disabled]="!validToConfirm"
                        class="mat-raised-button mat-primary"
                        (click)="confirm()" cy-data=confirmBtn>Confirm</button>
                <button *ngIf="editUserConfirmed"
                        class="mat-raised-button mat-primary"
                        (click)="approve()" cy-data=approveBtn>Approve</button>
        </div>
    </div>
</div>