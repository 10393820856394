import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { newsFeedRouteLinkPrefix } from '../constants';
import { Model } from '../interfaces';
import { resetNewsFeed } from '../state/actions';

@Component({
  selector: 'app-news-feed-preview',
  templateUrl: './news-feed-preview.component.html',
  styleUrls: ['./news-feed-preview.component.scss']
})
export class NewsFeedPreviewComponent implements OnInit{
  @Input() public newsItems: Model.NewsItemSummary[];
  @Input() public title: string;
  @Input() public displayFeedLink: boolean;
  @Input() public verticalDisplay: boolean;
  private newsFeedLink: string;

  constructor(private readonly store: Store, private readonly router: Router){}

  ngOnInit(): void {
    this.newsFeedLink = newsFeedRouteLinkPrefix + this.newsItems[0]?.newsType;
  }

  onViewAll(): void {
    this.store.dispatch(resetNewsFeed());
    this.router.navigateByUrl(this.newsFeedLink);
  }
}
