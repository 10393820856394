import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { adminRoute } from '../admin-features/index';
import { homeRoute, registerRoute, noPropertiesRoute } from '../features/index';
import { Model, selectUser } from '../user/index';

@Component({
  template: ''
})
export class DefaultNavigationComponent implements OnInit, OnDestroy {
  constructor(
    private readonly router: Router,
    private readonly store: Store,
  ) { }
  
  private readonly _destroying$ = new Subject();

  ngOnInit(): void {
    this.store.select(selectUser)
      .pipe(
        filter(user => !!user), // only act on fully fledged users (i.e. filter null)
        takeUntil(this._destroying$))
      .subscribe((user) => this.routeUserOnChange(user));
  }
  
  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  private routeUserOnChange(user: Model.User): void {
    if (!user.isActive) {
      if (!user.hasRegistration || user.hasRegistrationPendingApproval) {
        this.router.navigate([registerRoute]);
      }
      else {
        this.router.navigate([noPropertiesRoute]);
      }
    }
    else if (user.isUser) {
      if (user.hasPropertyAccess) {
        this.router.navigate([homeRoute]);
      }
      else {
        this.router.navigate([noPropertiesRoute]);
      }    
    }
    else {
      this.router.navigate([adminRoute]);
    }
  }
}