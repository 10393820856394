import { Injectable } from "@angular/core";
import { Model } from "../admin.interfaces";
import { environment } from '../../../environments/environment';
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { adminSearchUsersStarted, adminSearchUsersCompleted } from "../state/actions";

@Injectable({ providedIn: 'root' })
export class CommandSearchUsers {
    constructor(
        private readonly store: Store,
        private readonly http: HttpClient,
    ) {}

    public async execute(searchTerm: string): Promise<void> {
        this.store.dispatch(adminSearchUsersStarted());
        const userSearchResults: Model.UserSearchResult[] = await this.searchUsers(searchTerm);
        this.store.dispatch(adminSearchUsersCompleted({ userSearchResults }));
    }

    private async searchUsers(searchTerm: string): Promise<Model.UserSearchResult[]> {
        const params = { searchTerm };

        return await this.http
            .post<Model.UserSearchResult[]>(environment.apiUrl + "/api/admin/search-users", params)
            .toPromise();
    }
}