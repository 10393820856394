<div class="default-container" data-cy="processing-page-shell">
  <div class="headline">
    <h2>Certifications</h2>
  </div>

  <div>
    <div >
      <app-compliance-table [compliances]="compliances"></app-compliance-table>
      <app-compliance-cards [compliances]="compliances"></app-compliance-cards>
      <div *ngIf="compliances.length === 0" class="no-results">Your properties do not have any current NZFAP certifications</div>
    </div>

  </div>
</div>