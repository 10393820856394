<mat-card>
  <mat-card-title>
    <span role="heading">Awaiting Approval</span>
  </mat-card-title>
  <mat-card-content>
    <div>
      <label>
        <strong>Calf Tally</strong>
      </label>
      <div>
        <strong>{{ pickup.booking.requestedNumOfCalves }}</strong>
      </div>
    </div>
    <div>
      <label>
        <strong>Pick-up Date</strong>
      </label>
      <div>
        {{ date }}
      </div>
    </div>
    <div>
      <label>
        <strong>Requested By</strong>
      </label>
      <div>
        {{ pickup.booking.requestedBy }}
      </div>
    </div>
  </mat-card-content>
</mat-card>
