<div class="footer">
  <div class="footer-content" data-cy="footer-links">
    <div class="footer-align footer-text">
      <img class="img-logo" src="../../assets/images/mainLogo.PNG" />
      <div class="store">
        <label class="store-text"
          >Download the ANZCO Livestock Mobile App:
        </label>
        <div class="store-align">
          <a
            class="store-align"
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.anzcolivestock&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <img
              class="google-img"
              alt="Get it on Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"/>
            </a>
          <a
            class="store-align"
            target="_blank"
            href="https://apps.apple.com/nz/app/anzco-livestock/id1614528578?itsct=apps_box_badge&amp;itscg=30200">
            <img
              class="apple-img"
              src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1650844800&h=e4e87ee9de9d6ec78e4b563c12c0946d"
              alt="Download on the App Store" />
          </a>
        </div>
      </div>
    </div>
    <div class="spacer"></div>
    <div class="footer-align">
      <div class="anzco-footer footer-text">
        <label class="anzco-footer">@{{ currentYear }} ANZCO Foods</label>
        <label class="anzco-footer">{{ releaseVersion }}</label>
      </div>
      <div class="footer-links footer-text">
        <a
          class="mat-but"
          mat-button
          routerLink="/legal"
          data-cy="legal">
          Legal
        </a>
        <a
          class="mat-but"
          mat-button
          routerLink="/frequently-asked-questions"
          data-cy="frequently-asked-questions">
          FAQs
        </a>
        <a class="mat-but" mat-button (click)="help()" data-cy="contact-us">
          Contact Us
        </a>
      </div>
    </div>
  </div>
  <div class="footer-spacer"></div>
  <div class="footer-cow-img" data-cy="footer">
    <img
      class="image-cow-left-line"
      src="../../assets/images/WhiteLineLambCow.png"
      alt="footer image" />
    <img
      class="image-cow"
      src="../../assets/images/GrassLambCowWhite.png"
      alt="footer image" />
  </div>
</div>
