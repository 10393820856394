<div class="advantage-tile" [appLoader]="{show: isLoading$ | async}">
  <a [routerLink]="['/advantage-redirect']" target="_blank" data-cy="anzcoAdvantageCard">
    <mat-card id="anzco-advantage">
      <img src="../../../assets/images/Advantage-Logo.png" alt="advantage image" />
      <div *ngIf="points$ | async as points">
        <div *ngIf="points.showPoints" class="points">
          <label>Points: </label>
          <label>{{ points.value.toLocaleString() }}</label>
        </div>
        <p *ngIf="points.showPoints">Redeem your rewards here.</p>
        <p *ngIf="!points.showPoints">
          View your points and redeem rewards here.
        </p>
      </div>
    </mat-card>
  </a>
</div>
