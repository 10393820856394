<mat-card
  *ngFor="let compliance of compliances"
  class="card-border"
  [class.applied-border]="compliance.status === -1"
  [class.withdrawn-border]="compliance.status === 1"
  [class.certified-border]="compliance.status === 2"
  [class.expires-soon-border]="compliance.status === 3"
  [class.not-certified-border]="
    compliance.status === 4 || compliance.status === 5
  "
>
  <mat-card-content>
    <div *ngIf="compliance.status === 3" class="expires-soon-bar"></div>
    <div
      *ngIf="compliance.status === 4 || compliance.status === 5"
      class="not-certified-bar"
    ></div>
    <div class="rows">
      <div class="program-container">
        <div class="program-name">
          {{ compliance.complianceProgramDescription }}
        </div>
        <div *ngIf="compliance.status === -1" class="status applied">
          <p>{{ compliance.statusName }}</p>
        </div>
        <div *ngIf="compliance.status === 1" class="status withdrawn">
          <p>{{ compliance.statusName }}</p>
        </div>
        <div *ngIf="compliance.status === 2" class="status certified">
          <p>{{ compliance.statusName }}</p>
        </div>
        <div *ngIf="compliance.status === 3" class="status expires-soon">
          <p>{{ compliance.statusName }}</p>
        </div>
        <div *ngIf="compliance.status === 4" class="status not-certified">
          <p>{{ compliance.statusName }}</p>
        </div>
        <div *ngIf="compliance.status === 5" class="status-container">
          <div class="status not-certified">
            <p>{{ compliance.statusName }}</p>
          </div>
          <span class="not-certified-label">Corrective Actions Required</span>
        </div>
      </div>
      <div class="columns">
        <div>
          <span>Property</span>
          <div>{{ compliance.propertyName }}</div>
        </div>
        <div>
          <span>Certification Expiry</span>
          <div class="center-date">
            {{
              compliance.expiryDate
                ? (compliance.expiryDate | date : "dd/MM/yyyy")
                : "-"
            }}
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
