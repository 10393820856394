<div *ngIf="declaration" class="default-container">
  <div class="data-field">
    <mat-label>Property</mat-label>
    <mat-label>{{ declaration.propertyName }}</mat-label>
  </div>
  <div class="data-field">
    <mat-label>Pick-up Date</mat-label>
    <mat-label>{{ date }}</mat-label>
  </div>
  <div class="data-field">
    <mat-label>No of Calves</mat-label>
    <mat-label>{{ declaration.tally }}</mat-label>
  </div>
  <div *ngIf="declaration.tally" class="data-field">
    <mat-label>Last Fed at</mat-label>
    <mat-label>{{ lastFedTime }}</mat-label>
  </div>
  <div class="data-field">
    <mat-label>Contact Number</mat-label>
    <mat-label>{{ declaration.contactNumber }}</mat-label>
  </div>
  <div
    *ngIf="confirmationRequired"
    class="data-field">
    <mat-label>Completed By</mat-label>
    <mat-label>{{ declaration.recordedBy }}</mat-label>
  </div>
  <mat-label 
    *ngIf="confirmationRequired"
    class="welfare">
    All calves presented meet the animal welfare requirements and are suitable for transportation and for processing
  </mat-label>
  <mat-checkbox
    *ngIf="confirmationRequired"
    (change)="declareWelfare()">
    I confirm the above is true
  </mat-checkbox>
  <mat-label 
    *ngIf="!confirmationRequired"
    class="welfare">
    All calves presented meet the animal welfare requirements and are suitable for transportation and for processing
  </mat-label>
  <mat-checkbox
    *ngIf="!confirmationRequired"
    disabled
    [checked]="true">
    I confirm the above is true
  </mat-checkbox>
  <div
    *ngIf="!confirmationRequired"
    class="data-field">
    <mat-label>Completed By</mat-label>
    <mat-label>{{ declaration.recordedBy }}</mat-label>
  </div>
</div>