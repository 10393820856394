import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Model } from '../interfaces';
import { reportsFeatureKey } from './reducer';

const getReportsState = createFeatureSelector<Model.ReportsState>(reportsFeatureKey);

export const selectReportDownloadError = createSelector(
  getReportsState,
  (state: Model.ReportsState) => state.error
);

export const selectReportQuery = createSelector(
  getReportsState,
  (state: Model.ReportsState) => state.query
);

export const selectAvailableProperties = createSelector(
  getReportsState,
  (state: Model.ReportsState) => state.availableProperties
);

export const selectAvailablePropertyIds = createSelector(
  selectAvailableProperties,
  (properties: Model.AvailableProperty[]) => properties.map((property) => property.propertyId)
);

export const selectSelectedProperties = createSelector(
  getReportsState,
  selectAvailablePropertyIds,
  (state: Model.ReportsState, availablePropertyIds: number[]) => {
    const allPropertiesSelected = state.query.propertyIds.length === availablePropertyIds.length;
    const result: number[] = allPropertiesSelected ?
      [...availablePropertyIds, 0] :// 0 present for select all option
      state.query.propertyIds;

    return result;
  }
);

export const selectSelectedPropertiesList = createSelector(
  getReportsState,
  selectAvailablePropertyIds,
  (state: Model.ReportsState, availablePropertyIds: number[]) => {
    const allPropertiesSelected: boolean =
      state.query.propertyIds.length === 0 ||
      state.query.propertyIds.length === availablePropertyIds.length;
    const result: string =
      state.availableProperties.length === 1 ?
        state.availableProperties[0].propertyName :
        allPropertiesSelected ?
          'All Properties' :
          state.query.propertyIds
            .map((propertyId: number) => state.availableProperties.find((property) => property.propertyId === propertyId).propertyName)
            .join(', ');

    return result;
  }
);

export const selectSubmitValid = createSelector(
  selectReportQuery,
  (query: Model.FinancialReportQuery) => query.startDate <= query.endDate,
);
