import { Component, Input } from '@angular/core';
import { newsItemRouteLinkPrefix } from '../constants';
import { Model } from '../interfaces';

@Component({
  selector: 'app-news-thumbnail',
  templateUrl: './news-thumbnail.component.html',
  styleUrls: ['./news-thumbnail.component.scss']
})
export class NewsSummaryTileComponent{
  @Input() public newsItem: Model.NewsItemSummary;
  public newsItemRouteLinkPrefix: string = newsItemRouteLinkPrefix;
}
