import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  loadDeclarations,
  loadDeclarationsCompleted,
  loadProperties,
  loadPropertiesCompleted,
} from './actions';
import { HttpClient } from '@angular/common/http';
import { Model } from '../interfaces';
import { environment } from 'src/environments/environment';
import { HttpDateFormatter } from 'src/app/miscellaneous/http-date-formatter';

const apiUrl = (...segments: any[]) =>
  [`${environment.apiUrl}`, ...segments].join('/');

@Injectable()
export class DocumentsEffects {
  constructor(
    private readonly actions: Actions,
    private readonly http: HttpClient,
  ) { }

  public loadProperties$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadProperties),
      switchMap(() =>
        this.http
          .get<Model.PropertiesResponse>(apiUrl('api/bobby/all-time-properties'))
          .pipe(map((res) => {
            return loadPropertiesCompleted(res);
          }))
      ),
    ),
  );

  public loadDeclarations$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadDeclarations),
      switchMap((params) => {
          const httpParams = {
            anzcoPropertyIds: params.anzcoPropertyIds,
            pageSize: params.pageSize,
            pageIndex: params.pageIndex,
            startDate: HttpDateFormatter.format(params.startDate, false),
            endDate: HttpDateFormatter.format(params.endDate, false),
          };
          return this.http
            .post<Model.PagedDeclarationHistoryResponse>(
              apiUrl('api/bobby/declaration-history-search'),
              httpParams,
            )
            .pipe(map(res => loadDeclarationsCompleted(res)))
        }
      ),
    ),
  );
}