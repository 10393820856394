import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Selector } from "../state/selector";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { adminRoute, portalUsersRoute } from "../constants";
import { editUserFixRoles } from "../state/actions";
import { CommandPostEditUser } from "../commands/command-post-edit-user";
import { Model } from "../admin.interfaces";

@Component({
    selector: "app-admin-portal-user",
    templateUrl: "./admin-portal-user.component.html",
    styleUrls: ["../admin.scss"],
})
export class AdminPortalUserComponent implements OnInit, OnDestroy {
    public editUserConfirmed: boolean;

    private subscriptions: Subscription[];
    public validToConfirm: boolean;
    private propertyRoles: Model.PropertyRole[];

    constructor(
        private readonly router: Router,
        private readonly store: Store,
        private readonly selector: Selector,
        private readonly commandPostEditUser: CommandPostEditUser,
    ) { }

    public async ngOnInit(): Promise<void> {
        this.subscriptions = [
            this.store.select(this.selector.selectEditUserConfirmed)
                .subscribe((confirmed: boolean) => this.editUserConfirmed = confirmed),
            this.store.select(this.selector.selectEditUserPropertyRolesModels)
                .subscribe((propertyRoles: Model.PropertyRole[]) => this.propertyRoles = propertyRoles),
            this.store.select(this.selector.selectValidAdvantageEmail)
                .subscribe((validToConfirm: boolean) => this.validToConfirm = validToConfirm),
        ];
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
    }

    public confirm(): void {
        this.store.dispatch(editUserFixRoles());
    }

    public async approve(): Promise<void> {
        this.store.select(this.selector.selectEditUserModel)
            .subscribe(async (user: Model.User) => {
                await this.commandPostEditUser.execute(
                    user,
                    this.propertyRoles,
                );
                this.router.navigate([adminRoute, portalUsersRoute]);
        }).unsubscribe();
    }

    public cancel(): void {
        this.router.navigate([adminRoute, portalUsersRoute]);
    }
}