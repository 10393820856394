<mat-card>
  <mat-card-title> </mat-card-title>
  <mat-card-content>
    <p>
      Thank you for working with ANZCO Foods this season. Your property is not
      scheduled to have any more Bobby Calf pick-ups. If this is incorrect,
      please contact your Bobby Calf Livestock Representative.
    </p>
  </mat-card-content>
</mat-card>
