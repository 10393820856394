import { Injectable } from '@angular/core';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Model } from '../interfaces';
import { featureReducerKey } from './reducer';

@Injectable({ providedIn: 'root' })
export class Selector {
  private getAdvantageState =
    createFeatureSelector<Model.AdvantageState>(featureReducerKey);

  public selectSSOLink = createSelector(
    this.getAdvantageState,
    (state: Model.AdvantageState) => state.ssoLink
  );

  public selectPoints = createSelector(
    this.getAdvantageState,
    (state: Model.AdvantageState) => state.points
  );
}
