import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { pickupDeclarationReducer, pickupDeclarationsReducerKey } from './state/reducer';
import { AngularMaterialModule, LoaderModule } from '../../miscellaneous';
import { PickupDeclarationComponent } from './pickup-declarations.component';
import { SharedPipesModule } from '../../miscellaneous/pipes/shared-pipes.module';
import { MatStepperModule } from '@angular/material/stepper';
import { PiclupDeclarationEditComponent } from './pickup-declaration-edit/pickup-declaration-edit.component';
import { PiclupDeclarationSummaryComponent } from './pickup-declaration-summary/pickup-declaration-summary.component';
import { PiclupDeclarationPropertySelectionComponent } from './pickup-declaration-property-selection/pickup-declaration-property-selection.component';

@NgModule({
  imports: [
    StoreModule.forFeature(pickupDeclarationsReducerKey, pickupDeclarationReducer),
    FormsModule,
    CommonModule,
    AngularMaterialModule,
    SharedPipesModule,
    LoaderModule,
    MatStepperModule,
  ],
  exports: [],
  declarations: [
    PickupDeclarationComponent,
    PiclupDeclarationPropertySelectionComponent,
    PiclupDeclarationEditComponent,
    PiclupDeclarationSummaryComponent,
  ],
  providers: [],
})
export class PickupDeclarationsModule {}
