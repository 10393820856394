import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { Model } from '../interfaces';
import { selectNewsOverview } from '../state/selectors';
import { loadNewsOverview, loadNewsOverviewWithoutGlobalSpinner } from '../state/actions';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-news-overview-shell',
  templateUrl: './news-overview-shell.component.html',
  styleUrls: ['./news-overview-shell.component.scss']
})
export class NewsOverviewShellComponent implements OnInit {
  @Input() public useCompactView: boolean;
  public newItems: Model.NewsItemSummary[];
  public anzcoUpdateItems: Model.NewsItemSummary[];
  public importantInfoItems: Model.NewsItemSummary[];
  public overviewReady: boolean;
  private readonly destroy$ = new Subject();

  constructor(
    private readonly store: Store,
  ) { }

  public ngOnInit(): void {
    // when using the 'compact view' show a component level spinner rather than the full page blocking spinner as we
    this.store.dispatch(this.useCompactView ? loadNewsOverviewWithoutGlobalSpinner() : loadNewsOverview());
    this.store.select(selectNewsOverview)
      .pipe(
        filter(Boolean),
        takeUntil(this.destroy$)
      )
      .subscribe((overview: Model.NewsOverview) => {
        const itemsPerFeed = this.useCompactView ? 1 : 4;
        this.newItems = overview.news.items.slice(0, itemsPerFeed);
        this.anzcoUpdateItems = overview.anzcoUpdate.items.slice(0, itemsPerFeed);
        this.importantInfoItems = overview.importantInfo.items.slice(0, itemsPerFeed);
        this.overviewReady = true;
      });
  }
}
