<div class="default-container" data-cy="faq-page-shell">
  <div class="headline">
    <h2>FAQs (Frequently Asked Questions)</h2>
  </div>
  <mat-accordion data-cy="FAQ-section">
    <div>
      <h3>Producer Portal FAQs</h3>
    </div>
    <div class="spacer">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            What do the different coloured tiles on the Home page signify?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul>
          <li>
            Light Blue tiles display upcoming processing's where your livestock
            rep has allocated you space.
          </li>
          <li>
            Dark Blue tiles are currently being processed, stock are either on
            the chain or they have recently been processed. The average weight
            of your animals will display once available.
          </li>
          <li>
            Green tiles are for once the pricing has been confirmed and invoiced
            for your processed stock. If you are an employee, grazier or not the
            primary creditor, you will be unable to view any financial values.
          </li>
        </ul>
        <div class="faq-tile-example-block">
          <p>Examples:</p>
          <img
            class="faq-tile-example-img"
            src="../../../assets/images/FAQTileExample.jpg"
            alt="Processing tile examples"
          />
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>Why can&#39;t I see financials?</mat-panel-title>
        </mat-expansion-panel-header>
        <p>There are two reasons why you may not be able to see this:</p>
        <ul>
          <li>If you are an employee against the property.</li>
          <li>
            If you were not the creditor of the processed animals (either by
            being a grazier or not the owner of the processed stock).
          </li>
        </ul>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>What is Carcase Data?</mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Carcase data is what formerly was called the &#39;Kill Sheet&#39;. For
          Ovine this is at mob level and displays a scattergram indicating the
          spread of weights and grades, plus detailed health data. For Bovine
          this is reported at individual EID level.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title
            >Why do some lines in the Processing tables have &#39;No Files
            Available&#39;?</mat-panel-title
          >
        </mat-expansion-panel-header>
        <p>
          When a mob is processed over two days with only one advice, we split
          the advice to display two entries but only one entry has the
          documentation displayed against it. One entry will display &#39;No Files
          Available&#39; .It could also be that something went wrong with the
          compiling of the data &#45; if you need further info, please contact
          the Livestock Admin team on <a href="tel:+6433571783">033571783</a>.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title
            >I can&#39;t see all my data in the Processing table?
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          The Processing table is defaulted to 10 results per page, so that not
          too much data is loaded at a time. This is particularly for users who
          have low internet connections. You can use the
          <img
            class="paganation-image"
            src="../../../assets/images/PaganationIcons.jpg"
            alt="Paganation icons image"
          />
          arrows or by selecting to view more results per page.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title
            >I have more than one property - where can I find it or how do I get
            it added?</mat-panel-title
          >
        </mat-expansion-panel-header>
        <p>
          Use the dropdown arrow in the property field and select the property
          you wish to view. If the property is not present, you will need to get
          in touch with the Livestock Admin team to get the property added. You
          can do this by emailing your request to
          <a href="mailto:producerqueries@anzfoods.com"
            >producerqueries@anzfoods.com</a
          >
          or talking to your Livestock Representative.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title
            >What happens when I click on the Advantage tile?</mat-panel-title
          >
        </mat-expansion-panel-header>
        <p>
          The Advantage tile is only displayed to Advantage owners. It is not
          visible to Managers, Employees or Service Professionals. When you
          click on the tile it will take you through to the Advantage website.
        </p>
      </mat-expansion-panel>
    </div>
    <h3>ANZCO Livestock Mobile App FAQs</h3>
    <div class="spacer">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title
            >How do I get the ANZCO Livestock Mobile App?</mat-panel-title
          >
        </mat-expansion-panel-header>
        <p class="app-store-align">
          If on your phone you can download the ANZCO Livestock Mobile App from
          here:
        </p>
        <div>
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.anzcolivestock&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          >
            <img
              style="max-height: 60px"
              alt="Get it on Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            />
          </a>
          <a
            target="_blank"
            href="https://apps.apple.com/nz/app/anzco-livestock/id1614528578?itsct=apps_box_badge&amp;itscg=30200"
          >
            <img
              style="height: 40px; margin: 10px"
              src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1650844800&h=e4e87ee9de9d6ec78e4b563c12c0946d"
              alt="Download on the App Store"
            />
          </a>
        </div>
        <p>
          Then you can sign in with your Producer Portal username (your email)
          and password.
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title
            >Do I need a separate username and password for the ANZCO Livestock
            Mobile App?</mat-panel-title
          >
        </mat-expansion-panel-header>
        <p>
          No, you use the same username and password as the Producer Portal.
        </p>
      </mat-expansion-panel>
    </div>
    <h3>Bobby Calf FAQs</h3>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title
          >Are Bobby Calf requests first in first served?</mat-panel-title
        >
      </mat-expansion-panel-header>
      <p>
        No. Everyone can submit their Bobby Calf requests to ANZCO and we will
        fairly manage capacity across all producers. There is no need to get up
        in the middle of the night to request pick-ups.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title
          >I made a pick-up request just on close time and it came back to advise
          that the request for that date had closed. Am I too late and who can I
          ask for a late request?</mat-panel-title
        >
      </mat-expansion-panel-header>
      <p>
        We close off at a specified time and this can vary route to route.
        However, we do want to enforce the cut off time but please contact your
        Bobby Calf/Livestock Representative.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title
          >I have extra Bobby Calves to pick-up - can I request another extra
          pick-up?</mat-panel-title
        >
      </mat-expansion-panel-header>
      <p>
        Please raise this with your Bobby Calf/Livestock Representative - we will need to work around
        demand for that day.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title
          >Will I know how many of my calves you will be able to take? And when
          will I know this?</mat-panel-title
        >
      </mat-expansion-panel-header>
      <p>
        We will let you know via the Portal/App the day before pick-up. If you
        are on the App, you will receive a notification advising confirmed
        numbers. The Portal will display the current status of a request. We
        don&#39;t envisage that on some days we won&#39;t be able to take any of
        your calves but circumstances like weather events or plant breakdowns
        may force us to re-evaluate numbers on a daily basis.
      </p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title
          >I got an error message that says there is an issue with the Bobby
          Calf Service, and I can&#39;t request a pick-up - how long will it take
          to fix?</mat-panel-title
        >
      </mat-expansion-panel-header>
      <p>
        We endeavour to get the service running as soon as we can after an
        outage. If we can&#39;t get it up and running by close off time for your
        next day(s) request(s), we have a text message back up system that we
        can kick off if we can&#39;t get the service back on in time.
        Alternatively, you can call your Bobby Calf/Livestock Representative.
      </p>
    </mat-expansion-panel>
  </mat-accordion>
  <button mat-raised-button class="back-button" (click)="goBack()">
    &#60; Back
  </button>
</div>
