<div class="main-element">
    <app-edit-user [title]="'Manage Portal User'"
                   [displayProducerData]="false"></app-edit-user>

    <div class="button-panel">
        <button class="mat-raised-button"
                (click)="cancel()"
                data-cy="portalUsersCancelBtn">Cancel</button>
        <div>
            <button *ngIf="!editUserConfirmed"
                    class="mat-raised-button mat-primary"
                    [disabled]="!validToConfirm"
                    (click)="confirm()"
                    data-cy="portalUsersConfirmBtn">Confirm</button>
            <button *ngIf="editUserConfirmed"
                    class="mat-raised-button mat-primary"
                    (click)="approve()"
                    data-cy="portalUsersApproveBtn">Approve</button>
        </div>
    </div>
</div>