import { createReducer, on } from "@ngrx/store";
import { Compliance, Model } from "../interfaces";
import {
    complianceErrored,
    loadComplianceLinesCompleted,
} from "./actions";

export const complianceFeatureKey = "compliance";

const initialState: Compliance.State = {
    compliances: [],
    errored: false,
}

export const complianceReducer = createReducer<Compliance.State>(
    initialState,

    on(
        loadComplianceLinesCompleted,
        (state: Compliance.State, res: Model.PotentialOutageResponse<Model.ComplianceLineResponse[]>): Compliance.State => ({
            ...state,
            compliances: res.errored ?
                [] :
                res.response.map(compliance => {
                    return {
                        anzcoPropertyId: compliance.anzcoPropertyId,
                        propertyName: compliance.propertyName,
                        compliancePropertyId: compliance.compliancePropertyId,
                        complianceProgramId: compliance.complianceProgramId,
                        complianceProgramName: compliance.complianceProgramName,
                        complianceProgramDescription: compliance.complianceProgramDescription,
                        isClipOn: compliance.isClipOn,
                        expiryDate: compliance.expiryDate ?
                            new Date(compliance.expiryDate) :
                            null,
                        status: compliance.status,
                    };
                }),
        }),
    ),

    on(complianceErrored, (state: Compliance.State, res): Compliance.State => {
        return {
            ...state,
            errored: res.errored,
        };
    }),
);