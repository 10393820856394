export enum Role {
  Owner = 1,
  Manager = 2,
  Limited = 3,
  ServiceProvider = 5,
  Unknown = 10,
}

export class RoleEnum {
  public static Owner = new RoleEnum(Role.Owner, 'Owner', 1);
  public static Manager = new RoleEnum(Role.Manager, 'Manager', 2);
  public static ServiceProvider = new RoleEnum(
    Role.ServiceProvider,
    'Service Professional',
    4
  );
  public static Limited = new RoleEnum(Role.Limited, 'Employee', 3);
  public static Unknown = new RoleEnum(Role.Unknown, 'Unknown', null);

  private static allRoles: { [role: number]: RoleEnum };

  constructor(
    public readonly role: Role,
    public readonly description: string,
    public readonly displayOrderNumber: number
  ) {
    if (!RoleEnum.allRoles) {
      RoleEnum.allRoles = {};
    }
    RoleEnum.allRoles[role] = this;
  }

  public static get(role: Role): RoleEnum {
    return this.allRoles[role] || RoleEnum.Unknown;
  }

  public static parse(role: Role): string {
    return role && RoleEnum.allRoles[role]
      ? RoleEnum.allRoles[role].description
      : '';
  }

  public static all(): RoleEnum[] {
    return Object.entries(RoleEnum.allRoles)
      .map(([key, value]) => value)
      .filter((roleEnum) => roleEnum.displayOrderNumber !== null)
      .sort(
        (roleA, roleB) => roleA.displayOrderNumber - roleB.displayOrderNumber
      );
  }
}
