import { combineReducers, createReducer, on } from "@ngrx/store";
import { closeHelp, showHelp } from "./actions";

export const featureReducerKey = "help";

const activeReducer = createReducer(
    false,
    on(showHelp, (state: boolean) => true),
    on(closeHelp, (state: boolean) => false),
);

export const reducer = combineReducers(
    {
        active: activeReducer,
    },
);