<div class="edit-header">
  <h1>{{ title }}</h1>
  <h1>Email: {{ user.email }}</h1>
</div>

<div class="row static-row">
  <div class="column short-column">
    First Name:
    <input
      #firstNameInput
      cy-data="firstNameInput"
      type="text"
      value="{{ user.firstName }}"
      [disabled]="disabled"
      (input)="change({ firstName: firstNameInput.value })"
    />
  </div>
  <div class="column short-column">
    Surname:
    <input
      #lastNameInput
      cy-data="lastNameInput"
      type="text"
      value="{{ user.surName }}"
      [disabled]="disabled"
      (input)="change({ surName: lastNameInput.value })"
    />
  </div>
  <div class="column short-column">
    GST Number:
    <input
      #gstNumberInput
      cy-data="gstNumberInput"
      type="text"
      value="{{ user.gstNumber }}"
      [disabled]="disabled"
      (input)="change({ gstNumber: gstNumberInput.value })"
    />
  </div>
  <div class="column short-column">
    Contact Number:
    <input
      #contactNumberInput
      cy-data="contactNumberInput"
      type="text"
      value="{{ user.contactNumber }}"
      [disabled]="disabled"
      (input)="change({ contactNumber: contactNumberInput.value })"
    />
  </div>
  <div class="column">
    <div class="split-labels">
      <span>ANZCO Advantage Username:</span>
      <span *ngIf="!validAdvantageEmail" class="validation-failure">
        * Invalid Email format
      </span>
    </div>
    <input
      #advantageUsernameInput
      cy-data="advantageUsernameInput"
      type="text"
      value="{{ user.advantageUsername }}"
      placeholder="{{ user.email }}"
      [disabled]="disabled"
      (input)="change({ advantageUsername: advantageUsernameInput.value })"
    />
  </div>
</div>
<div class="row">
  <div *ngIf="displayProducerData" class="column">
    Producer Name:
    <input
      #producerNameInput
      cy-data="producerNameInput"
      type="text"
      value="{{ user.producerName }}"
      disabled
      (input)="change({ producerName: producerNameInput.value })"
    />
  </div>
  <div *ngIf="displayProducerData" class="column short-column">
    Role:
    <input cy-data="roleInput" type="text" value="{{ user.role }}" disabled />
  </div>
</div>
<div class="row">
  <div class="column">
    Property (Search):
    <input
      #propertySearchInput
      cy-data="propertySearch"
      type="text"
      [disabled]="disabled"
      (input)="changePropertySearchTerm(propertySearchInput.value)"
    />
  </div>
  <div class="column short-column">
    Producer Code (Search):
    <input
      #producerCodeSearchInput
      cy-data="producerCodeSearch"
      [class.producer-code-unknown]="(producerCodeKnown | async) === false"
      type="text"
      value="{{ user.producerCode }}"
      [disabled]="disabled"
      (input)="changeProducerCodeSearchTerm(producerCodeSearchInput.value)"
    />
  </div>
</div>

<table size="sm" cy-data="userPropertyTable">
  <thead>
    <tr>
      <th>Property ID</th>
      <th class="wide-table-column">Property Name</th>
      <th>Role</th>
      <th>&nbsp;</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let property of properties | async; index as i">
      <td cy-data="propertyId">{{ property.propertyId }}</td>
      <td cy-data="propertyName" class="wide-table-column">
        {{ property.propertyName }}
      </td>
      <td *ngIf="property.shouldDisplayRoleChoice">
        <button
          mat-button
          [matMenuTriggerFor]="roles"
          class="table-button"
          cy-data="currentRoleBtn"
        >
          {{ property.roleDescription }}
        </button>
        <mat-menu #roles="matMenu">
          <button
            cy-data="roleDropdownOption"
            *ngFor="let role of allRoles"
            mat-menu-item
            (click)="setRole(property.propertyId, role.role)"
          >
            {{ role.description }}
          </button>
        </mat-menu>
      </td>
      <td *ngIf="property.shouldDisplayPlainRole" cy-data="currentRoleText">
        {{ property.roleDescription }}
      </td>
      <td *ngIf="property.shouldDisplayLock">
        <button
          mat-button
          class="table-button"
          (click)="changeLock(property.propertyId)"
          cy-data="lockBtn"
        >
          Lock
        </button>
      </td>
      <td *ngIf="property.shouldDisplayUnlock">
        <button
          mat-button
          class="table-button bright-button"
          (click)="changeLock(property.propertyId)"
          cy-data="unlockBtn"
        >
          Unlock
        </button>
      </td>
      <td
        *ngIf="!property.shouldDisplayLock && !property.shouldDisplayUnlock"
      ></td>
    </tr>
  </tbody>
</table>
