import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reportsFeatureKey, reportsReducer } from './state/reducer';
import { ReportingEffects } from './state/effects';
import { AngularMaterialModule } from 'src/app/miscellaneous';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReportsComponent } from './financial-report/reports.component';

@NgModule({
    declarations: [
        ReportsComponent
    ],
    imports: [
        CommonModule,
        AngularMaterialModule,
        StoreModule.forFeature(reportsFeatureKey, reportsReducer),
        EffectsModule.forFeature([ReportingEffects]),
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: [ReportsComponent]
})
export class ReportingModule { }