<div class="main-element" data-cy="portal-users-page-shell">
  <div class="headline">
    <h1>Portal Users</h1>
    <button
      mat-raised-button
      color="primary"
      class="icon-button"
      data-cy="addStaffButton"
      (click)="onAddUser()"
    >
      <i class="btr bt-plus bt-lg"></i>
    </button>
  </div>

  <h5>Search for Producer Code, Email, First Name or Surname:</h5>

  <input
    type="text"
    [value]="searchTerm"
    (input)="searchForUsers($event.srcElement.value)"
    data-cy="portalUsersSearch"
  />

  <table size="sm" data-cy="portalUsersTable">
    <thead>
      <tr>
        <th class="icon-table-column" data-cy="activeHeading">Active</th>
        <th class="wide-table-column" data-cy="emailHeading">Email</th>
        <th class="medium-table-column" data-cy="firstNameHeading">
          First Name
        </th>
        <th class="medium-table-column" data-cy="surnameHeading">Surname</th>
        <th class="medium-table-column"></th>
        <th class="medium-table-column"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of users | async; index as i">
        <td class="icon-table-column">
          <img
            *ngIf="user.isActive"
            src="../../../assets/icons/check_circle_black_24dp.svg"
          />
        </td>
        <td class="wide-table-column" data-cy="userEmail">{{ user.email }}</td>
        <td class="medium-table-column" data-cy="userFirstName">
          {{ user.firstName }}
        </td>
        <td class="medium-table-column" data-cy="userSurname">
          {{ user.surName }}
        </td>
        <td class="medium-table-column">
          <button
            mat-button
            class="table-button"
            (click)="editUser(user.userId)"
            data-cy="manageBtn"
          >
            Manage
          </button>
        </td>
        <td class="medium-table-column">
          <button
            mat-button
            class="table-button"
            (click)="proxyInAsUser(user.userId)"
            data-cy="proxyBtn"
          >
            Proxy In As
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
