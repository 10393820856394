import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { seasonComplete } from '../state/actions';
import { selectedPropertyId, selectedPropertyName } from '../state/selectors';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ReactivateSeasonDialogComponent } from '../reactivate-season-dialog/reactivate-season-dialog.component';

@Component({
  selector: 'app-bobby-calves-season-completed-card',
  templateUrl: './bobby-calves-season-completed-card.component.html',
  styleUrls: ['./bobby-calves-season-completed-card.component.scss'],
})
export class BobbyCalvesSeasonCompletedCardComponent implements OnInit {
  private readonly destroy$ = new Subject();
  public propertyId: number;
  private propertyName: string | null = null;

  constructor(
    private readonly store: Store,
    private readonly dialog: MatDialog,
  ) { }
  
  public ngOnInit(): void {
    this.store
      .select(selectedPropertyId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((propertyId) => this.propertyId = propertyId);
    this.store
      .select(selectedPropertyName)
      .pipe(takeUntil(this.destroy$))
      .subscribe(propertyName => this.propertyName = propertyName);
  }

  public reactiveSeason(): void {
    this.store.dispatch(seasonComplete({
      anzcoPropertyId: this.propertyId,
      isSeasonCompleted: false,
    }));
    this.dialog.open(
      ReactivateSeasonDialogComponent,
      {
        data: {
          propertyName: this.propertyName,
        },
      });
  }
}
