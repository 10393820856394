import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { proxyInAsCompleted, proxyInAsStarted } from "../state/actions";

@Injectable({ providedIn: 'root' })
export class CommandProxyInAsUser {
    constructor(
        private readonly store: Store,
        private readonly http: HttpClient,
    ) {}

    public async execute(userId: number): Promise<void> {
        this.store.dispatch(proxyInAsStarted());
        await this.proxyInAsUser(userId);
        this.store.dispatch(proxyInAsCompleted());
    }

    private async proxyInAsUser(userId: number): Promise<void> {
        await this.http
            .post(environment.apiUrl + "/api/admin/proxied-in-as-user", { userId })
            .toPromise();
    }
}