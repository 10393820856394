import { createAction, props } from '@ngrx/store';
import { Model } from '../interfaces';

export const loadProperties = createAction(`[Documents] loadPropertiesStarted`);

export const loadPropertiesCompleted = createAction(
  `[Documents] loadPropertiesCompleted`,
  props<Model.PropertiesResponse>()
);

export const loadDeclarations = createAction(
    "[Documents] Load Declarations Started",
    props<Model.DeclarationsHistoryQuery>(),
);

export const loadDeclarationsCompleted = createAction(
    "[Documents] Load Declarations Completed",
    props<Model.PagedDeclarationHistoryResponse>(),
);

export const filterUpdated = createAction(
    "[Documents] Filter Updated",
    props<{ update: Partial<Model.DeclarationsHistoryQuery> }>(),
);

export const clearFilter = createAction("[Documents] Clear Filter");