import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Injectable({
  providedIn: 'root',
})
export class IconRegistryService {
  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry
  ) {}

  init(): void {
    this.addSvg('bobby-calf');
  }

  private addSvg(name: string): IconRegistryService {
    const url = `assets/icons/${name}.svg`;
    const safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    this.matIconRegistry.addSvgIcon(name, safeUrl);
    return this;
  }
}
