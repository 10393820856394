<div class="default-container" data-cy="legal-page-shell">
  <div class="headline">
    <h2>Legal Links</h2>
  </div>
  <mat-card>
    <div
      class="policy-row"
      onclick="window.open('https://anzcofoods.com/website-terms-of-use/', '_blank')"
      data-cy="website-terms-and-conditions"
    >
      <div>Website T&Cs</div>
      <div>&#62;</div>
    </div>
    <div class="divider">
      <mat-divider></mat-divider>
    </div>
    <div
      class="policy-row"
      onclick="window.open('https://anzcofoods.com/assets/Documents/Standard-Livestock-Supply-Terms-and-Conditions.pdf', '_blank')"
      data-cy="livestock-terms-and-conditions"
    >
      <div>Livestock T&Cs</div>
      <div>&#62;</div>
    </div>
    <div class="divider">
      <mat-divider></mat-divider>
    </div>
    <div
      class="policy-row"
      onclick="window.open('https://anzcofoods.com/assets/Documents/Store-Stock-Terms-and-Conditions.pdf', '_blank')"
      data-cy="store-stock-terms-and-conditions"
    >
      <div>Store Stock T&Cs</div>
      <div>&#62;</div>
    </div>
    <div class="divider">
      <mat-divider></mat-divider>
    </div>
    <div
      class="policy-row"
      onclick="window.open('https://anzcofoods.com/privacy-policy/', '_blank')"
      data-cy="privacy-policy"
    >
      <div>Privacy Policy</div>
      <div>&#62;</div>
    </div>
    <div class="divider">
      <mat-divider></mat-divider>
    </div>
    <div
      class="policy-row"
      onclick="window.open('https://anzcofoods.com/cookies-policy/', '_blank')"
      data-cy="cookies-policy"
    >
      <div>Cookies Policy</div>
      <div>&#62;</div>
    </div>
  </mat-card>
  <button mat-raised-button class="back-button" (click)="goBack()">
    &#60; Back
  </button>
</div>
