<div mat-dialog-title>
    <h1>{{title}}</h1>
</div>

<mat-dialog-content>
    <span>{{text}}</span>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button
            class="cancel-button"
            (click)="cancel()"
            data-cy="confirmationCancelButton">
        Cancel
    </button>
    <button mat-raised-button
            (click)="ok()"
            data-cy="confirmationOkButton">
        OK
    </button>
</mat-dialog-actions>