import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { adminTaskAssignedStarted, adminTaskAssignedCompleted } from "../state/actions";
import { selectUser } from "src/app/user";

@Injectable({ providedIn: 'root' })
export class CommandAssignAdminUserTask {
    constructor(
        private readonly store: Store,
        private readonly http: HttpClient,
    ) {}

    public async execute(adminTaskId: number): Promise<void> {
        const subscription = this.store.select(selectUser).subscribe(async user => {
            this.store.dispatch(adminTaskAssignedStarted());
            const success: boolean = await this.assignAdminTask(adminTaskId);
            this.store.dispatch(adminTaskAssignedCompleted({ success, adminTaskId, userName: user.name }));
        });

        subscription.unsubscribe();
    }

    private async assignAdminTask(adminTaskId: number): Promise<boolean> {
        return await this.http
            .post<boolean>(environment.apiUrl + "/api/admin/assign-admin-task", { adminTaskId })
            .toPromise();
    }
}