import { createReducer, on } from "@ngrx/store";
import { Model } from "../interfaces";
import {
    closeMenu,
    deactivateBobbyMenu,
    loadBobbyCalvesTallyCompleted,
    loadUser,
    loadUserCompleted,
    registerAccountSuccess,
    toggleMenu,
    loadOpenDeclarationsCompleted,
    showOpenDeclarations,
    createOrUpdatePickupDeclaration,
} from "./actions";

export const userFeatureKey = "user";

export interface UserState {
    displayMenu: boolean;
    user: Model.User;
    bobbyTallies: Model.TotalTallyResponse[],
    declarations: Model.OpenDeclarations,
}

const initialState: UserState = {
    displayMenu: false,
    user: null,
    bobbyTallies: [],
    declarations: null,
}

export const userReducer = createReducer<UserState>(
    initialState,

    on(loadUser, (state: UserState) => {
        return {
            ...state,
            user: null,
        };
    }),

    on(loadUserCompleted, (state: UserState, { user }) => {
        return {
            ...state,
            user: {
                ...user,
                newRegistration: false,
            },
        };
    }),
    
    on(registerAccountSuccess, (state: UserState) => {
        return {
            ...state,
            user: {
                ...state.user,
                hasRegistration: true,
                hasRegistrationPendingApproval: true,
                newRegistration: true,
            },
        };
    }),

    on(toggleMenu, (state: UserState) => {
        return {
            ...state,
            displayMenu: !state.displayMenu,
        };
    }),

    on(closeMenu, (state: UserState) => {
        return {
            ...state,
            displayMenu: false,
        };
    }),

    on(deactivateBobbyMenu, (state: UserState) => {
        return {
            ...state,
            user: {
                ...state.user,
                hasBobbyCalvesAccess: false,
            }
        };
    }),

    on(loadBobbyCalvesTallyCompleted, (state: UserState, { tallies }) => {
        return {
            ...state,
            bobbyTallies: <Model.TotalTallyResponse[]>(tallies.errored ? [] : tallies.response),
        };
    }),

    on(loadOpenDeclarationsCompleted, (state: UserState, { declarations }) => {
        return {
            ...state,
            declarations: <Model.OpenDeclarations>(
                declarations.errored ?
                    null :
                    {
                        completed: declarations.response.completed,
                        expected: declarations.response.expected,
                        show: state.declarations?.show ?? true,
                    }),
        };
    }),

    on(showOpenDeclarations, (state: UserState, { show }) => {
        return {
            ...state,
            declarations: <Model.OpenDeclarations>(
                {
                    completed: state.declarations?.completed ?? [],
                    expected: state.declarations?.expected ?? [],
                    show,
                }),
        };
    }),

    on(createOrUpdatePickupDeclaration, (state: UserState, command) => {
        return {
            ...state,
            declarations: <Model.OpenDeclarations>(
                {
                    ...state.declarations,
                    expected: state.declarations?.expected.filter(expected => expected.bookingId !== command.bookingId),
                    completed: [
                      ...state.declarations.completed,
                      {
                        id: command.id,
                        anzcoPropertyId: 12, // TODO,
                        bookingId: command.bookingId,
                        contactNumber: command.contactNumber,
                        pickupDate: new Date(), // TODO
                        propertyName: "", // TODO
                        recordedBy: command.recordedBy,
                        tally: command.tally,
                        timeOfLastFeed: command.timeOfLastFeed,
                      },
                    ],
                }),
        };
    }),    
);