export enum AdviceStatus {
    Unknown = 0,
    Created = 1,
    Processing = 2,
    ProposedPricing = 3,
    Invoiced = 4,
};

export class AdviceStatusEnum {
    public static Unknown = new AdviceStatusEnum(
        AdviceStatus.Unknown,
        "Unknown",
        "unknown",
    );
    public static Created = new AdviceStatusEnum(
        AdviceStatus.Created,
        "Created",
        "created",
    );
    public static Processing = new AdviceStatusEnum(
        AdviceStatus.Processing,
        "Processing",
        "processing",
    );
    public static ProposedPricing = new AdviceStatusEnum(
        AdviceStatus.ProposedPricing,
        "Proposed Pricing",
        "proposed-pricing",
    );
    public static Invoiced = new AdviceStatusEnum(
        AdviceStatus.Invoiced,
        "Invoiced",
        "invoiced",
    );

    private static allAdviceStatus: { [adviceStatus: number]: AdviceStatusEnum; };

    constructor(
        public readonly adviceStatus: AdviceStatus,
        public readonly description: string,
        public readonly adviceCardClass: string,
    ) {
        if (!AdviceStatusEnum.allAdviceStatus) {
            AdviceStatusEnum.allAdviceStatus = {};
        }
        AdviceStatusEnum.allAdviceStatus[adviceStatus] = this;
    }

    public static fromEnum(adviceStatus: AdviceStatus): AdviceStatusEnum {
        return AdviceStatusEnum.allAdviceStatus[adviceStatus]
    }

    public static parse(adviceStatus: AdviceStatus): string {
        return adviceStatus !== null && AdviceStatusEnum.allAdviceStatus[adviceStatus] ?
            AdviceStatusEnum.allAdviceStatus[adviceStatus].description :
            "";
    }

    public static cardCssClass(adviceStatus: AdviceStatus): string {
        return adviceStatus !== null && AdviceStatusEnum.allAdviceStatus[adviceStatus] ?
            AdviceStatusEnum.allAdviceStatus[adviceStatus].adviceCardClass :
            "#000000";
    }
};