import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-registration-help',
  templateUrl: './help.component.html',
  styleUrls: ['../registration.scss']
})
export class RegistrationHelpComponent {

  constructor(private readonly msalInstance: MsalService) { }

  public logout(): void {
    this.msalInstance.logoutRedirect({ postLogoutRedirectUri: environment.webUrl });
  } 
}