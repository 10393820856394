import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { adminTaskDeclineCompleted, adminTaskDeclineStarted } from "../state/actions";
import { DeclineReason } from "src/app/miscellaneous/enums/decline-reason.enum";

@Injectable({ providedIn: 'root' })
export class CommandPostDeclineAdminUserTask {
    constructor(
        private readonly store: Store,
        private readonly http: HttpClient,
    ) {}

    public async execute(
        adminTaskId: number,
        userId: number,
        declineReason: DeclineReason,
        declineText: string,
    ): Promise<void> {
        this.store.dispatch(adminTaskDeclineStarted());
        await this.declineAdminTask(
            adminTaskId,
            userId,
            declineReason,
            declineText,
        );
        this.store.dispatch(adminTaskDeclineCompleted({ adminTaskId }));
    }

    private async declineAdminTask(
        adminTaskId: number,
        userId: number,
        declineReason: DeclineReason,
        declineText: string,
    ): Promise<void> {
        const params = {
            adminTaskId,
            userId,
            declineReason,
            declineText,
        };

        await this.http
            .post(environment.apiUrl + "/api/admin/decline-admin-task", params)
            .toPromise();
    }
}