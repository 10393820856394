import { Component, ElementRef, Input, OnChanges } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { CommandGetCarcase } from "../commands/command-get-carcase";
import { CommandGetInvoice } from "../commands/command-get-invoice";
import { Projection } from "../interfaces";
import { selectAdvice } from "../state/actions";

@Component({
    selector: "app-advice-cards",
    templateUrl: "advice-cards.component.html",
    styleUrls: ["advice-cards.component.scss", "../advices.scss"]
})
export class AdviceCardsComponent implements OnChanges {
    @Input() public advices: Projection.Advice[];

    constructor(
        private readonly element: ElementRef,
        private readonly store: Store,
        private readonly route: ActivatedRoute,
        private readonly commandGetInvoice: CommandGetInvoice,
        private readonly commandGetCarcase: CommandGetCarcase,
    ) { }

    public ngOnChanges(): void {
        if (this.advices && this.advices.length === 2 && this.element.nativeElement.clientWidth > 700) {
            this.element.nativeElement.style.justifyContent = "initial";
        }
    }

    public adviceKillDate(advice: Projection.Advice): Date {
        return advice.adviceLines.reduce(
            (lowestLine: Projection.AdviceLine, currentLine: Projection.AdviceLine) =>
                currentLine.killDate ?
                    (lowestLine.killDate.getTime() > currentLine.killDate.getTime() ? currentLine : lowestLine) :
                    lowestLine).killDate;
    }

    public selectAdvice(advice: Projection.Advice): void {
        this.store.dispatch(selectAdvice({
            season: advice.season,
            advice: advice.advice,
            propertyId: advice.propertyId,
            previousRoute: this.route.snapshot.routeConfig.path,
        }));
    }

    public async downloadInvoice(
        advice: Projection.Advice,
        evt: MouseEvent,
    ): Promise<void> {
        // stop event from propagating so that a download isn't misinterpreted as a card click
        evt.stopImmediatePropagation();

        await this.commandGetInvoice.Retrieve(
            advice.invoiceFileId,
            advice.advice,
            advice.season,
            advice.propertyId,
            this.adviceKillDate(advice),
            advice.isBobbyAdvice);
    }

    public async downloadCarcaseData(
        advice: Projection.Advice,
        evt: MouseEvent,
    ): Promise<void> {
        // stop event from propagating so that a download isn't misinterpreted as a card click
        evt.stopImmediatePropagation();

        for (let index = 0; index < advice.adviceLines.length; index++) {
            const adviceLine: Projection.AdviceLine = advice.adviceLines[index];
            
            if (adviceLine.carcaseFileId)
            {
                await this.commandGetCarcase.Retrieve(
                    adviceLine.carcaseFileId,
                    advice.advice,
                    advice.season,
                    advice.propertyId,
                    adviceLine.killDate);
            }
        }
    }
}