export enum DeclineReason {
    NotRecognisedProducer = 1,
    NotEnoughInformation = 2,
    NotRelevant = 3,
    PropertyAlreadyExists = 4,
    PropertyOwnerComms = 5,
    UnableToContactOwner = 6,
    UnrecognisedProperty = 7,
    Other = 100,
};

export class DeclineReasonEnum {
    public static NotRecognisedProducer = new DeclineReasonEnum(
        DeclineReason.NotRecognisedProducer,
        "Your company/property is not one of our recognised producers. You will need to make an application to ANZCO as a supplier before access can be granted. Please refer to our Farm with Us page. Here is the link to our Supplier application.",
        1,
    );
    public static NotEnoughInformation = new DeclineReasonEnum(
        DeclineReason.NotEnoughInformation,
        "We don’t have enough information about you and suggest you speak to your local livestock rep.",
        2,
    );
    public static NotRelevant = new DeclineReasonEnum(
        DeclineReason.NotRelevant,
        "Your business is not relevant to this portal.",
        3,
    );
    public static PropertyAlreadyExists = new DeclineReasonEnum(
        DeclineReason.PropertyAlreadyExists,
        "The property already exists under your producer code.",
        4,
    );
    public static PropertyOwnerComms = new DeclineReasonEnum(
        DeclineReason.PropertyOwnerComms,
        "The property owner has chosen to handle any communications with ANZCO.",
        5,
    );
    public static UnableToContactOwner = new DeclineReasonEnum(
        DeclineReason.UnableToContactOwner,
        "We were unable to contact the property owner to verify this request you may want to check with the owner directly to request access.",
        6,
    );
    public static UnrecognisedProperty = new DeclineReasonEnum(
        DeclineReason.UnrecognisedProperty,
        "The property you have requested access to is not one we recognise.",
        7,
    );

    private static allDeclineReason: { [declineReason: number]: DeclineReasonEnum; };

    constructor(
        public readonly declineReason: DeclineReason,
        public readonly description: string,
        public readonly displayOrderNumber: number,
    ) {
        if (!DeclineReasonEnum.allDeclineReason) {
            DeclineReasonEnum.allDeclineReason = {};
        }
        DeclineReasonEnum.allDeclineReason[declineReason] = this;
    }

    public static parse(declineReason: DeclineReason): string {
        return declineReason && DeclineReasonEnum.allDeclineReason[declineReason] ?
            DeclineReasonEnum.allDeclineReason[declineReason].description :
            "";
    }

    public static all(): DeclineReasonEnum[] {
        return Object
            .entries(DeclineReasonEnum.allDeclineReason)
            .map(([key, value]) => value)
            .filter(roleEnum => roleEnum.displayOrderNumber !== null)
            .sort((declineReasonA, declineReasonB) => declineReasonA.displayOrderNumber - declineReasonB.displayOrderNumber);
    }
};