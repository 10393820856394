import { Component, Input } from "@angular/core";
import { Model } from "../interfaces";

@Component({
    selector: "app-declaration-cards",
    templateUrl: "declaration-cards.component.html",
    styleUrls: ["declaration-cards.component.scss"]
})
export class DeclarationCardsComponent {
    @Input() public declarations: Model.DeclarationHistorySearchResponse[] = [];
}