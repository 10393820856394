<mat-card class="preseason-info">
  <span class="material-symbols-outlined"> signal_disconnected </span>
  <p>
    We're sorry, our Bobby Calf Request System is currently experiencing
    technical issues.
  </p>
  <p>
    We're working to resolve this outage as soon as we can. Please try again
    soon.
  </p>
  <p>
    If you have an urgent matter, please contact your Livestock Representative.
  </p>
  <p>
    Need to speak to someone? Please call 0800 4 ANZCO
    <a href="tel:0800426926">(0800 4 26926)</a>
    for further assistance.
  </p>
  <div></div>
</mat-card>
