import { Injectable } from "@angular/core";
import { createSelector } from "@ngrx/store";
import { Model } from "../help.interfaces";

@Injectable({ providedIn: 'root' })
export class Selector {
    public selectActive = (state: Model.State) => state.help.active;

    public selectHelpActive = createSelector(
        this.selectActive,
        (active: boolean) => active,
    )
}