<div class="main-element">
    <h1>Change Requests</h1>

    <table size="sm">
        <thead>
            <tr>
                <th class="medium-table-column">User Name</th>
                <th>Email</th>
                <th class="medium-table-column">Producer Code</th>
                <th class="short-table-column">Role</th>
                <th class="medium-table-column">Trade Name</th>
                <th class="wide-table-column">Requested</th>
                <th class="medium-table-column">Assigned To</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let adminTask of adminTasks | async; index as i"
                (click)="editUserRequest(adminTask.id)">
                <td class="medium-table-column">{{adminTask.userName}}</td>
                <td>{{adminTask.email}}</td>
                <td class="medium-table-column">{{adminTask.producerCode}}</td>
                <td class="short-table-column">{{adminTask.role}}</td>
                <td class="medium-table-column">{{adminTask.producerName}}</td>
                <td class="wide-table-column">{{adminTask.created}}</td>
                <td class="medium-table-column">{{adminTask.assignedTo}}</td>
            </tr>
        </tbody>
    </table>
</div>