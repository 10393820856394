import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Projection } from './interfaces';
import { selectCompliances } from './state/selectors';
import { loadComplianceLines } from './state/actions';

@Component({
  selector: 'app-compliance',
  templateUrl: './compliance.component.html',
  styleUrls: ['./compliance.component.scss']
})
export class ComplianceComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject();

  public compliances: Projection.Compliances[] = [];

  constructor(
    private readonly store: Store,
  ) { }
 
  public ngOnInit(): void {
    this.store.select(selectCompliances)
      .pipe(takeUntil(this.destroy$))
      .subscribe((compliances) => this.compliances = compliances);

    this.store.dispatch(loadComplianceLines());
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}