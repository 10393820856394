<div
  *ngIf="bobbyTallies.length"
  data-cy="bobby-calves-progress-tile"
  class="bobby-dashboard-tile">

  <div *ngIf="!multiTalliesSummary" class="properties">
    <mat-card *ngFor="let bobbyTally of bobbyTallies" class="property">
      <div>
        <p class="highlight">{{ bobbyTally.propertyName }}</p>
      </div>
      <div class="stack-view">
        <mat-progress-spinner
          class="grey-spinner"
          diameter="140"
          strokeWidth="14"
          color="primary"
          value="100">
        </mat-progress-spinner>
        <mat-progress-spinner
          diameter="140"
          strokeWidth="14"
          color="accent"
          [value]="bobbyTally.percentage">
        </mat-progress-spinner>
      </div>
      <div class="spinner-text">
        <p>{{ bobbyTally.usedTally }} / {{ bobbyTally.totalTally }}</p>
      </div>
    </mat-card>
  </div>

  <div *ngIf="multiTalliesSummary" class="properties">
    <mat-card class="property">
      <div>
        <p class="highlight">Total Bobby Processing {{ year }}</p>
      </div>
      <div class="stack-view">
        <mat-progress-spinner
          class="grey-spinner"
          diameter="140"
          strokeWidth="14"
          value="100">
        </mat-progress-spinner>
        <mat-progress-spinner
          diameter="140"
          strokeWidth="14"
          color="accent"
          [value]="multiTalliesSummary.summedPercentage">
        </mat-progress-spinner>
      </div>
      <div class="spinner-text">
        <p>
          {{ multiTalliesSummary.summedUsedTally }} /
          {{ multiTalliesSummary.summedTally }}
        </p>
      </div>
    </mat-card>
    <mat-card *ngFor="let bobbyTally of bobbyTallies" class="property">
      <div>
        <p class="highlight">{{ bobbyTally.propertyName }}</p>
      </div>
      <div class="stack-view">
        <mat-progress-spinner
          class="grey-spinner"
          diameter="140"
          strokeWidth="14"
          color="primary"
          value="100">
        </mat-progress-spinner>
        <mat-progress-spinner
        diameter="140"
        strokeWidth="14"
          color="accent"
          [value]="bobbyTally.percentage">
        </mat-progress-spinner>
      </div>
      <div class="spinner-text">
        <p>{{ bobbyTally.usedTally }} / {{ bobbyTally.totalTally }}</p>
      </div>
    </mat-card>
  </div>
</div>
