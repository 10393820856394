<div class="default-container" data-cy="processing-page-shell">
  <div class="headline">
    <h2>Processing</h2>
    <div>
      <button mat-raised-button
              color="primary"
              class="non-mobile"
              (click)="downloadCsv()"
              data-cy="downloadCSVButton">
        Download CSV
      </button>
      <button *ngIf="!showFilter"
              mat-raised-button
              color="primary"
              class="icon-button"
              (click)="showFilterPanel()">
        <i class="btr bt-angles-down bt-lg"></i>
      </button>
    </div>
  </div>

  <div *ngIf="filterOptions">
    <app-advice-filter *ngIf="showFilter"></app-advice-filter>

    <div class="mat-elevation-z8">
      <app-processing-table [advices]="advices"></app-processing-table>

      <app-advice-cards [advices]="advices"></app-advice-cards>

      <div *ngIf="advices?.length === 0 && filterOptions" class="no-results">No advices available.</div>

      <mat-paginator *ngIf="advices?.length > 0"
                      [length]="totalAdvicesForSearch$ | async"
                      [pageIndex]="pageNumber - 1"
                      [pageSize]="pageSize"
                      [pageSizeOptions]="pageSizeOptions"
                      [showFirstLastButtons]="true"
                      (page)="onPage($event)"
                      aria-label="Select page">
      </mat-paginator>
    </div>

  </div>
</div>