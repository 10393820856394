<div mat-dialog-title>
    <h1>Season Re-Activated</h1>
</div>

<mat-dialog-content>
    <span>You have reactivated bobby calf pick-ups for your property '{{propertyName}}'. You had previously indicated that this property doesn't require any further bobby calf pick-ups.</span>
    <br>
    <br>
    <span>Now you'll be able to request pick-ups and ANZCO will start sending you reminder notifications again. </span>
    <br>
    <br>
    <span>Thank you for doing business with ANZCO.</span>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button
            (click)="ok()"
            data-cy="confirmationOkButton">
        OK
    </button>
</mat-dialog-actions>