import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Model } from '../interfaces';
import { userFeatureKey, UserState } from './reducer';
import { ComplianceStatus } from 'src/app/features/compliance/compliance-status.enum';

const getUserFeatureState = createFeatureSelector<UserState>(userFeatureKey);

export const selectUser = createSelector(
  getUserFeatureState,
  (state) => state.user
);

export const selectUserName = createSelector(selectUser, (user) =>
  user ? user.name : ''
);

export const selectRegistrationRequired = createSelector(
  selectUser,
  (user) => user && !user.isActive
);

export const selectCompleteRegistrationRequired = createSelector(
  selectUser,
  selectRegistrationRequired,
  (user, registrationRequired) =>
    registrationRequired && !user.hasRegistrationPendingApproval
);

export const selectRegistrationPendingApproval = createSelector(
  selectUser,
  selectRegistrationRequired,
  (user, registrationRequired) =>
    registrationRequired && user.hasRegistrationPendingApproval
);

export const selectNewRegistration = createSelector(
  selectUser,
  (user) => user && user.newRegistration
);

export const selectShouldDisplayAdminMenu = createSelector(
  selectUser,
  (user: Model.User) =>
    user ? user.isActive && user.isAdmin && !user.isUser : false
);

export const selectShouldDisplayUserMenu = createSelector(
  selectUser,
  (user: Model.User) =>
    user ? user.isActive && user.isUser && user.hasPropertyAccess : false
);

export const selectShouldDisplayAdminUserMenu = createSelector(
  selectUser,
  (user: Model.User) => (user ? user.isAdmin : false)
);

export const selectShouldDisplayUserLogoutMenu = createSelector(
  selectUser,
  (user: Model.User) => user && !user.isAdmin
);

export const selectShouldDisplayOwnerMenu = createSelector(
  selectUser,
  (user: Model.User) =>
    user ? user.isActive && user.isUser && user.isOwner : false
);

export const selectShouldDisplayReportsMenu = createSelector(
  selectUser,
  (user: Model.User) => (user ? user.hasReportingAccess : false)
);

export const selectShouldDisplayBobbyMenu = createSelector(
  selectUser,
  (user: Model.User) => (user ? user.hasBobbyCalvesAccess : false)
);

export const selectShouldDisplayComplianceMenu = createSelector(
  selectUser,
  (user: Model.User) => user ?
    (user.mostSevereComplianceStatus !== ComplianceStatus.NoCompliance &&
      user.mostSevereComplianceStatus !== ComplianceStatus.NoAccess) :
    false
);

export const selectShouldDisplayDocumentsMenu = createSelector(
  selectUser,
  (user: Model.User) => user ? user.suppliedEverBobbyCalves : false,
);

export const selectShouldHideMenu = createSelector(
  getUserFeatureState,
  (state) => !state.displayMenu
);

export const selectOutstandingBobbyDeclarations = createSelector(
  getUserFeatureState,
  (state) => (state.declarations && state.declarations.show ?
    state.declarations.expected.filter(exp => !exp.hasBeenPickedUp).length :
    0),
);

export const selectBobbyTallies = createSelector(
  getUserFeatureState,
  (state) => state.bobbyTallies
    .map(tally => {
      const totalTally: Model.TotalTally = {
        anzcoPropertyId: tally.anzcoPropertyId,
        propertyName: tally.propertyName,
        totalTally: tally.totalTally,
        usedTally: tally.usedTally,
        percentage: tally.usedTally > tally.totalTally ?
          100 :
          (100 * tally.usedTally) / tally.totalTally,
      }
      return totalTally;
    })
    .sort((totalTallyA: Model.TotalTally, totalTallyB: Model.TotalTally) =>
      totalTallyA.propertyName.localeCompare(totalTallyB.propertyName)),
);

export const selectSummedBobbyTallies = createSelector(
  getUserFeatureState,
  (state) => {
    if (state.bobbyTallies.length > 1) {
      const summedTally: number = state.bobbyTallies.reduce((previous, current) => previous + current.totalTally, 0);
      const summedUsedTally: number = state.bobbyTallies.reduce((previous, current) => previous + current.usedTally, 0);
      const summedPercentage: number = summedUsedTally > summedTally ?
        100 :
        (100 * summedUsedTally) / summedTally;

      return {
        summedTally,
        summedUsedTally,
        summedPercentage,
      }
    }
    return null;
  }
);