import { Component } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-compliance-certified-tile',
  templateUrl: './compliance-certified-tile.component.html',
  styleUrls: ['./compliance-tile.component.scss'],
})
export class ComplianceCertifiedTileComponent {
  constructor(
    public readonly nav: NavigationService,
  ) {}

  public goCompliance(): void {
    this.nav.compliance();
  }
}
