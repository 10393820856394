import { createFeatureSelector, createSelector } from "@ngrx/store";
import { NewsType } from "src/app/miscellaneous/enums/news-type.enum";
import { Model } from "../interfaces";
import { newsFeatureKey } from "./reducer";

const getNewsState = createFeatureSelector<Model.NewsState>(newsFeatureKey);

export const selectNewsOverview = createSelector(
  getNewsState,
  (state: Model.NewsState) => state.overview
);

export const selectCurrentNewsItem = createSelector(getNewsState, (state: Model.NewsState) => state.currentNewsItem);

export const selectErrorLoading = createSelector(getNewsState, (state: Model.NewsState) => state.errorLoading);

export const selectOverviewLoading = createSelector(selectNewsOverview, selectErrorLoading, (overview, error) => !(overview || error) );

export const selectCurrentNewsFeed =  createSelector(getNewsState, selectErrorLoading, (state, error) => {
  if (state.currentNewsFeed){
    return state.currentNewsFeed;
  }

  return error ? null : getNewsOverviewFeed(state.overview, state.initialNewsFeedType);
});

export const selectRelatedNewsItems = createSelector(
  selectNewsOverview,
  selectCurrentNewsItem,
  (newsOverview: Model.NewsOverview, currentNewsItem: Model.NewsItem) => {
    const newsFeed: Model.NewsFeed = getNewsOverviewFeed(newsOverview, currentNewsItem?.newsType);
    return newsFeed?.items.filter(item => item.id != currentNewsItem?.id) ?? [];
  }
);

export const selectInitialNewsFeed = (newsType: NewsType) => {
  return createSelector(
    selectNewsOverview,
    (newsOverview) => {
      return getNewsOverviewFeed(newsOverview, newsType);
    }
  );
}

function getNewsOverviewFeed(newsOverview: Model.NewsOverview, newsType: NewsType) {
  let newsFeed: Model.NewsFeed;
  switch (newsType) {
    case NewsType.AnzcoUpdate:
      newsFeed = newsOverview?.anzcoUpdate;
      break;

    case NewsType.ImportantInfo:
      newsFeed = newsOverview?.importantInfo;
      break;

    case NewsType.News:
      newsFeed = newsOverview?.news;
      break;

    default:
      newsFeed = null;
      break;
  }

  return newsFeed;
}
