<div *ngIf="advices">
  <div class="headline">
    <h2>My Recent Processing</h2>
    <button mat-raised-button class="cancel-button" (click)="goToViewAll()">
      View More &#62;
    </button>
  </div>

  <app-advice-cards *ngIf="advices && advices.length > 0" [advices]="advices"></app-advice-cards>

  <p *ngIf="advices && advices.length === 0">
    Your properties do not have any processing data available.
    <br />
    If you have any questions please contact the Livestock Admin Team on
    <a href="mailto:support@anzcoproducers.co.nz">
      support@anzcoproducers.co.nz
    </a>
  </p>
</div>
