import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Role, RoleEnum } from "src/app/miscellaneous/enums/role.enum";
import { ConfirmationDialogComponent } from "../confimation-dialog/index";
import { allStaffManagementRoute, roleDescriptionRoute } from "./constants";
import { Model, Projection } from "./interfaces";
import { addStaffStarted, changeStaffStarted, loadOwnerPropertiesStarted, removeStaffStarted, selectStaff, updateStaffDetail, updateStaffRole } from "./state/actions";
import { Selector } from "./state/selector";

@Component({
    selector: "app-staff-management",
    templateUrl: "./staff-management.component.html",
    styleUrls: ["./staff-management.component.scss"],
})
export class StaffManagementComponent implements OnInit, OnDestroy {
    public readonly staffTableColumns: string[] = [
        "propertyName",
        "role",
        "isActive",
    ];
    public readonly allRoles: RoleEnum[] = RoleEnum.all().filter(role => role.role !== Role.Owner);

    private readonly destroy$ = new Subject();
    public staff: Projection.Staff;
    public disbaleEditedStaffFields: boolean;
    public headline: string;
    public validFirstName: boolean;
    public validSurname: boolean;
    public validEmail: boolean;
    public uniqueEmail: boolean;
    public submitValid: boolean;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly dialog: MatDialog,
        private readonly store: Store,
        private readonly selector: Selector,
    ) { }

    public ngOnInit(): void {
        this.route.queryParams
            .subscribe(params => {
                const userId: number = parseInt(params["userId"]);

                this.store.select(this.selector.selectPropertiesLoaded)
                    .subscribe((loaded: boolean) => {
                        if (!loaded) {
                            this.store.dispatch(loadOwnerPropertiesStarted());
                        }
                    })
                    .unsubscribe();
                if (userId) {
                    this.store.select(this.selector.selectStaff)
                        .subscribe((staff: Projection.Staff) => {
                            if (!staff.userId) {
                                this.store.dispatch(selectStaff({ userId }));
                            }
                        })
                        .unsubscribe();
                }
            })
            .unsubscribe();

        this.store.select(this.selector.selectStaff)
            .pipe(takeUntil(this.destroy$))
            .subscribe((staff: Projection.Staff) => this.staff = staff);

        this.store.select(this.selector.selectDisbaleEditedStaffFields)
            .pipe(takeUntil(this.destroy$))
            .subscribe((disbale: boolean) => this.disbaleEditedStaffFields = disbale);

        this.store.select(this.selector.selectStaffManagementHeadline)
            .pipe(takeUntil(this.destroy$))
            .subscribe((headline: string) => this.headline = headline);

        this.store.select(this.selector.selectValidFirstName)
            .pipe(takeUntil(this.destroy$))
            .subscribe((validFirstName: boolean) => this.validFirstName = validFirstName);

        this.store.select(this.selector.selectValidSurname)
            .pipe(takeUntil(this.destroy$))
            .subscribe((validSurname: boolean) => this.validSurname = validSurname);

        this.store.select(this.selector.selectValidEmail)
            .pipe(takeUntil(this.destroy$))
            .subscribe((validEmail: boolean) => this.validEmail = validEmail);

        this.store.select(this.selector.selectEmailUniqueEmail)
            .pipe(takeUntil(this.destroy$))
            .subscribe((unique: boolean) => this.uniqueEmail = unique);

        this.store.select(this.selector.selectSubmitValid)
            .pipe(takeUntil(this.destroy$))
            .subscribe((submitValid: boolean) => this.submitValid = submitValid);
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public updateStaffDetail(update: Partial<Model.Staff>): void {
        this.store.dispatch(updateStaffDetail({ update }));
    }

    public updateStaffRole(propertyId: number, role: Role): void {
        this.store.dispatch(updateStaffRole({
            propertyId,
            isActive: role !== Role.Unknown,
            role,
        }));
    }

    public updateStaffRoleActive(propertyId: number, role: Role, isActive: boolean): void {
        this.store.dispatch(updateStaffRole({
            propertyId,
            isActive,
            role,
        }));
    }

    public roleDescriptions(): void {
        this.router.navigate([roleDescriptionRoute]);
    }

    public removeStaff(): void {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                title: "Remove Staff",
                text: "Do you want to remove this staff member from all your properties?",
            },
        });
        dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.store.dispatch(removeStaffStarted({ userId: this.staff.userId }));
                this.router.navigate([allStaffManagementRoute]);
            }
        });
    }

    public cancel(): void {
        this.router.navigate([allStaffManagementRoute]);
    }

    public submit(): void {
        this.store
            .select(this.selector.selectEditedStaff)
            .subscribe((staff: Model.Staff) => {
                if (this.staff.userId) {
                    this.store.dispatch(changeStaffStarted({ staff }));
                }
                else {
                    this.store.dispatch(addStaffStarted({ staff }));
                }
            })
            .unsubscribe();
    }
}