import { NgModule } from "@angular/core";
import { legalRoute } from "./constants";
import { FooterComponent } from "./footer.component";
import { LegalComponent } from "./legal.component";

export { FooterComponent };
export { LegalComponent };
export { legalRoute };

@NgModule()
export class FooterModule {}