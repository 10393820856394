<ng-container *ngIf="overviewLoading$ | async">
  <div class="headline">
    <h2>Latest Articles</h2>
  </div>
  <div>
    <mat-card [appLoader]="{show: true, noBackgroundOverlay: true, loadingText: 'Loading...'}">
    </mat-card>
  </div>
</ng-container>

<app-news-overview-shell [useCompactView]="true"></app-news-overview-shell>
