import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AdviceStatus, AdviceStatusEnum } from "src/app/miscellaneous/enums/advice-status.enum";
import { StockType, StockTypeEnum } from "src/app/miscellaneous/enums/stock-type.enum";
import { Model, Projection } from "../interfaces";
import { advicesFeatureKey } from "./reducer";

const getAdvicesState = createFeatureSelector<Model.AdvicesState>(advicesFeatureKey);

export const selectRecentAdvices = createSelector(
    getAdvicesState,
    (state: Model.AdvicesState) => {
        const result: Projection.Advice[] = state.recentAdvices.map(modelAdvice => mapAdvice(modelAdvice));
        return result;
    },
);

export const selectAdviceSearchResults = createSelector(
    getAdvicesState,
    (state: Model.AdvicesState) => {
        const result: Projection.Advice[] = state.adviceSearchResults.map(modelAdvice => mapAdvice(modelAdvice));
        return result;
    },
);

export const selectAdviceFilterOptions = createSelector(
    getAdvicesState,
    (state: Model.AdvicesState) => {
        const projection: Projection.AdvicesFilterOptions = {
            stockTypes: state.filterOptions.stockTypes
                .map(stockType => StockTypeEnum.fromEnum(stockType))
                .sort((stockTypeA, stockTypeB) => stockTypeA.orderNumber - stockTypeB.orderNumber),
            properties: state.filterOptions.properties,
            adviceStatuses: state.filterOptions.adviceStatuses
                .map(status => AdviceStatusEnum.fromEnum(status))
                .filter(status => status !== AdviceStatusEnum.Unknown)
                .sort((statusA, statusB) => statusA.adviceStatus - statusB.adviceStatus),
        };
        
        return projection;
    }
);

export const selectTotalAdvicesForSearch = createSelector(
    getAdvicesState,
    (state: Model.AdvicesState) => state.totalAdvicesForSearch,
);

export const selectAdvicesQuery = createSelector(
    getAdvicesState,
    (state: Model.AdvicesState) => state.filter,
);
    
export const selectSelectedStockTypes = createSelector(
    getAdvicesState,
    (state: Model.AdvicesState) => {
        const allStockTypesSelected: boolean = state.filter.stockTypes.length === state.filterOptions.stockTypes.length;
        const result: StockType[] = allStockTypesSelected ?
            state.filter.stockTypes.concat(0) :
            state.filter.stockTypes;

        return result;
    },
);

export const selectSelectedProperty = createSelector(
    getAdvicesState,
    (state: Model.AdvicesState) =>
        state.filter.propertyId ?
        state.filter.propertyId :
            state.filterOptions.properties.length === 1 ?
            state.filterOptions.properties[0].propertyId :
            0,
);

export const selectSelectedStatuses = createSelector(
    getAdvicesState,
    (state: Model.AdvicesState) => {
        const allStatutesSelected: boolean = state.filter.adviceStatuses.length === state.filterOptions.adviceStatuses.length;
        const result: AdviceStatus[] = allStatutesSelected ?
            state.filter.adviceStatuses.concat(10) :
            state.filter.adviceStatuses;

        return result;
    },
);

export const selectSelectedStockTypesList = createSelector(
    getAdvicesState,
    (state: Model.AdvicesState) => {
        const allStockTypesSelected: boolean =
            state.filter.stockTypes.length === state.filterOptions.stockTypes.length;
        const result: string =
            state.filterOptions.stockTypes.length === 1 ?
            StockTypeEnum.fromEnum(state.filterOptions.stockTypes[0]).description :
            allStockTypesSelected ?
                "All Stock Types" :
                state.filter.stockTypes
                    .map((stockType: StockType) => StockTypeEnum.fromEnum(stockType))
                    .sort((stockTypeA: StockTypeEnum, stockTypeB: StockTypeEnum) => stockTypeA.orderNumber - stockTypeB.orderNumber)
                    .map((stockType: StockTypeEnum) => stockType.description)
                    .join(", ");

        return result;
    },
);

export const selectSelectedStatusesList = createSelector(
    getAdvicesState,
    (state: Model.AdvicesState) => {
        const allStatusesSelected: boolean =
            state.filter.adviceStatuses.length === state.filterOptions.adviceStatuses.length;
        const result: string = 
            state.filterOptions.adviceStatuses.length === 1 ?
            AdviceStatusEnum.fromEnum(state.filterOptions.adviceStatuses[0]).description :
            allStatusesSelected ?
                "All Statuses" :
                state.filter.adviceStatuses
                    .map((status: AdviceStatus) => AdviceStatusEnum.fromEnum(status))
                    .filter(status => status !== AdviceStatusEnum.Unknown)
                    .sort((statusA, statusB) => statusA.adviceStatus - statusB.adviceStatus)
                    .map((status: AdviceStatusEnum) => status.description)
                    .join(", ");

        return result;
    },
);

export const selectPageNumber = createSelector(
    getAdvicesState,
    (state: Model.AdvicesState) => state.filter.pageNumber,
);
    
export const selectPageSize = createSelector(
    getAdvicesState,
    (state: Model.AdvicesState) => state.filter.pageSize,
);

export const selectShowFilter = createSelector(
    getAdvicesState,
    (state: Model.AdvicesState) => state.showFilter,
);

export const selectSelectedAdvice = createSelector(
    getAdvicesState,
    (state: Model.AdvicesState) =>
        state.selectedAdvice ?
            mapAdvice(state.selectedAdvice) :
            null,
);

const mapAdvice = function(modelAdvice: Model.Advice): Projection.Advice {
    const projection: Projection.Advice = {
        advice: modelAdvice.advice,
        adviceStatus: AdviceStatusEnum.parse(modelAdvice.adviceStatus),
        invoiceFileId: modelAdvice.invoiceFile,
        propertyId: modelAdvice.propertyId,
        propertyName: modelAdvice.propertyName,
        season: modelAdvice.season,
        isGrazing: modelAdvice.isGrazing,
        cardBackgroundClass: AdviceStatusEnum.cardCssClass(modelAdvice.adviceStatus),
        hasCarcaseFiles: modelAdvice.adviceLines.some(line => line.carcaseFile && line.carcaseFile.length > 1),
        feedbackComments: modelAdvice.adviceLines.some(line => line.feedbackComments) ?
            modelAdvice.adviceLines
                .filter(adviceLine => adviceLine.feedbackComments)
                .map(adviceLine => adviceLine.feedbackComments)
                .join(', ') :
            '',
        isBobbyAdvice: modelAdvice.adviceLines
            .some(line => line.stockType === StockType.BobbyCalf),
        bookingType: modelAdvice.bookingType,
        adviceLines: modelAdvice.adviceLines
            .slice()
            .sort((lineA, lineB) =>
                lineA.killDate.getTime() > lineB.killDate.getTime() ? 1 :
                    lineA.killDate.getTime() === lineB.killDate.getTime() ? 0 : -1)
            .sort((lineA, lineB) =>
                StockTypeEnum.fromEnum(lineA.stockType).orderNumber -
                StockTypeEnum.fromEnum(lineB.stockType).orderNumber)
            .map((modelAdviceLine, index, orderedArray) => {
                const lineProjection: Projection.AdviceLine = {
                    killDate: modelAdviceLine.killDate,
                    averageWeight: modelAdviceLine.averageWeight,
                    killedPaidWeight: modelAdviceLine.killedPaidWeight,
                    paidPerHead: modelAdviceLine.paidPerHead,
                    paidPricePerKg: modelAdviceLine.paidPricePerKg,
                    killDollarPerKg: modelAdviceLine.killDollarPerKg,
                    tally: modelAdviceLine.tally,
                    condemnedTally: modelAdviceLine.condemnedTally,
                    totalNettAmount: modelAdviceLine.totalNettAmount,
                    stockType: StockTypeEnum.parse(modelAdviceLine.stockType),
                    carcaseFileId: index > 0 ?
                        (orderedArray[0].killDate.getTime() === modelAdviceLine.killDate.getTime() ? null : modelAdviceLine.carcaseFile) :
                        modelAdviceLine.carcaseFile,
                    imageSrc: StockTypeEnum.sourceImage(modelAdviceLine.stockType),
                };

                return lineProjection;
            }),
        booking: modelAdvice.booking,
        declaration: modelAdvice.declaration ?
            {
                tally: modelAdvice.declaration.tally,
                recordedBy: modelAdvice.declaration.recordedBy,
                timeOfLastFeed: modelAdvice.declaration.timeOfLastFeed,
            } :
            null,
        pickup: modelAdvice.pickup ?
            {
                tally: modelAdvice.pickup.tally,
                rejected: modelAdvice.pickup.numberRejectedNoTag +
                    modelAdvice.pickup.numberRejectedOther +
                    modelAdvice.pickup.numberRejectedScours +
                    modelAdvice.pickup.numberRejectedWeak +
                    modelAdvice.pickup.numberRejectedWet +
                    modelAdvice.pickup.numberRejectedWetNavel,
                driverName: modelAdvice.pickup.driverName,
                transportName: modelAdvice.pickup.transportName,
                pickedUpTime: modelAdvice.pickup.pickedUpTime,
                rejectReasons: getDisplayableRejectReasons(modelAdvice.pickup),
            } :
            null,
            bookingDisplayMessage: modelAdvice.bookingDisplayMessage,
            declarationDisplayMessage: modelAdvice.declarationDisplayMessage,
            pickupDisplayMessage: modelAdvice.pickupDisplayMessage,
    };

    return projection;
}

function getDisplayableRejectReasons(
  pickup: Model.BookingByKillDatePickedUpResponse
): string {
  const allRejectReasons = [
    getDisplayableRejectReason('No Tag', pickup.numberRejectedNoTag),
    getDisplayableRejectReason('Weak', pickup.numberRejectedWeak),
    getDisplayableRejectReason('Wet', pickup.numberRejectedWet),
    getDisplayableRejectReason('Wet Navel', pickup.numberRejectedWetNavel),
    getDisplayableRejectReason('Scours', pickup.numberRejectedScours),
    getDisplayableRejectReason(
      pickup.rejectedOtherNote || 'Other',
      pickup.numberRejectedOther
    ),
  ]
    .filter((reason) => reason !== '')
    .join(', ');

  return allRejectReasons || '-';
}

function getDisplayableRejectReason(
  reason: string,
  numberOfRejects: number
): string {
  if (numberOfRejects === 0) {
    return '';
  }

  if (numberOfRejects === 1) {
    return reason;
  }

  return `${numberOfRejects} x ${reason}`;
}
