<div
  data-cy="home-page-shell"
  class="home-container"
  *ngIf="user">
  <div class="background-section">
    <div class="background-container">
      <app-recent-advices></app-recent-advices>
    </div>
  </div>
  <div class="background-section" *ngIf="user.hasBobbyCalvesAccess && errored === false">
    <div class="background-container">
      <div class="headline">
        <h2>Bobby Calf {{ currentYear }} Summary</h2>
      </div>
      <p>
        Below is the total number of Bobby Calves processed vs
        the total estimated calves on your ASD this season.
      </p>
      <app-bobby-calves-progress-tile
        *ngIf="user.hasBobbyCalvesAccess">
      </app-bobby-calves-progress-tile>
    </div>
  </div>
  <div class="background-section advantage">
    <div class="background-container">
      <div class="headline">
        <h2>Other Services</h2>
      </div>
      <div
        class="other-services">
        <app-compliance-outage-tile *ngIf="shouldShowNzfaiOutageTile()"></app-compliance-outage-tile>
        <app-no-compliance-tile *ngIf="shouldShowNzfaiNoComplianceTile()"></app-no-compliance-tile>
        <app-compliance-certified-tile *ngIf="shouldShowNzfaiCertifiedTile()"></app-compliance-certified-tile>
        <app-compliance-action-required-tile *ngIf="shouldShowNzfaiActionRequiredTile()"></app-compliance-action-required-tile>
        <app-myospri-tile></app-myospri-tile>
        <app-advantage-tile *ngIf="user.canAccessAnzcoAdvantage"></app-advantage-tile>
      </div>
    </div>
  </div>
  <div class="background-section">
    <div class="background-container">
      <app-news-homepage-widget></app-news-homepage-widget>
    </div>
  </div>
</div>
