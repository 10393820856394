import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { showFilter } from "../state/actions";

@Injectable({ providedIn: 'root' })
export class FilterToggle {
    private readonly updatesStateKey: string = 'showAdviceFilter';

    constructor(private readonly store: Store) { }

    public init(): void {
        const showAdviceFilter: string = localStorage.getItem(this.updatesStateKey);
        this.store.dispatch(showFilter({ show: showAdviceFilter === 'true' || showAdviceFilter === null }));
    }

    public showFilter(): void {
        localStorage.setItem(this.updatesStateKey, "true");
        this.store.dispatch(showFilter({ show: true }));
    }

    public hideFilter(): void {
        localStorage.setItem(this.updatesStateKey, "false");
        this.store.dispatch(showFilter({ show: false }));
    }
}