import { createAction, props } from '@ngrx/store';
import { Model } from '../models';

const TYPE = '[Pickup Declarations]';

export const pickupDeclarationErrored = createAction(
  `${TYPE} Errored`,
    props<{ errored: boolean }>());

export const selectBooking = createAction(
  `${TYPE} Select Booking`,
    props<{
      propertyId: number,
      bookingId: string,
    }>());

export const selectSelectableBooking = createAction(
  `${TYPE} Select Selectable Property`,
    props<{
      propertyId: number | null,
      bookingId: string | null,
    }>());

export const selectReadOnlyBooking = createAction(
  `${TYPE} Select ReadOnly Booking`,
    props<{
      propertyId: number,
      bookingId: string,
    }>());

export const updateDeclarationfDetail = createAction(
  `${TYPE} Update Declaration Detail`,
  props<{ update: Partial<Model.SelectedBookingDeclaration> }>());

export const toggleDeclarationWelfare = createAction(
  `${TYPE} Toggle Declaration Welfare`);

export const decreaseTally = createAction(
  `${TYPE} Decrease Tally`);

export const increaseTally = createAction(
  `${TYPE} Increase Tally`);