import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import moment from 'moment';
import { BlobSaver } from "src/app/miscellaneous/file-downloader";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: 'root' })
export class CommandGetInvoice {
    constructor(
        private readonly http: HttpClient,
    ) {}

    public async Retrieve(
        fileId: string,
        advice: number,
        season: number,
        propertyId: number,
        killDate: Date,
        isStatement: boolean,
    ): Promise<void> {
        const res = await this.RequestInvoiceFile(fileId);
        const date = moment(killDate).format("YYYYMMDD");
        const fileName = (isStatement ? "STMT-" : "INV-") + season + "-" + advice + "-" + propertyId + "-" + date + ".pdf";

        BlobSaver.saveFile(res, fileName);
    }

    private async RequestInvoiceFile(fileId: string): Promise<Blob> {
        const params: any = { fileId };
        const pdfBlob = await this.http
            .get(
                environment.apiUrl + "/api/documents/invoice-file",
                {
                    responseType: "blob",
                    params,
                })
            .toPromise();

        return pdfBlob as Blob;
    }
}