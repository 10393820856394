import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject  } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Model, selectUser } from 'src/app/user';
import { selectErrored } from '../../bobby-calves/state/selectors';
import { ComplianceStatus } from '../../compliance/compliance-status.enum';

@Component({
  selector: 'app-homepage-shell',
  templateUrl: './homepage-shell.component.html',
  styleUrls: ['./homepage-shell.scss']
})
export class HomepageShellComponent implements OnInit {
  private readonly destroy$ = new Subject();
  public user: Model.User;
  public errored: boolean;
  public currentYear: number = new Date().getFullYear();

  constructor(
    private readonly store: Store,
  ) { }

  public ngOnInit(): void {
    this.store
      .select(selectUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => this.user = user);
    this.store
      .select(selectErrored)
      .pipe(takeUntil(this.destroy$))
      .subscribe(errored => this.errored = errored);
  }

  public shouldShowNzfaiOutageTile(): boolean {
    return this.user &&
      (this.user.complianceServiceOutage ||
        this.user.mostSevereComplianceStatus === ComplianceStatus.NoAccess);
  }

  public shouldShowNzfaiNoComplianceTile(): boolean {
    return this.user &&
      !this.user.complianceServiceOutage &&
      this.user.mostSevereComplianceStatus === ComplianceStatus.NoCompliance;
  }

  public shouldShowNzfaiCertifiedTile(): boolean {
    return this.user &&
      !this.user.complianceServiceOutage &&
      this.user.mostSevereComplianceStatus === ComplianceStatus.Certified;
  }

  public shouldShowNzfaiActionRequiredTile(): boolean {
    return this.user &&
      !this.user.complianceServiceOutage &&
      this.user.mostSevereComplianceStatus !== ComplianceStatus.NoCompliance &&
      this.user.mostSevereComplianceStatus !== ComplianceStatus.Certified &&
      this.user.mostSevereComplianceStatus !== ComplianceStatus.NoAccess;
  }
}