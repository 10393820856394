import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectCompleteRegistrationRequired, selectRegistrationPendingApproval } from '../../../user/index';

@Component({
  selector: 'app-registration-shell',
  templateUrl: './registration-shell.component.html',
  styleUrls: ['./registration-shell.component.scss']
})
export class RegistrationShellComponent implements OnInit {
  constructor(private readonly store: Store) { }
  
  completeRegistrationRequired$: Observable<boolean>;
  registrationPendingApproval$: Observable<boolean>;

  ngOnInit(): void {
    this.completeRegistrationRequired$ = this.store.select(selectCompleteRegistrationRequired);
    this.registrationPendingApproval$ = this.store.select(selectRegistrationPendingApproval);
  }
}