<div class="default-container">
    <div class="headline">
        <h2>Pick-up Declaration</h2>
        <button mat-raised-button
                color="primary"
                (click)="back()">
            Back
        </button>
    </div>

    <mat-stepper
        *ngIf="shouldDisplayEditStage"
        labelPosition="bottom"
        [linear]="true"
        (selectionChange)="onStepChange($event)"
        #stepper>
        <mat-step *ngIf="shouldDisplayPropertySelection" label="Select Property">
            <h2 class="mobile">Select Property</h2>
            <app-pickup-declaration-property-selection></app-pickup-declaration-property-selection>
        </mat-step>
        <mat-step label="Declaration Details">
            <h2 class="mobile">Declaration Details</h2>
            <app-pickup-declaration-edit></app-pickup-declaration-edit>
            <div class="button-stpper-panel">
                <button
                    mat-button
                    matStepperNext
                    class="mat-raised-button"
                    [disabled]="invalidDeclaration">Next</button>
                <button
                    *ngIf="shouldDisplayPropertySelection"
                    mat-button
                    matStepperPrevious
                    class="mat-raised-button nav-button">Back</button>
            </div>
        </mat-step>
        <mat-step label="Summary">
            <h2 class="mobile">Summary</h2>
            <app-pickup-declaration-summary></app-pickup-declaration-summary>
            <div
                *ngIf="shouldDisplayEditStage"
                class="button-stpper-panel">
                <button
                    mat-button
                    class="mat-raised-button"
                    [disabled]="confirmationOutstanding"
                    (click)="confirm()">Confirm</button>
                <button
                    mat-button
                    matStepperPrevious
                    class="mat-raised-button nav-button">Back</button>
            </div>
        </mat-step>
        <ng-template matStepperIcon="done" let-index="index">
            {{(index+1) * 10}}
        </ng-template>
    </mat-stepper>

    <app-pickup-declaration-summary *ngIf="!shouldDisplayEditStage"></app-pickup-declaration-summary>
</div>