<div *ngIf="declaration">
  <div class="data-field">
    <mat-label>Property</mat-label>
    <mat-label>{{ declaration.propertyName }}</mat-label>
  </div>
  <div class="data-field">
    <mat-label>Pick-up Date</mat-label>
    <mat-label>{{ date }}</mat-label>
  </div>
  <div class="data-item-container">
    <div class="data-field data-field-no-data">
      <mat-label>Last Fed at</mat-label>
    </div>
    <div class="data-field sub-data-field switch-on-mobile">
      <mat-label>Hour</mat-label>
      <mat-button-toggle-group [value]="declaration.timeOfLastFeedHours.toString()">
        <mat-button-toggle value="5" (change)="onHourChanged(5)">
          <div class="time-button">5</div>
        </mat-button-toggle>
        <mat-button-toggle value="6" (change)="onHourChanged(6)">
          <div class="time-button">6</div>
        </mat-button-toggle>
        <mat-button-toggle value="7" (change)="onHourChanged(7)">
          <div class="time-button">7</div>
        </mat-button-toggle>
        <mat-button-toggle value="8" (change)="onHourChanged(8)">
          <div class="time-button">8</div>
        </mat-button-toggle>
        <mat-button-toggle value="9" (change)="onHourChanged(9)">
          <div class="time-button">9</div>
        </mat-button-toggle>
        <mat-button-toggle value="10" (change)="onHourChanged(10)">
          <div class="time-button">10</div>
        </mat-button-toggle>
        <mat-button-toggle value="11" (change)="onHourChanged(11)">
          <div class="time-button">11</div>
        </mat-button-toggle>
        <mat-button-toggle value="12" (change)="onHourChanged(12)">
          <div class="time-button">12</div>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="data-field sub-data-field switch-on-mobile">
      <mat-label>Minutes</mat-label>
      <mat-button-toggle-group [value]="declaration.timeOfLastFeedMinutes.toString()">
        <mat-button-toggle value="0" (change)="onMinutesChanged(0)">
          <div class="time-button">00</div>
        </mat-button-toggle>
        <mat-button-toggle value="15" (change)="onMinutesChanged(15)">
          <div class="time-button">15</div>
        </mat-button-toggle>
        <mat-button-toggle value="30" (change)="onMinutesChanged(30)">
          <div class="time-button">30</div>
        </mat-button-toggle>
        <mat-button-toggle value="45" (change)="onMinutesChanged(45)">
          <div class="time-button">45</div>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="labeled-data-item">
      <mat-label>
          Number of Calves
          <span *ngIf="!validCalves"
                class="validation-failure">
                * Required
          </span>
      </mat-label>
      <div>
        <button
            mat-button
            class="mat-raised-button nav-button"
            (click)="decreaseTally()">-</button>
        <input matInput
              type="number"
              min="0"
              max="99"
              [value]="declaration.tally"
              [class.validation-failure]="!validCalves"
              (keyup)="enforceMinMax($event.srcElement)"
              (input)="updateDeclaration({ tally: $event.srcElement.value })"/>
        <button
            mat-button
            class="mat-raised-button nav-button"
            (click)="increaseTally()">+</button>
      </div>
    </div>
    <div class="labeled-data-item">
      <mat-label>
          Contact Number
          <span *ngIf="!validContactNumber"
                class="validation-failure">
                * Required
          </span>
      </mat-label>
      <input matInput
             maxlength="100"
             [value]="declaration.contactNumber"
             [class.validation-failure]="!validContactNumber"
             (input)="updateDeclaration({ contactNumber: $event.srcElement.value })"/>
    </div>
    <div class="labeled-data-item">
      <mat-label>
          Completed By
          <span *ngIf="!validRecordedBy"
                class="validation-failure">
                * Required
          </span>
      </mat-label>
      <input matInput
             maxlength="100"
             [value]="declaration.recordedBy"
             [class.validation-failure]="!validRecordedBy"
             (input)="updateDeclaration({ recordedBy: $event.srcElement.value })"/>
    </div>
  </div>
</div>