import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { newsFeedRouteLinkPrefix, newsOverviewRoute } from '../constants';
import { Model } from '../interfaces';
import { loadNewsItem, loadNewsOverview } from '../state/actions';
import { selectCurrentNewsItem, selectRelatedNewsItems } from '../state/selectors';

@Component({
  selector: 'app-news-item-shell',
  templateUrl: './news-item-shell.component.html',
  styleUrls: ['./news-item-shell.component.scss']
})
export class NewsItemComponent implements OnInit, OnDestroy {
  public newsItem: Model.NewsItem;
  public relatedNews$: Observable<Model.NewsItemSummary[]>;
  public newsOverviewRoute: string = "/" + newsOverviewRoute;
  public newsFeedRoute: string;
  public newsFeedLinkText: string;
  private readonly destroy$ = new Subject();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly store: Store,
  ) { }

  public ngOnInit(): void {
    this.relatedNews$ = this.store.select(selectRelatedNewsItems);
    this.store.dispatch(loadNewsOverview());

    this.route
      .paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe(params => this.store.dispatch(loadNewsItem({ id: params.get('id')! })));

    this.store.select(selectCurrentNewsItem)
      .pipe(takeUntil(this.destroy$))
      .subscribe(newsItem => this.onNewsItemLoad(newsItem));
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private onNewsItemLoad(newsItem: Model.NewsItem): void {
    this.newsItem = newsItem;

    if (newsItem) {
      this.newsFeedRoute = newsFeedRouteLinkPrefix + newsItem.newsType;
    }
  }
}