<div
  class="default-container"
  *ngIf="reportQuery"
  data-cy="reporting-page-shell"
>
  <div class="headline">
    <h2>Reporting</h2>
  </div>

  <form>
    <div class="filter-container">
      <div mat-form-field class="labeled-data-item date-picker">
        <mat-label><strong>Report name</strong></mat-label>
        <p class="info-form-item">End of Year Financial Report</p>
      </div>

      <div mat-form-field class="labeled-data-item date-picker">
        <mat-label> Date From </mat-label>
        <div (click)="fromPicker.open()">
          <input
            data-cy="startDate"
            matInput
            name="startDate"
            #startDate="ngModel"
            [ngModel]="reportQuery.startDate"
            (dateChange)="updateFilter({ startDate: $event.value?.toDate() })"
            [matDatepicker]="fromPicker"
          />
          <mat-datepicker-toggle matSuffix [for]="fromPicker">
          </mat-datepicker-toggle>
          <mat-datepicker #fromPicker></mat-datepicker>
        </div>
      </div>

      <div class="labeled-data-item date-picker">
        <mat-label>Date To</mat-label>
        <div (click)="toPicker.open()">
          <input
            data-cy="endDate"
            matInput
            name="endDate"
            #endDate="ngModel"
            (dateChange)="updateFilter({ endDate: $event.value?.toDate() })"
            [ngModel]="reportQuery.endDate"
            [matDatepicker]="toPicker"
          />
          <mat-datepicker-toggle matSuffix [for]="toPicker">
          </mat-datepicker-toggle>
          <mat-datepicker #toPicker></mat-datepicker>
        </div>
      </div>

      <div class="labeled-data-item drop-down">
        <mat-label>Property</mat-label>
        <mat-select
          data-cy="propertyDropdown"
          multiple
          name="properties"
          [(ngModel)]="selectedProperties"
          [disabled]="properties.length === 1"
        >
          <mat-select-trigger>
            <span>{{ selectedPropertiesList }}</span>
          </mat-select-trigger>
          <mat-option (click)="toggleAllProperties()" [value]="0">
            Select All
          </mat-option>
          <mat-option
            *ngFor="let property of properties"
            [value]="property.propertyId"
            (click)="toggleProperty(property.propertyId)"
          >
            {{ property.propertyName }}
          </mat-option>
        </mat-select>
      </div>

      <div class="labeled-data-item">
        <button
          data-cy="clearFilterButton"
          mat-raised-button
          color="primary"
          (click)="clearFilter()"
        >
          Clear Filter
        </button>
      </div>

      <div class="labeled-data-item">
        <button
          data-cy="downloadButton"
          mat-raised-button
          color="primary"
          [disabled]="!submitReady"
          (click)="download()"
        >
          Download
        </button>
      </div>
    </div>

    <mat-error *ngIf="!submitReady">
      'Date From' must be before 'Date To'.
    </mat-error>

    <mat-error *ngIf="reportDownloadError$ | async as reportDownloadError">
      {{ reportDownloadError }}
    </mat-error>
  </form>

  <p>
    This report provides a financial summary of tallies, weights, and payments
    for the period in descending date order for the property(s) selected by
    advice number. There is a separate tab for each stock type and ovine and/or
    bovine totals.
  </p>
</div>
