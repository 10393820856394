<h1>New User</h1>
<app-loader *ngIf="createUser.loading$ | async"></app-loader>
<app-user-form
  (userDetails)="userDetails$.next($event)"
  (searchChanged)="onSearchChanged($event)"
  [disabled]="isConfirmed"
></app-user-form>
<app-property-role-form
  [propertySearchTerm]="search?.searchTerm"
  [producerCodeSearch]="search?.producerCode"
  (propertiesChanged)="propertyRoles$.next($event)"
  [disabled]="isConfirmed"
></app-property-role-form>
<mat-action-list>
  <button mat-raised-button (click)="onCancel()">Cancel</button>
  <button
    *ngIf="showConfirmed"
    mat-raised-button
    color="accent"
    (click)="onConfirmed()"
    [disabled]="!isFormValid"
  >
    Confirm
  </button>
  <button
    *ngIf="isConfirmed"
    mat-raised-button
    color="accent"
    (click)="onSubmitted()"
  >
    Submit
  </button>
</mat-action-list>
