import { Component, Input } from '@angular/core';
import { Projection } from '../models';
import { FormatDatePipe } from 'src/app/miscellaneous/pipes/format-date.pipe';

@Component({
  selector: 'app-bobby-calves-upcoming-card',
  templateUrl: './bobby-calves-upcoming-card.component.html',
  styleUrls: ['./bobby-calves-upcoming-card.component.scss'],
})
export class BobbyCalvesUpcomingCardComponent {
  @Input() pickup: Projection.Pickup;

  constructor(private readonly dateFormatter: FormatDatePipe) {}

  public get date(): string {
    const pickupDateStr = this.dateFormatter.transform(
      this.pickup.date,
      'dwdm'
    );
    return pickupDateStr;
  }

  public get bookingOpenDate(): string {
    const bookingOpenDatetr = this.dateFormatter.transform(
      this.pickup.bookingsOpenAt,
      'dwdm'
    );
      return bookingOpenDatetr;
  }
}
