import { Component } from '@angular/core';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-compliance-action-required-tile',
  templateUrl: './compliance-action-required-tile.component.html',
  styleUrls: ['./compliance-tile.component.scss'],
})
export class ComplianceActionRequiredTileComponent {
  constructor(
    public readonly nav: NavigationService,
  ) {}

  public goCompliance(): void {
    this.nav.compliance();
  }
}