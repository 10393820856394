<div class="web" (click)="fillOutDeclarations()">
  <div>
    <i class="bts bt-exclamation-triangle bt-3x"></i>
  </div>
  <div>
    <mat-label *ngIf="outstandingBobbyDeclarations === 1">Bobby Calf Pick-Up Declaration is ready to be completed</mat-label>
    <mat-label *ngIf="outstandingBobbyDeclarations !== 1">{{ outstandingBobbyDeclarations }} Bobby Calf Pick-Up Declarations are ready to be completed</mat-label>
    <mat-label class="link-like">Click here to complete</mat-label>
  </div>
</div>
<div class="mobile" (click)="fillOutDeclarations()">
  <div class="attention-bar"></div>
  <div class="mobile-content">
    <div>
      <i class="bts bt-exclamation-triangle bt-2x"></i>
    </div>
    <div>
      <mat-label *ngIf="outstandingBobbyDeclarations === 1">Bobby Calf Pick-Up Declaration is ready to be completed</mat-label>
      <mat-label *ngIf="outstandingBobbyDeclarations !== 1">{{ outstandingBobbyDeclarations }} Bobby Calf Pick-Up Declarations are ready to be completed</mat-label>
      <mat-label class="link-like">Click here to complete</mat-label>
    </div>
  </div>
</div>