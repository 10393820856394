<div *ngIf="errorLoading$ | async">
  <div class="headline">
    <h2>News</h2>
  </div>

  <p>
    Sorry, our news pages are experiencing problems so you may not be able to
    view our latest stories.
  </p>
  <p>
    Our team is working on the issue and are looking to have them back up and
    running within the next business day.
  </p>
</div>
