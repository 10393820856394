import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { AdviceStatus } from "src/app/miscellaneous/enums/advice-status.enum";
import { StockType } from "src/app/miscellaneous/enums/stock-type.enum";
import { Model, Projection } from "../interfaces";
import { filterCleared, filterUpdated, toggleFilterAllStatues, toggleFilterAllStockTypes, toggleFilterStatues, toggleFilterStockType } from "../state/actions";
import { selectAdviceFilterOptions, selectAdvicesQuery, selectSelectedProperty, selectSelectedStatuses, selectSelectedStatusesList, selectSelectedStockTypes, selectSelectedStockTypesList, selectShowFilter } from "../state/selectors";
import { FilterToggle } from "./filterToggle";

@Component({
    selector: "app-advice-filter",
    templateUrl: "advice-filter.html",
    styleUrls: ["all-advices.component.scss"]
})
export class AdviceFilterComponent implements OnInit, OnDestroy {
    private readonly destroy$ = new Subject();

    public adviceQuery: Model.AdvicesQuery = null;
    public filterOptions: Projection.AdvicesFilterOptions;
    public selectedProperty: number = 0;
    public selectedStockTypes: StockType[];
    public selectedStockTypesList: string;
    public selectedStatuses : AdviceStatus[];
    public selectedStatusList: string;
  
    constructor(
        private readonly store: Store,
        private readonly filterToggle: FilterToggle,
    ) { }

    public ngOnInit(): void {
        this.store.select(selectSelectedStockTypes)
            .pipe(takeUntil(this.destroy$))
            .subscribe((selected) => this.selectedStockTypes = selected);
    
        this.store.select(selectAdviceFilterOptions)
            .pipe(takeUntil(this.destroy$))
            .subscribe((filterOptions) => this.filterOptions = filterOptions);
          
        this.store.select(selectSelectedProperty)
            .pipe(takeUntil(this.destroy$))
            .subscribe((propertyId) => this.selectedProperty = propertyId);

        this.store.select(selectSelectedStockTypesList)
            .pipe(takeUntil(this.destroy$))
            .subscribe((list) => this.selectedStockTypesList = list);
    
        this.store.select(selectAdvicesQuery)
            .pipe(takeUntil(this.destroy$))
            .subscribe((query) => this.adviceQuery = query);

        this.store.select(selectSelectedStatuses)
            .pipe(takeUntil(this.destroy$))
            .subscribe((selected) => this.selectedStatuses = selected);

        this.store.select(selectSelectedStatusesList)
            .pipe(takeUntil(this.destroy$))
            .subscribe((list) => this.selectedStatusList = list);
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public hideFilter(): void {
        this.filterToggle.hideFilter();
    }

    public updateFilter(update: Partial<Model.AdvicesQuery >): void {
        update.pageNumber = 1;
        this.store.dispatch(filterUpdated({ update }));
    }

    public clearFilters(): void {
        this.store.dispatch(filterCleared());
    }

    public toggleAllStockTypes(): void {
        this.store.dispatch(toggleFilterAllStockTypes());
    }

    public toggleStockType(stockType: StockType): void {
        this.store.dispatch(toggleFilterStockType({ stockType }));
    }

    public toggleAllStatuses(): void {
        this.store.dispatch(toggleFilterAllStatues());
    }

    public toggleStatus(status: AdviceStatus): void {
        this.store.dispatch(toggleFilterStatues({ status }));
    }
}