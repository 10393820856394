<table mat-table
       [dataSource]="advices"
       class="clickable-rows">
    <ng-container matColumnDef="processingDate">
        <th mat-header-cell *matHeaderCellDef class="average-column">Processing Date</th>
        <td mat-cell *matCellDef="let advice">
            <div *ngFor="let adviceLine of advice.adviceLines; index as adviceLineIndex" class="sub-row numeric-data">
                <p>{{ adviceKillDate(adviceLineIndex, advice, adviceLine) | date: "dd/MM/yyyy" }}</p>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="propertyName">
        <th mat-header-cell *matHeaderCellDef class="grow">Property Name</th>
        <td mat-cell *matCellDef="let advice" class="grow">
            <div *ngIf="advice.feedbackComments" class="sub-row double-line-column">
                <p>{{ advice.propertyName }}</p>
                <p *ngIf="advice.feedbackComments" class="comment">
                    <strong>Comments:</strong> {{ advice.feedbackComments }}
                </p>
            </div>
            <div *ngIf="!advice.feedbackComments" class="sub-row">
                <p>{{ advice.propertyName }}</p>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="stockType">
        <th mat-header-cell *matHeaderCellDef class="short-column">Stock Type</th>
        <td mat-cell *matCellDef="let advice">
            <div *ngFor="let adviceLine of advice.adviceLines" class="sub-row">
                <p>{{ adviceLine.stockType }}</p>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="adviceNumber">
        <th mat-header-cell *matHeaderCellDef class="short-column">Advice Number</th>
        <td mat-cell *matCellDef="let advice">
            <div class="sub-row numeric-data">
                <p>{{ advice.advice }}</p>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="tally">
        <th mat-header-cell *matHeaderCellDef class="short-column">Tally</th>
        <td mat-cell *matCellDef="let advice">
            <div *ngFor="let adviceLine of advice.adviceLines" class="sub-row numeric-data">
                <p>{{ adviceLine.tally ? (adviceLine.tally | number) : "-" }}</p>
                <p *ngIf="adviceLine.condemnedTally"
                    matTooltip="Includes {{adviceLine.condemnedTally}} condemned animals"
                    class="condemned">
                    -{{adviceLine.condemnedTally}}
                </p>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="averageWeight">
        <th mat-header-cell *matHeaderCellDef class="short-column">Average Weight</th>
        <td mat-cell *matCellDef="let advice">
            <div *ngFor="let adviceLine of advice.adviceLines" class="sub-row numeric-data">
                <p *ngIf="advice.bookingType === 6">-</p>
                <p *ngIf="advice.bookingType !== 6">
                    {{ adviceLine.averageWeight ? (adviceLine.averageWeight | number: "1.1-1") : "-" }}
                </p>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="dollarPerKilo">
        <th mat-header-cell *matHeaderCellDef class="short-column">
            <span class="no-text-wrap">$ per</span> Head
        </th>
        <td mat-cell *matCellDef="let advice">
            <div *ngFor="let adviceLine of advice.adviceLines" class="sub-row numeric-data">
                <p> {{ adviceLine.paidPerHead ? (adviceLine.paidPerHead | number: "1.2-2") : "-" }} </p>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="invoiceDollars">
        <th mat-header-cell *matHeaderCellDef class="short-column">
            <span class="no-text-wrap">Invoice</span>
            <br>
            <span class="no-text-wrap">(excl GST)</span>
        </th>
        <td mat-cell *matCellDef="let advice">
            <div *ngFor="let adviceLine of advice.adviceLines" class="sub-row numeric-data">
                <p> {{ adviceLine.totalNettAmount ? (adviceLine.totalNettAmount | number: "1.2-2") : "-" }} </p>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="adviceStatus">
        <th mat-header-cell *matHeaderCellDef class="standard-column">Status</th>
        <td mat-cell *matCellDef="let advice">
            <div *ngIf="advice.bookingType === 3 || advice.bookingType === 6" class="sub-row double-line-column">
                <p>{{ advice.adviceStatus }}</p>
                <p *ngIf="advice.bookingType === 3" class="comment">
                    <strong>Contracted</strong>
                </p>
                <p *ngIf="advice.bookingType === 6" class="comment">
                    <strong>Paddock Sale</strong>
                </p>
            </div>
            <div *ngIf="advice.bookingType !== 3 && advice.bookingType !== 6" class="sub-row">
                <p>{{ advice.adviceStatus }}</p>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="files">
        <th mat-header-cell *matHeaderCellDef class="file-column">Files</th>
        <td mat-cell *matCellDef="let advice">
            <div *ngIf="advice.invoiceFileId || advice.hasCarcaseFiles"
                 class="sub-row download-column">
                <div *ngFor="let adviceLine of advice.adviceLines; index as adviceLineIndex"
                     class="downloads">
                    <div *ngIf="advice.invoiceFileId && adviceLineIndex === 0"
                          matRipple
                          class="download-icon"
                          [ngClass]="{ divider: advice.carcaseFileId }"
                          (click)="downloadInvoice(advice, adviceLine, $event)">
                        <mat-icon aria-hidden="false" aria-label="Invoice icon">
                        <span class="material-icons"> monetization_on </span>
                        </mat-icon>
                        <p class="icon-text">Invoice</p>
                    </div>
                    <div *ngIf="adviceLine.carcaseFileId"
                         matRipple
                         class="download-icon no-text-wrap carcase-download"
                         (click)="downloadCarcaseData(advice, adviceLine, $event)">
                        <mat-icon aria-hidden="false" aria-label="Chart icon">
                        <span class="material-icons-outlined"> insert_chart_outlined </span>
                        </mat-icon>
                        <p class="icon-text now">Carcase Data</p>
                    </div>
                </div>
            </div>
            <div *ngIf="!advice.invoiceFileId && !advice.hasCarcaseFiles"
                 class="sub-row center">
                <p>No Files Available</p>
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let advice; columns: tableColumns" (click)="selectAdvice(advice)"></tr>
</table>