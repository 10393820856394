<div class="default-container">
  <div class="headline">
    <h2>Role Descriptions</h2>
  </div>

  <table mat-table [dataSource]="descriptions" class="mat-elevation-z8">
    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef>Role</th>
      <td mat-cell *matCellDef="let role">
        <div class="sub-row">
          <p>{{ role.role.description }}</p>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="requestBobby">
      <th mat-header-cell style="min-width: 80px" *matHeaderCellDef>
        Can Request Bobby pickups
      </th>
      <td mat-cell *matCellDef="let role">
        <div class="sub-row icon">
          <i *ngIf="role.requestBobby" class="btr bt-check"></i>
          <i *ngIf="!role.requestBobby" class="btr bt-ban"></i>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="receiveNotifications">
      <th mat-header-cell *matHeaderCellDef>Can receive notifications</th>
      <td mat-cell *matCellDef="let role">
        <div class="sub-row icon">
          <i *ngIf="role.receiveNotifications" class="btr bt-check"></i>
          <i *ngIf="!role.receiveNotifications" class="btr bt-ban"></i>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="viewProcessing">
      <th mat-header-cell *matHeaderCellDef>Can view processing</th>
      <td mat-cell *matCellDef="let role">
        <div class="sub-row icon">
          <i *ngIf="role.viewProcessing" class="btr bt-check"></i>
          <i *ngIf="!role.viewProcessing" class="btr bt-ban"></i>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="viewFinancials">
      <th mat-header-cell *matHeaderCellDef>Can view financials</th>
      <td mat-cell *matCellDef="let role">
        <div class="sub-row icon">
          <i *ngIf="role.viewFinancials" class="btr bt-check"></i>
          <i *ngIf="!role.viewFinancials" class="btr bt-ban"></i>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="manageStaff">
      <th mat-header-cell style="min-width: 85px" *matHeaderCellDef>
        Can manage staff
      </th>
      <td mat-cell *matCellDef="let role">
        <div class="sub-row icon">
          <i *ngIf="role.manageStaff" class="btr bt-check"></i>
          <i *ngIf="!role.manageStaff" class="btr bt-ban"></i>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef class="non-mobile">Comment</th>
      <td mat-cell *matCellDef="let role" class="non-mobile">
        <div class="sub-row">
          <p>{{ role.comment }}</p>
        </div>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="roleTableColumns; sticky: true" mat-header-row></tr>
    <tr mat-row *matRowDef="let role; columns: roleTableColumns"></tr>
  </table>

  <div class="button-panel">
    <button mat-raised-button class="nav-button" (click)="backToStaff()">
      Back
    </button>
  </div>
</div>
