import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { featureReducerKey, reducer } from './state/reducer';
import { CommonModule } from '@angular/common';
import { Selector } from './state/selector';
import { DeclineReasonDialogComponent } from './decline-reason-dialog/decline-reason-dialog.component';
import { CommandBecomeSelf } from './commands/command-become-self';
import { NewUserRequestsComponent } from './new-user-requests/new-user-requests.component';
import { AdminChangeUsersComponent } from './change-user-requests/admin-change-users.component';
import { AdminPortalUsersComponent } from './portal-users/admin-portal-users.component';
import {
  adminRoute,
  changeRequestRoute,
  changeRequestsRoute,
  editUserRoute,
  newRequestRoute,
  newRequestsRoute,
  portalUserRoute,
  portalUsersRoute,
} from './constants';
import { AngularMaterialModule } from '../miscellaneous';
import { AdminEditUserComponent } from './edit-user/edit-user.component';
import { NewUserRequestComponent } from './new-user-requests/new-user-request.component';
import { AdminChangeUserComponent } from './change-user-requests/admin-change-user.component';
import { AdminPortalUserComponent } from './portal-users/admin-portal-user.component';
import { AdminComponent } from './admin.component';
import { AdminRoutingModule } from './admin-routing.module';
import { CreateUserModule } from './create-user/create-user.module';

export { AdminComponent };
export { AdminChangeUsersComponent };
export { AdminChangeUserComponent };
export { NewUserRequestsComponent };
export { NewUserRequestComponent };
export { AdminPortalUsersComponent };
export { AdminPortalUserComponent };
export { CommandBecomeSelf };
export { Selector };

export { adminRoute };
export { changeRequestsRoute };
export { changeRequestRoute };
export { editUserRoute };
export { newRequestsRoute };
export { newRequestRoute };
export { portalUsersRoute };
export { portalUserRoute };

@NgModule({
  declarations: [
    AdminChangeUserComponent,
    AdminChangeUsersComponent,
    AdminEditUserComponent,
    NewUserRequestsComponent,
    NewUserRequestComponent,
    AdminPortalUsersComponent,
    AdminPortalUserComponent,
    DeclineReasonDialogComponent,
  ],
  imports: [
    AdminRoutingModule,
    CommonModule,
    StoreModule.forFeature(featureReducerKey, reducer),
    AngularMaterialModule,
    CreateUserModule,
  ],
})
export class AdminModule {}
