<div
  *ngIf="propertyName"
  class="default-container"
  data-cy="calendar-page-shell"
>
  <div class="headline">
    <h2>Pick-up dates for {{ propertyName }}</h2>
    <button
      mat-raised-button
      color="primary"
      (click)="cancel()"
      data-cy="downloadCSVButton"
    >
      Back
    </button>
  </div>
  <div class="calendar-panel">
    <app-multi-select-calendar [startDate]="june" [daysSelected]="juneDates">
    </app-multi-select-calendar>
    <app-multi-select-calendar [startDate]="july" [daysSelected]="julyDates">
    </app-multi-select-calendar>
    <app-multi-select-calendar
      [startDate]="august"
      [daysSelected]="augustDates"
    >
    </app-multi-select-calendar>
    <app-multi-select-calendar
      [startDate]="september"
      [daysSelected]="septemberDates"
    >
    </app-multi-select-calendar>
    <app-multi-select-calendar
      [startDate]="october"
      [daysSelected]="octoberDates"
    >
    </app-multi-select-calendar>
    <app-multi-select-calendar
      [startDate]="november"
      [daysSelected]="novemberDates"
    >
    </app-multi-select-calendar>
  </div>

  <mat-card>
    <mat-card-title class="primary">
      <span>Need Help?</span>
    </mat-card-title>
    <mat-card-content class="center-text">
      <p>
        Need to speak to someone? Please call <b>0800 4 ANZCO</b>&nbsp;
        <a href="tel:0800426926">(0800 4 26926)</a> for further assistance.
      </p>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title class="secondary">
      <span>Other Contact Information</span>
    </mat-card-title>
    <mat-card-content>
      <div *ngIf="bobbyBuyerName">
        <label>
          <strong>Bobby Rep</strong>
        </label>
        <div>
          {{ bobbyBuyerName }}
        </div>
      </div>
      <div *ngIf="bobbyBuyerPhone">
        <label></label>
        <div>
          <b>{{ bobbyBuyerPhone }}</b>
        </div>
      </div>
      <div>
        <label>
          <strong>Livestock Rep</strong>
        </label>
        <div>
          {{ buyerName }}
        </div>
      </div>
      <div>
        <label></label>
        <div>
          <b>{{ buyerPhone }}</b>
        </div>
      </div>
      <div *ngIf="transports.length">
        <label>
          <strong>Transporter</strong>
        </label>
        <div>
          <div *ngFor="let transport of transports">
            {{ transport }}
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
