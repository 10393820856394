<div class="default-container">
  <ng-container *ngIf="newsItem">
    <div id="story">
      <!-- <a [routerLink]="newsOverviewRoute">< Back To News Home</a> -->
      <button
        mat-raised-button
        class="back-button"
        [routerLink]="newsFeedRoute"
      >
        &#60; Back To News Feed
      </button>
      <img class="news-image" [src]="newsItem.featuredImageUrl" />
      <mat-card>
        <div class="headline">
          <h2>{{ newsItem.title }}</h2>
        </div>
        <div id="content" [innerHtml]="newsItem.content | trustedHtml"></div>
      </mat-card>
      <button
        mat-raised-button
        class="back-button"
        [routerLink]="newsFeedRoute"
      >
        &#60; Back To News Feed
      </button>
    </div>
    <div id="related-news" *ngIf="relatedNews$ | async as relatedNews">
      <app-news-feed-preview
        title="Related Stories"
        [newsItems]="relatedNews"
        [verticalDisplay]="true"
        [displayFeedLink]="false"
        id="desktop-preview"
      ></app-news-feed-preview>
      <app-news-feed-preview
        title="Related Stories"
        [newsItems]="relatedNews"
        [verticalDisplay]="false"
        [displayFeedLink]="false"
        id="mobile-preview"
      ></app-news-feed-preview>
    </div>
  </ng-container>
  <app-news-error></app-news-error>
</div>
