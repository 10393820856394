import { createFeatureSelector, createSelector } from '@ngrx/store';
import { bobbyReducerKey } from './reducer';
import { Bobby, Projection } from '../models';
import { DateColour } from '../bobby-calves-calendar/multi-select-calendar/multi-select-calendar.component';

const getBobbyState = createFeatureSelector<Bobby.State>(bobbyReducerKey);
const prioritisedPickupStates: Bobby.PickupStatus[] = [
  Bobby.PickupStatus.NotYetSubmitted,
  Bobby.PickupStatus.BookingSubmitted,
  Bobby.PickupStatus.Upcoming,
  Bobby.PickupStatus.LateEntry,
];

export const selectProperties = createSelector(
  getBobbyState,
  (state: Bobby.State) => state.properties
);

export const selectOrderedPickups = createSelector(
  getBobbyState,
  (state: Bobby.State) => {
    var result: Projection.Pickup[] = state.pickupResponse.pickups
      .slice()
      .sort((pickupA, pickupB) => {
        if (prioritisedPickupStates.includes(pickupA.status) &&
            !prioritisedPickupStates.includes(pickupB.status)) {
              return -1;
        }
        if (prioritisedPickupStates.includes(pickupB.status) &&
            !prioritisedPickupStates.includes(pickupA.status)) {
              return 1;
        }
        return pickupA.pickupDate > pickupB.pickupDate ? 1 : -1
      })
      .map((pickup) => {
        const booking: Projection.Booking = pickup.booking ?
          {
            id: pickup.booking.id,
            propertyId: pickup.anzcoPropertyId,
            requestedBy: pickup.booking.requestedBy,
            requestedNumOfCalves: pickup.booking.requestedNumOfCalves,
            approvedNumOfCalves: pickup.booking.approvedNumOfCalves,
            isApproved: pickup.booking.approvedNumOfCalves !== null,
            note: pickup.booking.note,
            pickedUpDriverName: pickup.booking.pickupCompletion ?
              pickup.booking.pickupCompletion.driverName :
              null,
            pickedUpTally: pickup.booking.pickupCompletion ?
              pickup.booking.pickupCompletion.tally :
              null,
            numberRejectedNoTag: pickup.booking.pickupCompletion ?
              pickup.booking.pickupCompletion.numberRejectedNoTag :
              null,
            numberRejectedScours: pickup.booking.pickupCompletion ?
              pickup.booking.pickupCompletion.numberRejectedScours :
              null,
            numberRejectedWeak: pickup.booking.pickupCompletion ?
              pickup.booking.pickupCompletion.numberRejectedWeak :
              null,
            numberRejectedWet: pickup.booking.pickupCompletion ?
              pickup.booking.pickupCompletion.numberRejectedWet :
              null,
            numberRejectedWetNavel: pickup.booking.pickupCompletion ?
              pickup.booking.pickupCompletion.numberRejectedWetNavel :
              null,
            numberRejectedOther: pickup.booking.pickupCompletion ?
              pickup.booking.pickupCompletion.numberRejectedOther :
              null,
            rejectedOtherNote: pickup.booking.pickupCompletion?.rejectedOtherNote ?
              pickup.booking.pickupCompletion.rejectedOtherNote :
              null,
          } : null;
        const projection: Projection.Pickup = {
          anzcoPropertyId: pickup.anzcoPropertyId,
          status: pickup.status,
          hasBookingsClosed: true,
          hasBookingsOpened: true,
          bookingsOpenAt: pickup.bookingsOpensAt,
          bookingsCloseAt: pickup.bookingsCloseAt,
          bookingsReminderAt: pickup.bookingReminderAt,
          date: pickup.pickupDate,
          isOpenForBooking: false,
          calendarDateId: pickup.calendarDateId,
          contactNumber: pickup.contactNumber,
          declarationExpected: pickup.declarationExpected,
          booking,
        };

        return projection;
      });

    return result;
  }
);

export const selectIsInSeason = createSelector(
  getBobbyState,
  (state: Bobby.State) => state.pickupResponse.pickups.length > 0,
);

export const selectIsInPreSeason = createSelector(
  getBobbyState,
  (state: Bobby.State) => state.pickupResponse.pickups.length === 0 &&
    !state.pickupResponse.isSeasonCompleted &&
    !state.pickupResponse.hasSeasonEnded,
);

export const selectIsInPostSeason = createSelector(
  getBobbyState,
  (state: Bobby.State) => state.pickupResponse.pickups.length === 0 && state.pickupResponse.hasSeasonEnded,
);

export const selectShouldShowCalendar = createSelector(
  getBobbyState,
  (state: Bobby.State) => state.pickupResponse.hasSeasonStarted,
);

export const selectBooking = createSelector(
  getBobbyState,
  (state: Bobby.State) => state.bookings,
);

export const hasSeasonBeenCompleted = createSelector(
  getBobbyState,
  (state: Bobby.State) =>
    (state.pickupResponse?.isSeasonCompleted ?? false) &&
    !state.pickupResponse.hasSeasonEnded,
);

export const selectedPropertyId = createSelector(
  getBobbyState,
  (state: Bobby.State) => state.selectedPropertyId,
);

export const selectedPropertyName = createSelector(
  getBobbyState,
  (state: Bobby.State) => state.selectedPropertyId ?
    state.properties.find(property => property.id === state.selectedPropertyId).name :
    '',
);

export const selectedCalendarPropertyName = createSelector(
  getBobbyState,
  (state: Bobby.State) => state.calendar ?
    state.calendar.propertyName :
    '',
);

export const selectedBuyerName = createSelector(
  getBobbyState,
  (state: Bobby.State) => state.calendar ? state.calendar.buyerName : '',
);

export const selectedBuyerPhone = createSelector(
  getBobbyState,
  (state: Bobby.State) => state.calendar ? state.calendar.buyerPhone : '',
);

export const selectedBobbyCalvesBuyerName = createSelector(
  getBobbyState,
  (state: Bobby.State) => state.calendar && state.calendar.bobbyCalvesBuyerName !== state.calendar.buyerName ?
    state.calendar.bobbyCalvesBuyerName : 
    null,
);

export const selectedBobbyCalvesBuyerPhone = createSelector(
  getBobbyState,
  (state: Bobby.State) => state.calendar && state.calendar.bobbyCalvesBuyerPhone !== state.calendar.buyerPhone ?
    state.calendar.bobbyCalvesBuyerPhone : 
    null,
);

export const selectedTransports = createSelector(
  getBobbyState,
  (state: Bobby.State) => state.calendar ?
    state.calendar.transportCompanies.map(company => company.name) :
    [],
);

export const selectedJuneDates = createSelector(
  getBobbyState,
  (state: Bobby.State) => extractMonthDates(state.calendar, 5),
);

export const selectedJulyDates = createSelector(
  getBobbyState,
  (state: Bobby.State) => extractMonthDates(state.calendar, 6),
);

export const selectedAugustDates = createSelector(
  getBobbyState,
  (state: Bobby.State) => extractMonthDates(state.calendar, 7),
);

export const selectedSeptemberDates = createSelector(
  getBobbyState,
  (state: Bobby.State) => extractMonthDates(state.calendar, 8),
);

export const selectedOctoberDates = createSelector(
  getBobbyState,
  (state: Bobby.State) => extractMonthDates(state.calendar, 9),
);

export const selectedNovemberDates = createSelector(
  getBobbyState,
  (state: Bobby.State) => extractMonthDates(state.calendar, 10),
);

export const selectLoaded = createSelector(
  getBobbyState,
  (state: Bobby.State) => state.loaded,
);

export const selectErrored = createSelector(
  getBobbyState,
  (state: Bobby.State) => state.errored,
);

function extractMonthDates(calendar: Bobby.PropertyCalendarResponse | null, month: number): DateColour[] {
  var result: DateColour[] = [];

  if (calendar) {
    const today: Date = new Date();
    result = calendar.pickupDates
      .filter(pickupDate => pickupDate.getMonth() == month)
      .map(pickupDate => {
        const day: DateColour = (pickupDate > today) ?
          {
            date: pickupDate,
            colour: '#003d4c',
          } :
          {
            date: pickupDate,
            colour: '#777777',
          };

          return day;
      });
  }
  return result;
}