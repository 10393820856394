import {
    loadOwnerPropertiesStarted,
    loadOwnerPropertiesCompleted,
    removeStaffStarted,
    removeStaffCompleted,
    addStaffStarted,
    addStaffCompleted,
    changeStaffStarted,
    changeStaffCompleted,
    noAction
} from "./actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { exhaustMap, map, switchMap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Model } from "../interfaces";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { allStaffManagementRoute } from "../constants";

@Injectable()
export class UserManagementEffects {
    constructor(
        private readonly actions: Actions,
        private readonly http: HttpClient,
        private readonly router: Router,
    ) { }

    public loadOwnerPropertiesEffect = createEffect(() => this.actions.pipe(
        ofType(loadOwnerPropertiesStarted),
        switchMap(
            () => this.http.get<Model.PotentialOutageResponse<Model.PropertiesAndStaff>>(environment.apiUrl + "/api/user/owner-properties-users")
            .pipe(
                map(response => loadOwnerPropertiesCompleted(response)),
            ),
        ),
    ));

    public removeStaffEffect = createEffect(() => this.actions.pipe(
        ofType(removeStaffStarted),
        exhaustMap(
            (action) => this.http.post<void>(
                environment.apiUrl + "/api/staff-management/remove-staff",
                { userId: action.userId },
            )
            .pipe(
                map(() => removeStaffCompleted({ userId: action.userId })),
            ),
        ),
    ));

    public addStaffEffect = createEffect(() => this.actions.pipe(
        ofType(addStaffStarted),
        exhaustMap(
            (action) => this.http.post<number>(
                environment.apiUrl + "/api/staff-management/add-staff",
                {
                    email: action.staff.email?.trim(),
                    firstName: action.staff.firstName?.trim(),
                    surname: action.staff.surname?.trim(),
                    roles: action.staff.roles,
                },
            )
            .pipe(
                map((userId: number) => {
                    const staff: Model.Staff = {
                        ...action.staff,
                        userId,
                    };

                    return addStaffCompleted({ staff });
                }),
            ),
        ),
    ));

    public addStaffEffectCompleted = createEffect(() => this.actions.pipe(
        ofType(addStaffCompleted),
        map(
            () => {
                this.router.navigate([allStaffManagementRoute]);
                return noAction();
            }
        ),
    ));

    public changeStaffEffect = createEffect(() => this.actions.pipe(
        ofType(changeStaffStarted),
        exhaustMap(
            (action) => this.http.post<void>(
                environment.apiUrl + "/api/staff-management/change-staff",
                {
                    userId: action.staff.userId,
                    roles: action.staff.roles,
                },
            )
            .pipe(
                map(() => changeStaffCompleted({ staff: action.staff })),
            ),
        ),
    ));

    public changeStaffEffectCompleted = createEffect(() => this.actions.pipe(
        ofType(changeStaffCompleted),
        map(
            () => {
                this.router.navigate([allStaffManagementRoute]);
                return noAction();
            }
        ),
    ));
}