import { createReducer, on } from "@ngrx/store";
import { Documents, Model } from "../interfaces";
import {
    clearFilter,
    filterUpdated,
    loadDeclarationsCompleted,
    loadPropertiesCompleted,
} from "./actions";

export const documentsFeatureKey = "documents";

const initialState: Documents.State = {
    properties: [],
    declarations: [],
    totalDeclarations: 0,
    filter:{
        anzcoPropertyIds: [],
        startDate: null,
        endDate: null,
        pageIndex: 0,
        pageSize: 10,
    },
}

export const documentsReducer = createReducer<Documents.State>(
    initialState,

    on(
        loadPropertiesCompleted,
        (state: Documents.State, res: Model.PropertiesResponse): Documents.State => ({
          ...state,
          properties: res.properties.map(property => {
              return {
                id: property.id,
                name: property.name,
              };
            })
            .sort((propertyA, propertyB) => propertyA.name.localeCompare(propertyB.name)),
        })
      ),

      on(
        loadDeclarationsCompleted,
        (state: Documents.State, res: Model.PagedDeclarationHistoryResponse): Documents.State => ({
            ...state,
            declarations: res.results.map(declaration => {
                const mappedDeclaration: Model.DeclarationHistorySearchResponse = {
                    anzcoPropertyId: declaration.anzcoPropertyId,
                    pickupDate: new Date(declaration.pickupDate),
                    propertyId: declaration.propertyId,
                    propertyName: declaration.propertyName,
                    recordedBy: declaration.recordedBy,
                    declaredTally: declaration.declaredTally,
                    timeOfLastFeed: declaration.timeOfLastFeed ?
                        declaration.timeOfLastFeed.substring(0, 5) + 
                            (declaration.timeOfLastFeed.startsWith("1") ? " PM" : " AM") : 
                        "-",
                    pickedUpTally: declaration.pickedUpTally,
                    rejected: declaration.rejected,
                    rejectReasons: declaration.rejectReasons,
                    transportDriver: declaration.transportDriver,
                    transportName: declaration.transportName,
                }

                return mappedDeclaration;
            }),
            totalDeclarations: res.totalResultCount,
        }),
    ),

    on(filterUpdated, (state, { update }) => {
        return {
            ...state,
            filter: {
                ...state.filter,
                ...update,
            }
        };
    }),

    on(clearFilter, (state) => {
        return {
            ...state,
            filter:{
                anzcoPropertyIds: [],
                startDate: null,
                endDate: null,
                pageIndex: 0,
                pageSize: 10,
            },
        };
    }),
);