<mat-toolbar class="header-div">
  <img
    src="../../assets/images/mainLogo.PNG"
    alt="Anzco Logo"
    class="img-logo"
    [class]="logoClass"
    (click)="navigateHome()" />

  <div class="menu-div">
    <app-header-menu-items (menuClicked)="menuClicked.emit($event)">
    </app-header-menu-items>

    <app-admin-user *ngIf="shouldDisplayAdminUserMenu | async"></app-admin-user>
    <button *ngIf="shouldDisplayLogout" class="menu-icon" data-cy="logout-menu-header" (click)="logout()">
      <mat-icon>logout</mat-icon>
      Logout
    </button>
  </div>
</mat-toolbar>
