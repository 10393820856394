<mat-card (click)="fillOutDeclaration()">
  <mat-card-title>
    <span role="heading">Declaration Required</span>
  </mat-card-title>
  <mat-card-content>
    <div>
      <label>
        <strong>Confirmed Tally*</strong>
      </label>
      <div>
        <strong>{{ pickup.booking.approvedNumOfCalves }}</strong>
      </div>
    </div>
    <div>
      <label>
        <strong>Pick-up Date</strong>
      </label>
      <div>
        {{ date }}
      </div>
    </div>
    <div>
      <label>
        <strong>Requested By</strong>
      </label>
      <div>
        {{ pickup.booking.requestedBy }}
      </div>
    </div>
    <div class="panel-info-text">
      *Pick-ups are subject to change due to unforeseen circumstances e.g. adverse weather
    </div>
    <div class="declaration" >
      <div>
        <i class="bts bt-exclamation-triangle bt-2x"></i>
      </div>
      <div>
        <mat-label>DECLARATION REQUIRED</mat-label>
        <mat-label>Click here to complete</mat-label>
      </div>
    </div>
  </mat-card-content>
</mat-card>
