import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Models } from '../models';
import { environment } from '../../../../environments/environment';
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SearchPropertiesQuery {
  private readonly _loadingSubject$ = new BehaviorSubject(false);

  constructor(private http: HttpClient) {}

  public get loading$(): Observable<boolean> {
    return this._loadingSubject$;
  }

  execute(req: Models.PropertySearchRequest): Observable<Models.ApiProperty[]> {
    type Response = { properties: Models.ApiProperty[] };
    if (!req.searchTerm && !req.producerCode) return of([]);

    return of(environment.apiUrl + '/api/admin/properties-search').pipe(
      tap(() => this._loadingSubject$.next(true)),
      switchMap((url) => this.http.post<Response>(url, req)),
      map((response) => response.properties),
      tap(() => this._loadingSubject$.next(false))
    );
  }
}
